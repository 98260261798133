:root {
    --blue: #037afb;
    --indigo: #6551fd;
    --purple: #7342cf;
    --pink: #a7939c;
    --red: #f14a5a;
    --red-dark: #d13747;
    --orange: #f6a123;
    --yellow: #ffcd38;
    --green: #30b37c;
    --teal: #2eb2c4;
    --cyan: #33c0d6;
    --white: #fff;
    --gray: #9099aa;
    --gray-dark: #565f74;
    --light: #f4f5f8;
    --dark: #3b4863;
    --black: #001737;
    --border-light: 1.5px solid rgb(244, 247, 252);
    --box-shadow: 0 3px 10px rgba(62, 85, 120, 0.045);
}
*,
::after,
::before {
    box-sizing: border-box;
}
:focus {
    outline: 0;
}
html {
    font-family: var(--font-family);
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}
body {
    margin: 0;
    font-family: var(--font-family);
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #001737;
    text-align: left;
    background: var(--body-background);
    background-image: var(--background-image);
}
div::-webkit-scrollbar {
    height: 2px;
    width: 4px;
}
::-webkit-scrollbar-track {
    background: var(--body-background);
    border-radius: 6px;
}
div::-webkit-scrollbar-thumb {
    background: var(--secondary);
    border-radius: 10px;
}
.authPage footer,
.authPage header,
.blankPage footer,
.blankPage header {
    display: none;
}
[tabindex="-1"]:focus {
    outline: 0 !important;
}
hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}
p {
    margin-top: 0;
    margin-bottom: 1rem;
}
abbr[data-original-title],
abbr[title] {
    text-decoration: underline;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    text-decoration-skip-ink: none;
}
address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
}
dl,
ol,
ul {
    margin-top: 0;
    margin-bottom: 1rem;
}
ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0;
}
dt {
    font-weight: 700;
}
dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
}
blockquote {
    margin: 0 0 1rem;
}
b,
strong {
    font-weight: bolder;
}
small {
    font-size: 80%;
}
sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}
sub {
    bottom: -0.25em;
}
sup {
    top: -0.5em;
}
a {
    color: var(--primary);
    text-decoration: none;
    background-color: transparent;
}
a:hover {
    color: var(--primary-dark);
    text-decoration: none;
}
a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
}
a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
    outline: 0;
}
code,
kbd,
pre,
samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, liberation mono,
        courier new, monospace;
    font-size: 1em;
}
pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
}
figure {
    margin: 0 0 1rem;
}
img {
    vertical-align: middle;
    border-style: none;
}
svg {
    overflow: hidden;
    vertical-align: middle;
}
table {
    border-collapse: collapse;
}
caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: var(--secondary);
    text-align: left;
    caption-side: bottom;
}
th {
    text-align: inherit;
}
label {
    display: inline-block;
    font-size: 13px;
    margin-bottom: 4px;
}
button {
    border-radius: 0;
}
button:focus {
    outline: 0;
}
button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
button,
input {
    overflow: visible;
}
button,
select {
    text-transform: none;
}
select {
    word-wrap: normal;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
    -webkit-appearance: button;
}
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
    cursor: pointer;
}
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
    padding: 0;
    border-style: none;
}
input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
    cursor: pointer;
}
input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="time"] {
    -webkit-appearance: listbox;
}
textarea {
    overflow: auto;
    resize: vertical;
}
fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}
legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}
progress {
    vertical-align: baseline;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}
[type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
}
[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}
::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}
output {
    display: inline-block;
}
summary {
    display: list-item;
    cursor: pointer;
}
template {
    display: none;
}
[hidden] {
    display: none !important;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.25;
    color: var(--black);
}
.h1,
h1 {
    font-size: 2.1875rem;
}
.h2,
h2 {
    font-size: 1.75rem;
}
.h3,
h3 {
    font-size: 1.53125rem;
}
.h4,
h4 {
    font-size: 1.3125rem;
}
.h5,
h5 {
    font-size: 1.09375rem;
}
.h6,
h6 {
    font-size: 0.875rem;
}
.lead {
    font-size: 1.09375rem;
    font-weight: 300;
}
.display-1 {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.25;
}
.display-2 {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.25;
}
.display-3 {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.25;
}
.display-4 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.25;
}
hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.small,
small {
    font-size: 80%;
    font-weight: 400;
}
.mark,
mark {
    padding: 0.2em;
    background-color: #fcf8e3;
}
.list-unstyled {
    padding-left: 0;
    list-style: none;
}
.list-inline {
    padding-left: 0;
    list-style: none;
}
.list-inline-item {
    display: inline-block;
}
.list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
}
.initialism {
    font-size: 90%;
    text-transform: uppercase;
}
.blockquote {
    margin-bottom: 1rem;
    font-size: 1.09375rem;
}
.blockquote-footer {
    display: block;
    font-size: 80%;
    color: var(--secondary);
}
.blockquote-footer::before {
    content: "\2014\00A0";
}
.img-fluid {
    max-width: 100%;
    height: auto;
}
.img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #cdd4e0;
    border-radius: 0.25rem;
    max-width: 100%;
    height: auto;
}
.figure {
    display: inline-block;
}
.figure-img {
    margin-bottom: 0.5rem;
    line-height: 1;
}
.figure-caption {
    font-size: 90%;
    color: var(--secondary);
}
code {
    font-size: 87.5%;
    color: #f10075;
    word-break: break-word;
}
a > code {
    color: inherit;
}
kbd {
    padding: 0.2rem 0.4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #1c273c;
    border-radius: 0.2rem;
}
kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
}
pre {
    display: block;
    font-size: 87.5%;
    color: #1c273c;
}
pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
}
.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll;
}
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
    .support .chat-holder {
        height: 58vh !important;
    }
}
@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}
@media (min-width: 992px) {
    /* .content-fixed {
        margin-top: 35px;
    } */
    .container {
        max-width: 960px;
    }
}
@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
    .table-responsive::-webkit-scrollbar {
        display: none !important;
    }
}
.table-responsive::-webkit-scrollbar-thumb {
    background: var(--secondary) !important;
    height: 4px !important;
}
.container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.no-gutters {
    margin-right: 0;
    margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}
.col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}
.col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
}
.col-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
}
.col-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}
.col-3 {
    flex: 0 0 25%;
    max-width: 25%;
}
.col-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}
.col-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
}
.col-6 {
    flex: 0 0 50%;
    max-width: 50%;
}
.col-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
}
.col-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
}
.col-9 {
    flex: 0 0 75%;
    max-width: 75%;
}
.col-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
}
.col-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
}
.col-12 {
    flex: 0 0 100%;
    max-width: 100%;
}
@media (min-width: 576px) {
    .col-sm {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
    .col-sm-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
    .col-sm-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    .col-sm-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .col-sm-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-sm-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .col-sm-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-sm-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    .col-sm-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    .col-sm-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-sm-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    .col-sm-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
@media (min-width: 768px) {
    .col-md {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
    .col-md-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
    .col-md-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    .col-md-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-md-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .col-md-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-md-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    .col-md-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    .col-md-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-md-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    .col-md-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
@media (min-width: 992px) {
    .col-lg {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
    .col-lg-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
    .col-lg-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    .col-lg-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-lg-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .col-lg-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-lg-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    .col-lg-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-lg-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    .col-lg-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
@media (min-width: 1200px) {
    .col-xl {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
    .col-xl-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
    .col-xl-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    .col-xl-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .col-xl-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-xl-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .col-xl-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    .col-xl-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-xl-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    .col-xl-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    .col-xl-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-xl-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    .col-xl-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    .col-xl-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #001737;
}
.table td,
.table th {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid rgba(72, 94, 144, 0.16);
}
.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid rgba(72, 94, 144, 0.16);
}
.table tbody + tbody {
    border-top: 2px solid rgba(72, 94, 144, 0.16);
}
.table-sm td,
.table-sm th {
    padding: 0.3rem;
}
.table-bordered {
    border: 1px solid rgba(72, 94, 144, 0.16);
}
.table-bordered td,
.table-bordered th {
    border: 1px solid #e8e8e875;
}
.table-bordered thead td,
.table-bordered thead th {
    border-bottom-width: 2px;
}
.table-borderless tbody + tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
    border: 0;
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f4f7fc8a;
}
.table-hover tbody tr:hover {
    color: #001737;
    background-color: #f4f7fc9a;
}
.table-primary,
.table-primary > td,
.table-primary > th {
    background-color: #b8d5fe;
}
.table-primary tbody + tbody,
.table-primary td,
.table-primary th,
.table-primary thead th {
    border-color: #7bb0fc;
}
.table-hover .table-primary:hover {
    background-color: #9fc6fe;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
    background-color: #9fc6fe;
}
.table-secondary,
.table-secondary > td,
.table-secondary > th {
    background-color: #d9dde5;
}
.table-secondary tbody + tbody,
.table-secondary td,
.table-secondary th,
.table-secondary thead th {
    border-color: #b9c1ce;
}
.table-hover .table-secondary:hover {
    background-color: #cacfdb;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
    background-color: #cacfdb;
}
.table-success,
.table-success > td,
.table-success > th {
    background-color: #bcebd1;
}
.table-success tbody + tbody,
.table-success td,
.table-success th,
.table-success thead th {
    border-color: #83daa9;
}
.table-hover .table-success:hover {
    background-color: #a8e5c4;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
    background-color: #a8e5c4;
}
.table-info,
.table-info > td,
.table-info > th {
    background-color: #b8ebf3;
}
.table-info tbody + tbody,
.table-info td,
.table-info th,
.table-info thead th {
    border-color: #7adae9;
}
.table-hover .table-info:hover {
    background-color: #a2e5ef;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
    background-color: #a2e5ef;
}
.table-warning,
.table-warning > td,
.table-warning > th {
    background-color: #ffeeba;
}
.table-warning tbody + tbody,
.table-warning td,
.table-warning th,
.table-warning thead th {
    border-color: #ffdf7e;
}
.table-hover .table-warning:hover {
    background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
    background-color: #ffe8a1;
}
.table-danger,
.table-danger > td,
.table-danger > th {
    background-color: #f5c6cb;
}
.table-danger tbody + tbody,
.table-danger td,
.table-danger th,
.table-danger thead th {
    border-color: #ed969e;
}
.table-hover .table-danger:hover {
    background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
    background-color: #f1b0b7;
}
.table-light,
.table-light > td,
.table-light > th {
    background-color: #fcfcfd;
}
.table-light tbody + tbody,
.table-light td,
.table-light th,
.table-light thead th {
    border-color: #f9fafb;
}
.table-hover .table-light:hover {
    background-color: #ededf3;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
    background-color: #ededf3;
}
.table-dark,
.table-dark > td,
.table-dark > th {
    background-color: #c8ccd3;
}
.table-dark tbody + tbody,
.table-dark td,
.table-dark th,
.table-dark thead th {
    border-color: #99a0ae;
}
.table-hover .table-dark:hover {
    background-color: #babfc8;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
    background-color: #babfc8;
}
.table-active,
.table-active > td,
.table-active > th {
    background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075);
}
.table .thead-dark th {
    color: #fff;
    background-color: #3b4863;
    border-color: #49597b;
}
.table .thead-light th {
    color: #596882;
    background-color: #e3e7ed;
    border-color: rgba(72, 94, 144, 0.16);
}
.table-dark {
    color: #fff;
    background-color: #3b4863;
}
.table-dark td,
.table-dark th,
.table-dark thead th {
    border-color: #49597b;
}
.table-dark.table-bordered {
    border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075);
}
@media (max-width: 575.98px) {
    .donut {
        height: 300px;
    }
    .modal .ql-editor {
        max-height: 450px;
    }
    .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-sm > .table-bordered {
        border: 0;
    }
    .chat-holder .message {
        width: 80%;
    }
}
@media (max-width: 767.98px) {
    .patterns div:empty {
        height: 55px !important;
        width: 55px !important;
    }
    .navbar-menu .nav-link {
        font-size: 16px !important;
    }
    .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-md > .table-bordered {
        border: 0;
    }
}
@media (max-width: 991.98px) {
    .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-lg > .table-bordered {
        border: 0;
    }
}
@media (max-width: 1199.98px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-xl > .table-bordered {
        border: 0;
    }
}
.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
    border: 0;
}
.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.9375rem + 2px);
    padding: 0.46875rem 0.625rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #596882;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #c0ccda;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .form-control {
        transition: none;
    }
}
.form-control::-ms-expand {
    background-color: transparent;
    border: 0;
}
.form-control:focus {
    color: #596882;
    background-color: #fff;
    border-color: var(--primary-light);
    outline: 0;
    box-shadow: 0 0 2px 1.2px var(--primary-light);
}
.form-control::placeholder {
    color: var(--secondary);
    opacity: 1;
}
.form-control:disabled,
.form-control[readonly] {
    background-color: #e3e7ed;
    opacity: 1;
}
select.form-control:focus::-ms-value {
    color: #596882;
    background-color: #fff;
}
.form-control-file,
.form-control-range {
    display: block;
    width: 100%;
}
.col-form-label {
    padding-top: calc(0.46875rem + 1px);
    padding-bottom: calc(0.46875rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
}
.col-form-label-lg {
    padding-top: calc(0.6rem + 1px);
    padding-bottom: calc(0.6rem + 1px);
    font-size: 1.09375rem;
    line-height: 1.5;
}
.col-form-label-sm {
    padding-top: calc(0.391rem + 1px);
    padding-bottom: calc(0.391rem + 1px);
    font-size: 0.8125rem;
    line-height: 1.5;
}
.form-control-plaintext {
    display: block;
    width: 100%;
    padding-top: 0.46875rem;
    padding-bottom: 0.46875rem;
    margin-bottom: 0;
    line-height: 1.5;
    color: #001737;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
}
.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
    padding-right: 0;
    padding-left: 0;
}
.form-control-sm {
    height: calc(1.5em + 0.782rem + 2px);
    padding: 0.391rem 0.5rem;
    font-size: 0.8125rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}
.form-control-lg {
    height: calc(1.5em + 1.2rem + 2px);
    padding: 0.6rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}
select.form-control[multiple],
select.form-control[size] {
    height: auto;
}
textarea.form-control {
    height: auto;
}
.form-group {
    margin-bottom: 1rem;
}
.form-text {
    display: block;
    margin-top: 0.25rem;
}
.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}
.form-row > .col,
.form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
}
.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
}
.form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label {
    color: var(--secondary);
}
.form-check-label {
    margin-bottom: 0;
}
.form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0;
}
.valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: var(--green);
}
.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.76562rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(16, 183, 89, 0.9);
    border-radius: 0.25rem;
}
.form-control.is-valid,
.was-validated .form-control:valid {
    border-color: var(--green);
    padding-right: calc(1.5em + 0.9375rem);
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA4IDgnPjxwYXRoIGZpbGw9JyMxMGI3NTknIGQ9J00yLjMgNi43M0wuNiA0LjUzYy0uNC0xLjA0LjQ2LTEuNCAxLjEtLjhsMS4xIDEuNCAzLjQtMy44Yy42LS42MyAxLjYtLjI3IDEuMi43bC00IDQuNmMtLjQzLjUtLjguNC0xLjEuMXonLz48L3N2Zz4=);
    background-repeat: no-repeat;
    background-position: center right calc(0.375em + 0.23438rem);
    background-size: calc(0.75em + 0.46875rem) calc(0.75em + 0.46875rem);
}
.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
    border-color: var(--green);
    box-shadow: 0 0 0 0.2rem rgba(16, 183, 89, 0.25);
}
.form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip,
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip {
    display: block;
}
.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
    padding-right: calc(1.5em + 0.9375rem);
    background-position: top calc(0.375em + 0.23438rem) right
        calc(0.375em + 0.23438rem);
}
.custom-select.is-valid,
.was-validated .custom-select:valid {
    border-color: var(--green);
    padding-right: calc((1em + 0.9375rem) * 3 / 4 + 1.625rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%233b4863' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
            no-repeat right 0.625rem center/8px 10px,
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2310b759' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
            #fff no-repeat center right 1.625rem / calc(0.75em + 0.46875rem)
            calc(0.75em + 0.46875rem);
}
.custom-select.is-valid:focus,
.was-validated .custom-select:valid:focus {
    border-color: var(--green);
    box-shadow: 0 0 0 0.2rem rgba(16, 183, 89, 0.25);
}
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip,
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip {
    display: block;
}
.form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip,
.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip {
    display: block;
}
.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
    color: var(--green);
}
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip,
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip {
    display: block;
}
.custom-control-input.is-valid ~ .custom-control-label,
.was-validated .custom-control-input:valid ~ .custom-control-label {
    color: var(--green);
}
.custom-control-input.is-valid ~ .custom-control-label::before,
.was-validated .custom-control-input:valid ~ .custom-control-label::before {
    border-color: var(--green);
}
.custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip,
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip {
    display: block;
}
.custom-control-input.is-valid:checked ~ .custom-control-label::before,
.was-validated
    .custom-control-input:valid:checked
    ~ .custom-control-label::before {
    border-color: #14e670;
    background-color: #14e670;
}
.custom-control-input.is-valid:focus ~ .custom-control-label::before,
.was-validated
    .custom-control-input:valid:focus
    ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(16, 183, 89, 0.25);
}
.custom-control-input.is-valid:focus:not(:checked)
    ~ .custom-control-label::before,
.was-validated
    .custom-control-input:valid:focus:not(:checked)
    ~ .custom-control-label::before {
    border-color: var(--green);
}
.custom-file-input.is-valid ~ .custom-file-label,
.was-validated .custom-file-input:valid ~ .custom-file-label {
    border-color: var(--green);
}
.custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip,
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip {
    display: block;
}
.custom-file-input.is-valid:focus ~ .custom-file-label,
.was-validated .custom-file-input:valid:focus ~ .custom-file-label {
    border-color: var(--green);
    box-shadow: 0 0 0 0.2rem rgba(16, 183, 89, 0.25);
}
.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: var(--red);
}
.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.76562rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(220, 53, 69, 0.9);
    border-radius: 0.25rem;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
    border-color: var(--red);
    padding-right: calc(1.5em + 0.9375rem);
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIGZpbGw9JyNkYzM1NDUnIHZpZXdCb3g9Jy0yIC0yIDcgNyc+PHBhdGggc3Ryb2tlPScjZGMzNTQ1JyBkPSdNMCAwbDMgM20wLTNMMCAzJy8+PGNpcmNsZSByPScuNScvPjxjaXJjbGUgY3g9JzMnIHI9Jy41Jy8+PGNpcmNsZSBjeT0nMycgcj0nLjUnLz48Y2lyY2xlIGN4PSczJyBjeT0nMycgcj0nLjUnLz48L3N2Zz4=);
    background-repeat: no-repeat;
    background-position: center right calc(0.375em + 0.23438rem);
    background-size: calc(0.75em + 0.46875rem) calc(0.75em + 0.46875rem);
}
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
    border-color: var(--red);
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip,
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip {
    display: block;
}
.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
    padding-right: calc(1.5em + 0.9375rem);
    background-position: top calc(0.375em + 0.23438rem) right
        calc(0.375em + 0.23438rem);
}
.custom-select.is-invalid,
.was-validated .custom-select:invalid {
    border-color: var(--red);
    padding-right: calc((1em + 0.9375rem) * 3 / 4 + 1.625rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%233b4863' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
            no-repeat right 0.625rem center/8px 10px,
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E")
            #fff no-repeat center right 1.625rem / calc(0.75em + 0.46875rem)
            calc(0.75em + 0.46875rem);
}
.custom-select.is-invalid:focus,
.was-validated .custom-select:invalid:focus {
    border-color: var(--red);
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip,
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip {
    display: block;
}
.form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip,
.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip {
    display: block;
}
.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
    color: var(--red);
}
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip,
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip {
    display: block;
}
.custom-control-input.is-invalid ~ .custom-control-label,
.was-validated .custom-control-input:invalid ~ .custom-control-label {
    color: var(--red);
}
.custom-control-input.is-invalid ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid ~ .custom-control-label::before {
    border-color: var(--red);
}
.custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip,
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip {
    display: block;
}
.custom-control-input.is-invalid:checked ~ .custom-control-label::before,
.was-validated
    .custom-control-input:invalid:checked
    ~ .custom-control-label::before {
    border-color: #e4606d;
    background-color: #e4606d;
}
.custom-control-input.is-invalid:focus ~ .custom-control-label::before,
.was-validated
    .custom-control-input:invalid:focus
    ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.custom-control-input.is-invalid:focus:not(:checked)
    ~ .custom-control-label::before,
.was-validated
    .custom-control-input:invalid:focus:not(:checked)
    ~ .custom-control-label::before {
    border-color: var(--red);
}
.custom-file-input.is-invalid ~ .custom-file-label,
.was-validated .custom-file-input:invalid ~ .custom-file-label {
    border-color: var(--red);
}
.custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip,
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip {
    display: block;
}
.custom-file-input.is-invalid:focus ~ .custom-file-label,
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
    border-color: var(--red);
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}
.form-inline .form-check {
    width: 100%;
}
@media (min-width: 576px) {
    .form-inline label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
    }
    .form-inline .form-group {
        display: flex;
        flex: 0 0 auto;
        flex-flow: row wrap;
        align-items: center;
        margin-bottom: 0;
    }
    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }
    .form-inline .form-control-plaintext {
        display: inline-block;
    }
    .form-inline .custom-select,
    .form-inline .input-group {
        width: auto;
    }
    .form-inline .form-check {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        padding-left: 0;
    }
    .form-inline .form-check-input {
        position: relative;
        flex-shrink: 0;
        margin-top: 0;
        margin-right: 0.25rem;
        margin-left: 0;
    }
    .form-inline .custom-control {
        align-items: center;
        justify-content: center;
    }
    .form-inline .custom-control-label {
        margin-bottom: 0;
    }
}
.btn,
.sp-container button,
.ui-datepicker-buttonpane button {
    display: inline-block;
    font-weight: 400;
    color: #001737;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.46875rem 0.9375rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .btn,
    .sp-container button,
    .ui-datepicker-buttonpane button {
        transition: none;
    }
}
.btn:hover,
.sp-container button:hover,
.ui-datepicker-buttonpane button:hover {
    color: #001737;
    text-decoration: none;
}
.btn.focus,
.btn:focus,
.sp-container button.focus,
.sp-container button:focus,
.ui-datepicker-buttonpane button.focus,
.ui-datepicker-buttonpane button:focus {
    outline: 0;
    box-shadow: none;
}
.btn.disabled,
.btn:disabled,
.sp-container button.disabled,
.sp-container button:disabled,
.ui-datepicker-buttonpane button.disabled,
.ui-datepicker-buttonpane button:disabled {
    opacity: 0.65;
}
a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none;
}
.btn-primary {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
}
.btn-primary:hover {
    color: #fff;
    background-color: var(--primary-dark);
    border-color: var(--primary-dark);
}
.btn-primary.focus,
.btn-primary:focus {
    box-shadow: 0 0 0 0.2rem rgba(39, 127, 251, 0.5);
}
.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: var(--primary-dark);
    border-color: var(--primary-dark);
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem var(--primary-light);
}
.btn-secondary,
.ui-datepicker-buttonpane button {
    color: #fff;
    background-color: var(--secondary);
    border-color: var(--secondary);
}
.btn-secondary:hover,
.ui-datepicker-buttonpane button:hover {
    color: #fff;
    background-color: #64738f;
    border-color: #5f6d88;
}
.btn-secondary.focus,
.btn-secondary:focus,
.ui-datepicker-buttonpane button.focus,
.ui-datepicker-buttonpane button:focus {
    box-shadow: 0 0 0 0.2rem rgba(141, 153, 175, 0.5);
}
.btn-secondary.disabled,
.btn-secondary:disabled,
.ui-datepicker-buttonpane button.disabled,
.ui-datepicker-buttonpane button:disabled {
    color: #fff;
    background-color: var(--secondary);
    border-color: var(--secondary);
}
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle,
.ui-datepicker-buttonpane .show > button.dropdown-toggle,
.ui-datepicker-buttonpane button:not(:disabled):not(.disabled).active,
.ui-datepicker-buttonpane button:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #5f6d88;
    border-color: #5a6780;
}
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus,
.ui-datepicker-buttonpane .show > button.dropdown-toggle:focus,
.ui-datepicker-buttonpane button:not(:disabled):not(.disabled).active:focus,
.ui-datepicker-buttonpane button:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem rgba(141, 153, 175, 0.5);
}
.btn-success {
    color: #fff;
    background-color: var(--green);
    border-color: var(--green);
}
.btn-success:hover {
    color: #fff;
    background-color: #22ae73;
    border-color: #21a66e;
}
.btn-success.focus,
.btn-success:focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 194, 114, 0.5);
}
.btn-success.disabled,
.btn-success:disabled {
    color: #fff;
    background-color: var(--green);
    border-color: var(--green);
}
.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #22ae73;
    border-color: #21a66e;
}
.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 194, 114, 0.5);
}
.btn-info {
    color: #fff;
    background-color: var(--teal) !important;
    border-color: var(--teal) !important;
}
.btn-info:hover {
    color: #fff;
    background-color: #0097ae;
    border-color: #008ca1;
}
.btn-info.focus,
.btn-info:focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 195, 218, 0.5);
}
.btn-info.disabled,
.btn-info:disabled {
    color: #fff;
    background-color: var(--teal);
    border-color: var(--teal);
}
.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008ca1;
    border-color: #008194;
}
.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 195, 218, 0.5);
}
.btn-warning {
    color: #332106;
    background-color: var(--orange);
    border-color: var(--orange);
}
.btn-warning:hover {
    color: #1c273c;
    background-color: #e0a800;
    border-color: #d39e00;
}
.btn-warning.focus,
.btn-warning:focus {
    box-shadow: 0 0 0 0.2rem rgba(221, 170, 15, 0.5);
}
.btn-warning.disabled,
.btn-warning:disabled {
    color: #1c273c;
    background-color: var(--orange);
    border-color: var(--orange);
}
.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show > .btn-warning.dropdown-toggle {
    color: #1c273c;
    background-color: #d39e00;
    border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(221, 170, 15, 0.5);
}
.btn-danger {
    color: #fff;
    background-color: var(--red);
    border-color: var(--red);
}
.btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
}
.btn-danger.focus,
.btn-danger:focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled,
.btn-danger:disabled {
    color: #fff;
    background-color: var(--red);
    border-color: var(--red);
}
.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-light {
    color: #1c273c;
    background-color: #f4f5f8;
    border-color: #f4f5f8;
}
.btn-light:hover {
    color: #1c273c;
    background-color: #dde0e9;
    border-color: #d5d9e4;
}
.btn-light.focus,
.btn-light:focus {
    box-shadow: 0 0 0 0.2rem rgba(212, 214, 220, 0.5);
}
.btn-light.disabled,
.btn-light:disabled {
    color: #1c273c;
    background-color: #f4f5f8;
    border-color: #f4f5f8;
}
.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
    color: #1c273c;
    background-color: #d5d9e4;
    border-color: #cdd2df;
}
.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(212, 214, 220, 0.5);
}
.btn-dark {
    color: #fff;
    background-color: #3b4863;
    border-color: #3b4863;
}
.btn-dark:hover {
    color: #fff;
    background-color: #2d374b;
    border-color: #283143;
}
.btn-dark.focus,
.btn-dark:focus {
    box-shadow: 0 0 0 0.2rem rgba(88, 99, 122, 0.5);
}
.btn-dark.disabled,
.btn-dark:disabled {
    color: #fff;
    background-color: #3b4863;
    border-color: #3b4863;
}
.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #283143;
    border-color: #232b3b;
}
.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(88, 99, 122, 0.5);
}
.btn-outline-primary {
    color: var(--primary);
    border-color: var(--primary);
}
.btn-outline-primary:hover {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
}
.btn-outline-primary.focus,
.btn-outline-primary:focus {
    box-shadow: 0 0 0 0.2rem rgba(1, 104, 250, 0.5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: var(--primary);
    background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
}
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(1, 104, 250, 0.5);
}
.btn-outline-secondary {
    color: var(--secondary);
    border-color: var(--secondary);
}
.btn-outline-secondary:hover {
    color: #fff;
    background-color: var(--secondary);
    border-color: var(--secondary);
}
.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
    box-shadow: 0 0 0 0.2rem rgba(121, 135, 161, 0.5);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
    color: var(--secondary);
    background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: var(--secondary);
    border-color: var(--secondary);
}
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(121, 135, 161, 0.5);
}
.btn-outline-success {
    color: var(--green);
    border-color: var(--green);
}
.btn-outline-success:hover {
    color: #fff;
    background-color: var(--green);
    border-color: var(--green);
}
.btn-outline-success.focus,
.btn-outline-success:focus {
    box-shadow: 0 0 0 0.2rem rgba(16, 183, 89, 0.5);
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
    color: var(--green);
    background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: var(--green);
    border-color: var(--green);
}
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(16, 183, 89, 0.5);
}
.btn-outline-info {
    color: var(--teal);
    border-color: var(--teal);
}
.btn-outline-info:hover {
    color: #fff;
    background-color: var(--teal);
    border-color: var(--teal);
}
.btn-outline-info.focus,
.btn-outline-info:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 184, 212, 0.5);
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
    color: var(--teal);
    background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: var(--teal);
    border-color: var(--teal);
}
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 184, 212, 0.5);
}
.btn-outline-warning {
    color: var(--orange);
    border-color: var(--orange);
}
.btn-outline-warning:hover {
    color: #1c273c;
    background-color: var(--orange);
    border-color: var(--orange);
}
.btn-outline-warning.focus,
.btn-outline-warning:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
    color: var(--orange);
    background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show > .btn-outline-warning.dropdown-toggle {
    color: #1c273c;
    background-color: var(--orange);
    border-color: var(--orange);
}
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-danger {
    color: var(--red);
    border-color: var(--red);
}
.btn-outline-danger:hover {
    color: #fff;
    background-color: var(--red);
    border-color: var(--red);
}
.btn-outline-danger.focus,
.btn-outline-danger:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
    color: var(--red);
    background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: var(--red);
    border-color: var(--red);
}
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-light {
    color: #f4f5f8;
    border-color: #f4f5f8;
}
.btn-outline-light:hover {
    color: #1c273c;
    background-color: #f4f5f8;
    border-color: #f4f5f8;
}
.btn-outline-light.focus,
.btn-outline-light:focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 245, 248, 0.5);
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
    color: #f4f5f8;
    background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show > .btn-outline-light.dropdown-toggle {
    color: #1c273c;
    background-color: #f4f5f8;
    border-color: #f4f5f8;
}
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 245, 248, 0.5);
}
.btn-outline-dark {
    color: #3b4863;
    border-color: #3b4863;
}
.btn-outline-dark:hover {
    color: #fff;
    background-color: #3b4863;
    border-color: #3b4863;
}
.btn-outline-dark.focus,
.btn-outline-dark:focus {
    box-shadow: 0 0 0 0.2rem rgba(59, 72, 99, 0.5);
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
    color: #3b4863;
    background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #3b4863;
    border-color: #3b4863;
}
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(59, 72, 99, 0.5);
}
.btn-link {
    font-weight: 400;
    color: var(--primary);
    text-decoration: none;
}
.btn-link:hover {
    color: #0148ae;
    text-decoration: none;
}
.btn-link.focus,
.btn-link:focus {
    text-decoration: none;
    box-shadow: none;
}
.btn-link.disabled,
.btn-link:disabled {
    color: var(--secondary);
    pointer-events: none;
}
.btn-group-lg > .btn,
.btn-lg,
.sp-container .btn-group-lg > button,
.ui-datepicker-buttonpane .btn-group-lg > button {
    padding: 0.6rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}
.btn-group-sm > .btn,
.btn-sm,
.sp-container .btn-group-sm > button,
.ui-datepicker-buttonpane .btn-group-sm > button,
.ui-datepicker-buttonpane button {
    padding: 0.391rem 0.5rem;
    font-size: 0.8125rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}
.btn-block {
    display: block;
    width: 100%;
}
.btn-block + .btn-block {
    margin-top: 0.5rem;
}
input[type="button"].btn-block,
input[type="reset"].btn-block,
input[type="submit"].btn-block {
    width: 100%;
}
.fade {
    transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
    .fade {
        transition: none;
    }
}
.fade:not(.show) {
    opacity: 0;
}
.collapse:not(.show) {
    display: none;
}
.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.2s ease;
}
.services .collapsing {
    transition: height 0s ease;
}
@media (prefers-reduced-motion: reduce) {
    .collapsing {
        transition: none;
    }
}
.dropdown,
.dropleft,
.dropright,
.dropup {
    position: relative;
}
.dropdown-toggle {
    white-space: nowrap;
}
.dropdown-toggle::after,
.font-select span:after {
    display: inline-block;
    margin-left: 6px;
    vertical-align: 0.1em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
    margin-left: 0;
}
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 100%;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 0.875rem;
    color: #001737;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.25rem;
    border: var(--border-light);
    border-color: #f0f2f5 !important;
}
.dropdown-menu-left {
    right: auto;
    left: 0;
}
.dropdown-menu-right {
    right: 0;
    left: auto;
}
@media (min-width: 576px) {
    .dropdown-menu-sm-left {
        right: auto;
        left: 0;
    }
    .dropdown-menu-sm-right {
        right: 0;
        left: auto;
    }
}
@media (min-width: 768px) {
    .dropdown-menu-md-left {
        right: auto;
        left: 0;
    }
    .dropdown-menu-md-right {
        right: 0;
        left: auto;
    }
}
@media (min-width: 992px) {
    .dropdown-menu-lg-left {
        right: auto;
        left: 0;
    }
    .dropdown-menu-lg-right {
        right: 0;
        left: auto;
    }
}
@media (min-width: 1200px) {
    .dropdown-menu-xl-left {
        right: auto;
        left: 0;
    }
    .dropdown-menu-xl-right {
        right: 0;
        left: auto;
    }
}
.dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
    margin-left: 0;
}
.dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
    margin-left: 0;
}
.dropright .dropdown-toggle::after {
    vertical-align: 0;
}
.dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
}
.dropleft .dropdown-toggle::after {
    display: none;
}
.dropleft .dropdown-toggle::before {
    display: inline-block;
    margin-right: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
    margin-left: 0;
}
.dropleft .dropdown-toggle::before {
    vertical-align: 0;
}
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="top"] {
    right: auto;
    bottom: auto;
}
.dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: var(--border-light);
}
.dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #1c273c;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}
.dropdown-item:focus,
.dropdown-item:hover {
    color: #141c2b;
    text-decoration: none;
    background-color: #f4f5f8;
}
.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: var(--primary) !important;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
    color: var(--secondary);
    pointer-events: none;
    background-color: transparent;
}
.dropdown-menu.show {
    display: block;
}
.dropdown-header {
    display: block;
    padding: 0.5rem 1.5rem;
    margin-bottom: 0;
    font-size: 0.76562rem;
    color: var(--secondary);
    white-space: nowrap;
}
.dropdown-item-text {
    display: block;
    padding: 0.25rem 1.5rem;
    color: #1c273c;
}
.btn-group,
.btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
}
.btn-group-vertical > .btn,
.btn-group > .btn,
.sp-container .btn-group-vertical > button,
.sp-container .btn-group > button,
.ui-datepicker-buttonpane .btn-group-vertical > button,
.ui-datepicker-buttonpane .btn-group > button {
    position: relative;
    flex: 1 1 auto;
}
.btn-group-vertical > .btn:hover,
.btn-group > .btn:hover,
.sp-container .btn-group-vertical > button:hover,
.sp-container .btn-group > button:hover,
.ui-datepicker-buttonpane .btn-group-vertical > button:hover,
.ui-datepicker-buttonpane .btn-group > button:hover {
    z-index: 1;
}
.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus,
.sp-container .btn-group-vertical > button.active,
.sp-container .btn-group-vertical > button:active,
.sp-container .btn-group-vertical > button:focus,
.sp-container .btn-group > button.active,
.sp-container .btn-group > button:active,
.sp-container .btn-group > button:focus,
.ui-datepicker-buttonpane .btn-group-vertical > button.active,
.ui-datepicker-buttonpane .btn-group-vertical > button:active,
.ui-datepicker-buttonpane .btn-group-vertical > button:focus,
.ui-datepicker-buttonpane .btn-group > button.active,
.ui-datepicker-buttonpane .btn-group > button:active,
.ui-datepicker-buttonpane .btn-group > button:focus {
    z-index: 1;
}
.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.btn-toolbar .input-group {
    width: auto;
}
.btn-group > .btn-group:not(:first-child),
.btn-group > .btn:not(:first-child),
.sp-container .btn-group > button:not(:first-child),
.ui-datepicker-buttonpane .btn-group > button:not(:first-child) {
    margin-left: -1px;
}
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.sp-container .btn-group > .btn-group:not(:last-child) > button,
.sp-container .btn-group > button:not(:last-child):not(.dropdown-toggle),
.ui-datepicker-buttonpane .btn-group > .btn-group:not(:last-child) > button,
.ui-datepicker-buttonpane
    .btn-group
    > button:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:not(:first-child),
.sp-container .btn-group > .btn-group:not(:first-child) > button,
.sp-container .btn-group > button:not(:first-child),
.ui-datepicker-buttonpane .btn-group > .btn-group:not(:first-child) > button,
.ui-datepicker-buttonpane .btn-group > button:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.dropdown-toggle-split {
    padding-right: 0.70312rem;
    padding-left: 0.70312rem;
}
.dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after {
    margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
    margin-right: 0;
}
.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-sm + .dropdown-toggle-split,
.sp-container .btn-group-sm > button + .dropdown-toggle-split,
.ui-datepicker-buttonpane .btn-group-sm > button + .dropdown-toggle-split,
.ui-datepicker-buttonpane button + .dropdown-toggle-split {
    padding-right: 0.375rem;
    padding-left: 0.375rem;
}
.btn-group-lg > .btn + .dropdown-toggle-split,
.btn-lg + .dropdown-toggle-split,
.sp-container .btn-group-lg > button + .dropdown-toggle-split,
.ui-datepicker-buttonpane .btn-group-lg > button + .dropdown-toggle-split {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
}
.btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group,
.sp-container .btn-group-vertical > button,
.ui-datepicker-buttonpane .btn-group-vertical > button {
    width: 100%;
}
.btn-group-vertical > .btn-group:not(:first-child),
.btn-group-vertical > .btn:not(:first-child),
.sp-container .btn-group-vertical > button:not(:first-child),
.ui-datepicker-buttonpane .btn-group-vertical > button:not(:first-child) {
    margin-top: -1px;
}
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.sp-container .btn-group-vertical > .btn-group:not(:last-child) > button,
.sp-container
    .btn-group-vertical
    > button:not(:last-child):not(.dropdown-toggle),
.ui-datepicker-buttonpane
    .btn-group-vertical
    > .btn-group:not(:last-child)
    > button,
.ui-datepicker-buttonpane
    .btn-group-vertical
    > button:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn:not(:first-child),
.sp-container .btn-group-vertical > .btn-group:not(:first-child) > button,
.sp-container .btn-group-vertical > button:not(:first-child),
.ui-datepicker-buttonpane
    .btn-group-vertical
    > .btn-group:not(:first-child)
    > button,
.ui-datepicker-buttonpane .btn-group-vertical > button:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn,
.sp-container .btn-group-toggle > .btn-group > button,
.sp-container .btn-group-toggle > button,
.ui-datepicker-buttonpane .btn-group-toggle > .btn-group > button,
.ui-datepicker-buttonpane .btn-group-toggle > button {
    margin-bottom: 0;
}
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.sp-container .btn-group-toggle > .btn-group > button input[type="checkbox"],
.sp-container .btn-group-toggle > .btn-group > button input[type="radio"],
.sp-container .btn-group-toggle > button input[type="checkbox"],
.sp-container .btn-group-toggle > button input[type="radio"],
.ui-datepicker-buttonpane
    .btn-group-toggle
    > .btn-group
    > button
    input[type="checkbox"],
.ui-datepicker-buttonpane
    .btn-group-toggle
    > .btn-group
    > button
    input[type="radio"],
.ui-datepicker-buttonpane .btn-group-toggle > button input[type="checkbox"],
.ui-datepicker-buttonpane .btn-group-toggle > button input[type="radio"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
}
.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}
.input-group > .custom-file,
.input-group > .custom-select,
.input-group > .form-control,
.input-group > .form-control-plaintext {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
}
.input-group > .custom-file + .custom-file,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .form-control,
.input-group > .custom-select + .custom-file,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .form-control,
.input-group > .form-control + .custom-file,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .form-control,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .form-control {
    margin-left: -1px;
}
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label,
.input-group > .custom-select:focus,
.input-group > .form-control:focus {
    z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
    z-index: 4;
}
.input-group > .custom-select:not(:last-child),
.input-group > .form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.input-group > .custom-select:not(:first-child),
.input-group > .form-control:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.input-group > .custom-file {
    display: flex;
    align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.input-group-append,
.input-group-prepend {
    display: flex;
}
.input-group-append .btn,
.input-group-append .sp-container button,
.input-group-append .ui-datepicker-buttonpane button,
.input-group-prepend .btn,
.input-group-prepend .sp-container button,
.input-group-prepend .ui-datepicker-buttonpane button,
.sp-container .input-group-append button,
.sp-container .input-group-prepend button,
.ui-datepicker-buttonpane .input-group-append button,
.ui-datepicker-buttonpane .input-group-prepend button {
    position: relative;
    z-index: 2;
}
.input-group-append .btn:focus,
.input-group-append .sp-container button:focus,
.input-group-append .ui-datepicker-buttonpane button:focus,
.input-group-prepend .btn:focus,
.input-group-prepend .sp-container button:focus,
.input-group-prepend .ui-datepicker-buttonpane button:focus,
.sp-container .input-group-append button:focus,
.sp-container .input-group-prepend button:focus,
.ui-datepicker-buttonpane .input-group-append button:focus,
.ui-datepicker-buttonpane .input-group-prepend button:focus {
    z-index: 3;
}
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .btn,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .sp-container .btn + button,
.input-group-append .sp-container .input-group-text + button,
.input-group-append .sp-container .ui-datepicker-buttonpane button + button,
.input-group-append .sp-container button + .btn,
.input-group-append .sp-container button + .input-group-text,
.input-group-append .sp-container button + button,
.input-group-append .ui-datepicker-buttonpane .btn + button,
.input-group-append .ui-datepicker-buttonpane .input-group-text + button,
.input-group-append .ui-datepicker-buttonpane .sp-container button + button,
.input-group-append .ui-datepicker-buttonpane button + .btn,
.input-group-append .ui-datepicker-buttonpane button + .input-group-text,
.input-group-append .ui-datepicker-buttonpane button + button,
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .sp-container .btn + button,
.input-group-prepend .sp-container .input-group-text + button,
.input-group-prepend .sp-container .ui-datepicker-buttonpane button + button,
.input-group-prepend .sp-container button + .btn,
.input-group-prepend .sp-container button + .input-group-text,
.input-group-prepend .sp-container button + button,
.input-group-prepend .ui-datepicker-buttonpane .btn + button,
.input-group-prepend .ui-datepicker-buttonpane .input-group-text + button,
.input-group-prepend .ui-datepicker-buttonpane .sp-container button + button,
.input-group-prepend .ui-datepicker-buttonpane button + .btn,
.input-group-prepend .ui-datepicker-buttonpane button + .input-group-text,
.input-group-prepend .ui-datepicker-buttonpane button + button,
.sp-container .input-group-append .btn + button,
.sp-container .input-group-append .input-group-text + button,
.sp-container .input-group-append .ui-datepicker-buttonpane button + button,
.sp-container .input-group-append button + .btn,
.sp-container .input-group-append button + .input-group-text,
.sp-container .input-group-append button + button,
.sp-container .input-group-prepend .btn + button,
.sp-container .input-group-prepend .input-group-text + button,
.sp-container .input-group-prepend .ui-datepicker-buttonpane button + button,
.sp-container .input-group-prepend button + .btn,
.sp-container .input-group-prepend button + .input-group-text,
.sp-container .input-group-prepend button + button,
.sp-container .ui-datepicker-buttonpane .input-group-append button + button,
.sp-container .ui-datepicker-buttonpane .input-group-prepend button + button,
.ui-datepicker-buttonpane .input-group-append .btn + button,
.ui-datepicker-buttonpane .input-group-append .input-group-text + button,
.ui-datepicker-buttonpane .input-group-append .sp-container button + button,
.ui-datepicker-buttonpane .input-group-append button + .btn,
.ui-datepicker-buttonpane .input-group-append button + .input-group-text,
.ui-datepicker-buttonpane .input-group-append button + button,
.ui-datepicker-buttonpane .input-group-prepend .btn + button,
.ui-datepicker-buttonpane .input-group-prepend .input-group-text + button,
.ui-datepicker-buttonpane .input-group-prepend .sp-container button + button,
.ui-datepicker-buttonpane .input-group-prepend button + .btn,
.ui-datepicker-buttonpane .input-group-prepend button + .input-group-text,
.ui-datepicker-buttonpane .input-group-prepend button + button,
.ui-datepicker-buttonpane .sp-container .input-group-append button + button,
.ui-datepicker-buttonpane .sp-container .input-group-prepend button + button {
    margin-left: -1px;
}
.input-group-prepend {
    margin-right: -1px;
}
.input-group-append {
    margin-left: -1px;
}
.input-group-text {
    display: flex;
    align-items: center;
    padding: 0.46875rem 0.625rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #596882;
    text-align: center;
    white-space: nowrap;
    background-color: #f5f6fa;
    border: 1px solid #c0ccda;
    border-radius: 0.25rem;
}
.input-group-text input[type="checkbox"],
.input-group-text input[type="radio"] {
    margin-top: 0;
}
.input-group-lg > .custom-select,
.input-group-lg > .form-control:not(textarea) {
    height: calc(1.5em + 1.2rem + 2px);
}
.input-group-lg > .custom-select,
.input-group-lg > .form-control,
.input-group-lg > .input-group-append > .btn,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-prepend > .input-group-text,
.sp-container .input-group-lg > .input-group-append > button,
.sp-container .input-group-lg > .input-group-prepend > button,
.ui-datepicker-buttonpane .input-group-lg > .input-group-append > button,
.ui-datepicker-buttonpane .input-group-lg > .input-group-prepend > button {
    padding: 0.6rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}
.input-group-sm > .custom-select,
.input-group-sm > .form-control:not(textarea) {
    height: calc(1.5em + 0.782rem + 2px);
}
.input-group-sm > .custom-select,
.input-group-sm > .form-control,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .input-group-text,
.sp-container .input-group-sm > .input-group-append > button,
.sp-container .input-group-sm > .input-group-prepend > button,
.ui-datepicker-buttonpane .input-group-sm > .input-group-append > button,
.ui-datepicker-buttonpane .input-group-sm > .input-group-prepend > button {
    padding: 0.391rem 0.5rem;
    font-size: 0.8125rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}
.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
    padding-right: 1.625rem;
}
.input-group
    > .input-group-append:last-child
    > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
    > .input-group-append:last-child
    > .input-group-text:not(:last-child),
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.sp-container
    .input-group
    > .input-group-append:last-child
    > button:not(:last-child):not(.dropdown-toggle),
.sp-container .input-group > .input-group-append:not(:last-child) > button,
.sp-container .input-group > .input-group-prepend > button,
.ui-datepicker-buttonpane
    .input-group
    > .input-group-append:last-child
    > button:not(:last-child):not(.dropdown-toggle),
.ui-datepicker-buttonpane
    .input-group
    > .input-group-append:not(:last-child)
    > button,
.ui-datepicker-buttonpane .input-group > .input-group-prepend > button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group
    > .input-group-prepend:first-child
    > .input-group-text:not(:first-child),
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.sp-container .input-group > .input-group-append > button,
.sp-container
    .input-group
    > .input-group-prepend:first-child
    > button:not(:first-child),
.sp-container .input-group > .input-group-prepend:not(:first-child) > button,
.ui-datepicker-buttonpane .input-group > .input-group-append > button,
.ui-datepicker-buttonpane
    .input-group
    > .input-group-prepend:first-child
    > button:not(:first-child),
.ui-datepicker-buttonpane
    .input-group
    > .input-group-prepend:not(:first-child)
    > button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.custom-control {
    position: relative;
    display: block;
    min-height: 1.3125rem;
    padding-left: 1.5rem;
}
.custom-control-inline {
    display: inline-flex;
    margin-right: 1rem;
}
.custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: var(--primary);
    background-color: var(--primary);
}
.custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 2px 1.2px var(--primary-light);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: var(--primary-light);
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #afd0ff;
    border-color: #afd0ff;
}
.custom-control-input:disabled ~ .custom-control-label {
    color: var(--secondary);
}
.custom-control-input:disabled ~ .custom-control-label::before {
    background-color: #e3e7ed;
}
.custom-control-label {
    cursor: pointer;
    position: relative;
    margin-bottom: 0;
}
.custom-control-label::before {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #97a3b9 solid 1px;
}
.custom-control-label::after {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50%/50% 50%;
}
.custom-checkbox .custom-control-label::before {
    border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA4IDgnPjxwYXRoIGZpbGw9JyNmZmYnIGQ9J002LjU2NC43NWwtMy41OSAzLjYxMi0xLjUzOC0xLjU1TDAgNC4yNiAyLjk3NCA3LjI1IDggMi4xOTN6Jy8+PC9zdmc+);
}
.custom-checkbox
    .custom-control-input:indeterminate
    ~ .custom-control-label::before {
    border-color: var(--primary);
    background-color: var(--primary);
}
.custom-checkbox
    .custom-control-input:indeterminate
    ~ .custom-control-label::after {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA0IDQnPjxwYXRoIHN0cm9rZT0nI2ZmZicgZD0nTTAgMmg0Jy8+PC9zdmc+);
}
.custom-checkbox
    .custom-control-input:disabled:checked
    ~ .custom-control-label::before {
    background-color: rgba(1, 104, 250, 0.5);
}
.custom-checkbox
    .custom-control-input:disabled:indeterminate
    ~ .custom-control-label::before {
    background-color: rgba(1, 104, 250, 0.5);
}
.custom-radio .custom-control-label::before {
    border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9Jy00IC00IDggOCc+PGNpcmNsZSByPSczJyBmaWxsPScjZmZmJy8+PC9zdmc+);
}
.custom-radio
    .custom-control-input:disabled:checked
    ~ .custom-control-label::before {
    background-color: rgba(1, 104, 250, 0.5);
}
.custom-switch {
    padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
    top: calc(0.15625rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #97a3b9;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .custom-switch .custom-control-label::after {
        transition: none;
    }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem);
}
.custom-switch
    .custom-control-input:disabled:checked
    ~ .custom-control-label::before {
    background-color: rgba(1, 104, 250, 0.5);
}
.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.9375rem + 2px);
    padding: 0.46875rem 1.625rem 0.46875rem 0.625rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #596882;
    vertical-align: middle;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%233b4863' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
        no-repeat right 0.625rem center/8px 10px;
    background-color: #fff;
    border: 1px solid #c0ccda;
    border-radius: 0.25rem;
    appearance: none;
}
.custom-select:focus {
    border-color: var(--primary-light);
    outline: 0;
    box-shadow: 0 0 2px 1.2px var(--primary-light);
}
.custom-select:focus::-ms-value {
    color: #596882;
    background-color: #fff;
}
.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.625rem;
    background-image: none;
}
.custom-select:disabled {
    color: var(--secondary);
    background-color: #e3e7ed;
}
.custom-select::-ms-expand {
    display: none;
}
.custom-select-sm {
    height: calc(1.5em + 0.782rem + 2px);
    padding-top: 0.391rem;
    padding-bottom: 0.391rem;
    padding-left: 0.5rem;
    font-size: 0.8125rem;
}
.custom-select-lg {
    height: calc(1.5em + 1.2rem + 2px);
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    padding-left: 1rem;
    font-size: 1.09375rem;
}
.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.9375rem + 2px);
    margin-bottom: 0;
}
.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.5em + 0.9375rem + 2px);
    margin: 0;
    opacity: 0;
    cursor: pointer;
}
.custom-file-input:focus ~ .custom-file-label {
    border-color: var(--primary-light);
    box-shadow: 0 0 2px 1.2px var(--primary-light);
}
.custom-file-input:disabled ~ .custom-file-label {
    background-color: #e3e7ed;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse);
}
.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.5em + 0.9375rem + 2px);
    padding: 0.46875rem 0.625rem;
    font-weight: 400;
    line-height: 1.5;
    color: #596882;
    background-color: #fff;
    border: 1px solid #c0ccda;
    border-radius: 0.25rem;
}
.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.9375rem);
    padding: 0.46875rem 0.625rem;
    line-height: 1.5;
    color: #596882;
    content: "Browse";
    background-color: #f5f6fa;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0;
}
.custom-range {
    width: 100%;
    height: calc(1rem + 0.4rem);
    padding: 0;
    background-color: transparent;
    appearance: none;
}
.custom-range:focus {
    outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 2px 1.2px var(--primary-light);
}
.custom-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 2px 1.2px var(--primary-light);
}
.custom-range:focus::-ms-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 2px 1.2px var(--primary-light);
}
.custom-range::-moz-focus-outer {
    border: 0;
}
.custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: var(--primary);
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
}
@media (prefers-reduced-motion: reduce) {
    .custom-range::-webkit-slider-thumb {
        transition: none;
    }
}
.custom-range::-webkit-slider-thumb:active {
    background-color: #afd0ff;
}
.custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #cdd4e0;
    border-color: transparent;
    border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: var(--primary);
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
}
@media (prefers-reduced-motion: reduce) {
    .custom-range::-moz-range-thumb {
        transition: none;
    }
}
.custom-range::-moz-range-thumb:active {
    background-color: #afd0ff;
}
.custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #cdd4e0;
    border-color: transparent;
    border-radius: 1rem;
}
.custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: var(--primary);
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
}
@media (prefers-reduced-motion: reduce) {
    .custom-range::-ms-thumb {
        transition: none;
    }
}
.custom-range::-ms-thumb:active {
    background-color: #afd0ff;
}
.custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
    background-color: #cdd4e0;
    border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #cdd4e0;
    border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
    background-color: #97a3b9;
}
.custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
    background-color: #97a3b9;
}
.custom-range:disabled::-moz-range-track {
    cursor: default;
}
.custom-range:disabled::-ms-thumb {
    background-color: #97a3b9;
}
.custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
        transition: none;
    }
}
.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
.nav-link {
    display: block;
    padding: 0.5rem 1rem;
}
.nav-link:focus,
.nav-link:hover {
    text-decoration: none;
}
.nav-link.disabled {
    color: var(--secondary);
    pointer-events: none;
    cursor: default;
}
.nav-tabs {
    border-bottom: 1px solid #cdd4e0;
}
.nav-tabs .nav-item {
    margin-bottom: -1px;
}
.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: #e3e7ed #e3e7ed #cdd4e0;
}
.nav-tabs .nav-link.disabled {
    color: var(--secondary);
    background-color: transparent;
    border-color: transparent;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #596882;
    background-color: #fff;
    border-color: #cdd4e0 #cdd4e0 #fff;
}
.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.nav-pills .nav-link {
    border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    color: #fff;
    background-color: var(--primary);
}
.nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center;
}
.nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
}
.tab-content > .tab-pane {
    display: none;
}
.tab-content > .active {
    display: block;
}
.navbar {
    position: fixed !important;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
}
.navbar > .container,
.navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}
.navbar-brand {
    display: inline-block;
    padding-top: 0.33594rem;
    padding-bottom: 0.33594rem;
    margin-right: 1rem;
    font-size: 1.09375rem;
    line-height: inherit;
    white-space: nowrap;
}
.navbar-brand:focus,
.navbar-brand:hover {
    text-decoration: none;
}
.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}
.navbar-nav .dropdown-menu {
    position: static;
    float: none;
}
.navbar-text {
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
}
.navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.09375rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}
.navbar-toggler:focus,
.navbar-toggler:hover {
    text-decoration: none;
}
.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
}
@media (max-width: 575.98px) {
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}
@media (min-width: 576px) {
    .navbar-expand-sm {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-sm .navbar-nav {
        flex-direction: row;
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
        flex-wrap: nowrap;
    }
    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-expand-sm .navbar-toggler {
        display: none;
    }
}
@media (max-width: 767.98px) {
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}
@media (min-width: 768px) {
    .navbar-expand-md {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-md .navbar-nav {
        flex-direction: row;
    }
    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
        flex-wrap: nowrap;
    }
    .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-expand-md .navbar-toggler {
        display: none;
    }
}
@media (max-width: 991.98px) {
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}
@media (min-width: 992px) {
    .navbar-expand-lg {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-lg .navbar-nav {
        flex-direction: row;
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
        flex-wrap: nowrap;
    }
    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-expand-lg .navbar-toggler {
        display: none;
    }
}
@media (max-width: 1199.98px) {
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}
@media (min-width: 1200px) {
    .navbar-expand-xl {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-xl .navbar-nav {
        flex-direction: row;
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
        flex-wrap: nowrap;
    }
    .navbar-expand-xl .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-expand-xl .navbar-toggler {
        display: none;
    }
}
.navbar-expand {
    flex-flow: row nowrap;
    justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0;
}
.navbar-expand .navbar-nav {
    flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
    flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
}
.navbar-expand .navbar-toggler {
    display: none;
}
.card {
    box-shadow: var(--box-shadow);
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: var(--card-background);
    background-clip: border-box;
    border-radius: 0.25rem;
}
.card > hr {
    margin-right: 0;
    margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}
.card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}
.card-body {
    flex: 1 1 auto;
    padding: 1.25rem;
}
.card-title {
    margin-bottom: 0.75rem;
}
.card-subtitle {
    margin-top: -0.375rem;
    margin-bottom: 0;
}
.card-text:last-child {
    margin-bottom: 0;
}
.card-link:hover {
    text-decoration: none;
}
.card-link + .card-link {
    margin-left: 1.25rem;
}
.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(72, 94, 144, 0.16);
}
.card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
    border-top: 0;
}
.card-footer {
    padding: 0.75rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(72, 94, 144, 0.16);
}
.card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}
.card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -0.75rem;
    margin-left: -0.625rem;
    border-bottom: 0;
}
.card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
}
.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
}
.card-img {
    width: 100%;
    border-radius: calc(0.25rem - 1px);
}
.card-img-top {
    width: 100%;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
}
.card-img-bottom {
    width: 100%;
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px);
}
.card-deck {
    display: flex;
    flex-direction: column;
}
.card-deck .card {
    margin-bottom: 15px;
}
@media (min-width: 576px) {
    .card-deck {
        flex-flow: row wrap;
        margin-right: -15px;
        margin-left: -15px;
    }
    .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px;
    }
}
.card-group {
    display: flex;
    flex-direction: column;
}
.card-group > .card {
    margin-bottom: 15px;
}
@media (min-width: 576px) {
    .card-group {
        flex-flow: row wrap;
    }
    .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0;
    }
    .card-group > .card + .card {
        margin-left: 0;
        border-left: 0;
    }
    .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .card-group > .card:not(:last-child) .card-header,
    .card-group > .card:not(:last-child) .card-img-top {
        border-top-right-radius: 0;
    }
    .card-group > .card:not(:last-child) .card-footer,
    .card-group > .card:not(:last-child) .card-img-bottom {
        border-bottom-right-radius: 0;
    }
    .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .card-group > .card:not(:first-child) .card-header,
    .card-group > .card:not(:first-child) .card-img-top {
        border-top-left-radius: 0;
    }
    .card-group > .card:not(:first-child) .card-footer,
    .card-group > .card:not(:first-child) .card-img-bottom {
        border-bottom-left-radius: 0;
    }
}
.card-columns .card {
    margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
    .card-columns {
        column-count: 3;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1;
    }
    .card-columns .card {
        display: inline-block;
        width: 100%;
    }
}
.accordion > .card {
    overflow: hidden;
}
.accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0;
}
.accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0;
}
.accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.accordion > .card .card-header {
    margin-bottom: -1px;
}
.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #e3e7ed;
    border-radius: 0.25rem;
}
.breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: var(--secondary);
    content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none;
}
.breadcrumb-item.active {
    color: var(--secondary);
}
.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
}
.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: var(--primary);
    background-color: #fff;
    border: 1px solid #c0ccda4a !important;
}
.page-link:hover {
    z-index: 2;
    color: #0148ae;
    text-decoration: none;
    background-color: #e3e7ed;
    border-color: #cdd4e0;
}
.page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 2px 1.2px var(--primary-light);
}
.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
}
.page-item.disabled .page-link {
    color: var(--secondary);
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #cdd4e0;
}
.pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.09375rem;
    line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}
.pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.76562rem;
    line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
}
.badge {
    text-transform: capitalize;
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .badge {
        transition: none;
    }
}
a.badge:focus,
a.badge:hover {
    text-decoration: none;
}
.badge:empty {
    display: none;
}
.btn .badge,
.sp-container button .badge,
.ui-datepicker-buttonpane button .badge {
    position: relative;
    top: -1px;
}
.badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
}
.badge-primary {
    color: #fff;
    background-color: var(--primary);
}
a.badge-primary:focus,
a.badge-primary:hover {
    color: #fff;
    background-color: #0153c7;
}
a.badge-primary.focus,
a.badge-primary:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(1, 104, 250, 0.5);
}
.badge-secondary {
    color: #fff;
    background-color: var(--secondary);
}
a.badge-secondary:focus,
a.badge-secondary:hover {
    color: #fff;
    background-color: #5f6d88;
}
a.badge-secondary.focus,
a.badge-secondary:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(121, 135, 161, 0.5);
}
.badge-success {
    color: #fff;
    background-color: var(--green);
}
a.badge-success:focus,
a.badge-success:hover {
    color: #fff;
    background-color: #0c8842;
}
a.badge-success.focus,
a.badge-success:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(16, 183, 89, 0.5);
}
.badge-info {
    color: #fff;
    background-color: var(--teal);
}
a.badge-info:focus,
a.badge-info:hover {
    color: #fff;
    background-color: #008ca1;
}
a.badge-info.focus,
a.badge-info:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 184, 212, 0.5);
}
.badge-warning {
    color: #1c273c;
    background-color: var(--orange);
}
a.badge-warning:focus,
a.badge-warning:hover {
    color: #1c273c;
    background-color: #d39e00;
}
a.badge-warning.focus,
a.badge-warning:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.badge-danger {
    color: #fff;
    background-color: var(--red);
}
a.badge-danger:focus,
a.badge-danger:hover {
    color: #fff;
    background-color: #eb3143;
}
a.badge-danger.focus,
a.badge-danger:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.badge-light {
    color: #1c273c;
    background-color: #f4f5f8;
}
a.badge-light:focus,
a.badge-light:hover {
    color: #1c273c;
    background-color: #d5d9e4;
}
a.badge-light.focus,
a.badge-light:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(244, 245, 248, 0.5);
}
.badge-dark {
    color: #fff;
    background-color: #3b4863;
}
a.badge-dark:focus,
a.badge-dark:hover {
    color: #fff;
    background-color: #283143;
}
a.badge-dark.focus,
a.badge-dark:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(59, 72, 99, 0.5);
}
.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #e3e7ed;
    border-radius: 0.3rem;
}
@media (min-width: 576px) {
    .jumbotron {
        padding: 4rem 2rem;
    }
}
.jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0;
}
.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}
.alert-heading {
    color: inherit;
}
.alert-link {
    font-weight: 700;
}
.alert-dismissible {
    padding-right: 3.8125rem;
}
.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit;
}
.alert-primary {
    color: #013682;
    background-color: #cce1fe;
    border-color: #b8d5fe;
}
.alert-primary hr {
    border-top-color: #9fc6fe;
}
.alert-primary .alert-link {
    color: #01214f;
}
.alert-secondary {
    color: #3f4654;
    background-color: #e4e7ec;
    border-color: #d9dde5;
}
.alert-secondary hr {
    border-top-color: #cacfdb;
}
.alert-secondary .alert-link {
    color: #292e37;
}
.alert-success {
    color: #085f2e;
    background-color: #cff1de;
    border-color: #bcebd1;
}
.alert-success hr {
    border-top-color: #a8e5c4;
}
.alert-success .alert-link {
    color: #043017;
}
.alert-info {
    color: #00606e;
    background-color: #ccf1f6;
    border-color: #b8ebf3;
}
.alert-info hr {
    border-top-color: #a2e5ef;
}
.alert-info .alert-link {
    color: #00333b;
}
.alert-warning {
    color: #473604;
    background-color: #ff980052;
    border-color: #f8d5a1;
}
.alert-warning hr {
    border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
    color: #533f03;
}
.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}
.alert-danger hr {
    border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
    color: #491217;
}
.alert-light {
    color: #7f7f81;
    background-color: #fdfdfe;
    border-color: #fcfcfd;
}
.alert-light hr {
    border-top-color: #ededf3;
}
.alert-light .alert-link {
    color: #666667;
}
.alert-dark {
    color: #1f2533;
    background-color: #d8dae0;
    border-color: #c8ccd3;
}
.alert-dark hr {
    border-top-color: #babfc8;
}
.alert-dark .alert-link {
    color: #0c0e13;
}
@keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0;
    }
    to {
        background-position: 0 0;
    }
}
.progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: 0.65625rem;
    background-color: #e3e7ed;
    border-radius: 0.25rem;
}
.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: var(--primary);
    transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
    .progress-bar {
        transition: none;
    }
}
.progress-bar-striped {
    background-image: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent
    );
    background-size: 1rem 1rem;
}
.progress-bar-animated {
    animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
        animation: none;
    }
}
.media {
    display: flex;
    align-items: flex-start;
}
.media-body {
    flex: 1;
}
.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
}
.list-group-item-action {
    width: 100%;
    color: #596882;
    text-align: inherit;
}
.list-group-item-action:focus,
.list-group-item-action:hover {
    z-index: 1;
    color: #596882;
    text-decoration: none;
    background-color: #f4f5f8;
}
.list-group-item-action:active {
    color: #001737;
    background-color: #e3e7ed;
}
.list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}
.list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}
.list-group-item.disabled,
.list-group-item:disabled {
    color: var(--secondary);
    pointer-events: none;
    background-color: #fff;
}
.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
}
.list-group-horizontal {
    flex-direction: row;
}
.list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
}
.list-group-horizontal .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
}
.list-group-horizontal .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
}
@media (min-width: 576px) {
    .list-group-horizontal-sm {
        flex-direction: row;
    }
    .list-group-horizontal-sm .list-group-item {
        margin-right: -1px;
        margin-bottom: 0;
    }
    .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
    }
    .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0;
    }
}
@media (min-width: 768px) {
    .list-group-horizontal-md {
        flex-direction: row;
    }
    .list-group-horizontal-md .list-group-item {
        margin-right: -1px;
        margin-bottom: 0;
    }
    .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
    }
    .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0;
    }
}
@media (min-width: 992px) {
    .list-group-horizontal-lg {
        flex-direction: row;
    }
    .list-group-horizontal-lg .list-group-item {
        margin-right: -1px;
        margin-bottom: 0;
    }
    .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
    }
    .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0;
    }
}
@media (min-width: 1200px) {
    .list-group-horizontal-xl {
        flex-direction: row;
    }
    .list-group-horizontal-xl .list-group-item {
        margin-right: -1px;
        margin-bottom: 0;
    }
    .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
    }
    .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0;
    }
}
.list-group-flush .list-group-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
}
.list-group-flush .list-group-item:last-child {
    margin-bottom: -1px;
}
.list-group-flush:first-child .list-group-item:first-child {
    border-top: 0;
}
.list-group-flush:last-child .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom: 0;
}
.list-group-item-primary {
    color: #013682;
    background-color: #b8d5fe;
}
.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
    color: #013682;
    background-color: #9fc6fe;
}
.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #013682;
    border-color: #013682;
}
.list-group-item-secondary {
    color: #3f4654;
    background-color: #d9dde5;
}
.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
    color: #3f4654;
    background-color: #cacfdb;
}
.list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #3f4654;
    border-color: #3f4654;
}
.list-group-item-success {
    color: #085f2e;
    background-color: #bcebd1;
}
.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
    color: #085f2e;
    background-color: #a8e5c4;
}
.list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #085f2e;
    border-color: #085f2e;
}
.list-group-item-info {
    color: #00606e;
    background-color: #b8ebf3;
}
.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
    color: #00606e;
    background-color: #a2e5ef;
}
.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #00606e;
    border-color: #00606e;
}
.list-group-item-warning {
    color: #856404;
    background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
    color: #856404;
    background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404;
}
.list-group-item-danger {
    color: #721c24;
    background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
    color: #721c24;
    background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24;
}
.list-group-item-light {
    color: #7f7f81;
    background-color: #fcfcfd;
}
.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
    color: #7f7f81;
    background-color: #ededf3;
}
.list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #7f7f81;
    border-color: #7f7f81;
}
.list-group-item-dark {
    color: #1f2533;
    background-color: #c8ccd3;
}
.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
    color: #1f2533;
    background-color: #babfc8;
}
.list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1f2533;
    border-color: #1f2533;
}
.close {
    float: right;
    font-size: 1.3125rem;
    font-weight: 700;
    line-height: 1;
    color: #1b2e4b;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
}
.close:hover {
    color: #1b2e4b;
    text-decoration: none;
}
.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
    opacity: 0.75;
}
button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    appearance: none;
}
a.close.disabled {
    pointer-events: none;
}
.toast {
    max-width: 350px;
    overflow: hidden;
    font-size: 0.875rem;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    opacity: 0;
    border-radius: 0.25rem;
}
.toast:not(:last-child) {
    margin-bottom: 0.75rem;
}
.toast.showing {
    opacity: 1;
}
.toast.show {
    display: block;
    opacity: 1;
}
.toast.hide {
    display: none;
}
.toast-header {
    display: flex;
    align-items: center;
    padding: 0.25rem 0.75rem;
    color: var(--secondary);
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.toast-body {
    padding: 0.75rem;
}
.modal-open {
    overflow: hidden;
}
.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
    background: #aaaaaa75;
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1002;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}
.modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
}
.modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
        transition: none;
    }
}
.modal.show .modal-dialog {
    transform: none;
}
.modal-dialog-scrollable {
    display: flex;
    max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content,
.modal-dialog-scrollable .modal-content-main {
    max-height: calc(100vh - 1rem);
    overflow: hidden;
}
.modal-dialog-scrollable .modal-footer,
.modal-dialog-scrollable .modal-header {
    flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
    overflow-y: auto;
    max-height: calc(100vh - 150px);
}
.centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
}
.centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: "";
}
.centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%;
}
.centered.modal-dialog-scrollable .modal-content,
.centered.modal-dialog-scrollable.modal-content-main {
    max-height: none;
}
.centered.modal-dialog-scrollable::before {
    content: none;
}
.modal-content,
.modal-content-main {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
}
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}
.modal-backdrop.fade {
    opacity: 0;
}
.modal-backdrop.show {
    opacity: 0.5;
}
.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px 15px;
    border-bottom: 1px solid rgba(72, 94, 144, 0.16);
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}
.modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
}
.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
}
.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
}
.modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid rgba(72, 94, 144, 0.16);
    border-bottom-right-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
}
.modal-footer > :not(:first-child) {
    margin-left: 0.25rem;
}
.modal-footer > :not(:last-child) {
    margin-right: 0.25rem;
}
.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}
@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }
    .modal-dialog-scrollable {
        max-height: calc(100% - 3.5rem);
    }
    .modal-dialog-scrollable .modal-content,
    .modal-dialog-scrollable.modal-content-main {
        max-height: calc(100vh - 3.5rem);
    }
    .centered {
        min-height: calc(100% - 3.5rem);
    }
    .centered::before {
        height: calc(100vh - 3.5rem);
    }
    .modal-sm {
        max-width: 300px;
    }
}
@media (min-width: 992px) {
    .modal-lg,
    .modal-xl {
        max-width: 800px;
    }
}
@media (min-width: 1200px) {
    .modal-xl {
        max-width: 1140px;
    }
}
.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.76562rem;
    word-wrap: break-word;
    opacity: 0;
}
.tooltip.show {
    opacity: 0.9;
}
.tooltip .arrow {
    position: absolute;
    display: block !important;
    width: 0.8rem;
    height: 0.4rem;
}
.tooltip .arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid;
}
.bs-tooltip-auto[x-placement^="top"],
.bs-tooltip-top {
    padding: 0.4rem 0;
}
.bs-tooltip-auto[x-placement^="top"] .arrow,
.bs-tooltip-top .arrow {
    bottom: 0;
}
.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
    top: 0;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #000;
}
.bs-tooltip-auto[x-placement^="right"],
.bs-tooltip-right {
    padding: 0 0.4rem;
}
.bs-tooltip-auto[x-placement^="right"] .arrow,
.bs-tooltip-right .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem;
}
.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::before {
    right: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #000;
}
.bs-tooltip-auto[x-placement^="bottom"],
.bs-tooltip-bottom {
    padding: 0.4rem 0;
}
.bs-tooltip-auto[x-placement^="bottom"] .arrow,
.bs-tooltip-bottom .arrow {
    top: 0;
}
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before {
    bottom: 0;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #000;
}
.bs-tooltip-auto[x-placement^="left"],
.bs-tooltip-left {
    padding: 0 0.4rem;
}
.bs-tooltip-auto[x-placement^="left"] .arrow,
.bs-tooltip-left .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem;
}
.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::before {
    left: 0;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #000;
}
.tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 0.25rem;
}
.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.76562rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(72, 94, 144, 0.16);
    border-radius: 0.3rem;
}
.popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;
}
.popover .arrow::after,
.popover .arrow::before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
}
.bs-popover-auto[x-placement^="top"],
.bs-popover-top {
    margin-bottom: 0.5rem;
}
.bs-popover-auto[x-placement^="top"] > .arrow,
.bs-popover-top > .arrow {
    bottom: calc((0.5rem + 1px) * -1);
}
.bs-popover-auto[x-placement^="top"] > .arrow::before,
.bs-popover-top > .arrow::before {
    bottom: 0;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: rgba(72, 94, 144, 0.21);
}
.bs-popover-auto[x-placement^="top"] > .arrow::after,
.bs-popover-top > .arrow::after {
    bottom: 1px;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #fff;
}
.bs-popover-auto[x-placement^="right"],
.bs-popover-right {
    margin-left: 0.5rem;
}
.bs-popover-auto[x-placement^="right"] > .arrow,
.bs-popover-right > .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
}
.bs-popover-auto[x-placement^="right"] > .arrow::before,
.bs-popover-right > .arrow::before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: rgba(72, 94, 144, 0.21);
}
.bs-popover-auto[x-placement^="right"] > .arrow::after,
.bs-popover-right > .arrow::after {
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #fff;
}
.bs-popover-auto[x-placement^="bottom"],
.bs-popover-bottom {
    margin-top: 0.5rem;
}
.bs-popover-auto[x-placement^="bottom"] > .arrow,
.bs-popover-bottom > .arrow {
    top: calc((0.5rem + 1px) * -1);
}
.bs-popover-auto[x-placement^="bottom"] > .arrow::before,
.bs-popover-bottom > .arrow::before {
    top: 0;
    border-width: 0 0.5rem 0.5rem;
    border-bottom-color: rgba(72, 94, 144, 0.21);
}
.bs-popover-auto[x-placement^="bottom"] > .arrow::after,
.bs-popover-bottom > .arrow::after {
    top: 1px;
    border-width: 0 0.5rem 0.5rem;
    border-bottom-color: #fff;
}
.bs-popover-auto[x-placement^="bottom"] .popover-header::before,
.bs-popover-bottom .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7;
}
.bs-popover-auto[x-placement^="left"],
.bs-popover-left {
    margin-right: 0.5rem;
}
.bs-popover-auto[x-placement^="left"] > .arrow,
.bs-popover-left > .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
}
.bs-popover-auto[x-placement^="left"] > .arrow::before,
.bs-popover-left > .arrow::before {
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: rgba(72, 94, 144, 0.21);
}
.bs-popover-auto[x-placement^="left"] > .arrow::after,
.bs-popover-left > .arrow::after {
    right: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #fff;
}
.popover-header {
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: #001737;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
    display: none;
}
.popover-body {
    padding: 0.5rem 0.75rem;
    color: #001737;
}
.carousel {
    position: relative;
}
.carousel.pointer-event {
    touch-action: pan-y;
}
.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
}
.carousel-inner::after {
    display: block;
    clear: both;
    content: "";
}
.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    backface-visibility: hidden;
    transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .carousel-item {
        transition: none;
    }
}
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
    display: block;
}
.active.carousel-item-right,
.carousel-item-next:not(.carousel-item-left) {
    transform: translateX(100%);
}
.active.carousel-item-left,
.carousel-item-prev:not(.carousel-item-right) {
    transform: translateX(-100%);
}
.carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none;
}
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right,
.carousel-fade .carousel-item.active {
    z-index: 1;
    opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    z-index: 0;
    opacity: 0;
    transition: 0s 0.6s opacity;
}
@media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
        transition: none;
    }
}
.carousel-control-next,
.carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: 0.5;
    transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
    .carousel-control-next,
    .carousel-control-prev {
        transition: none;
    }
}
.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
}
.carousel-control-prev {
    left: 0;
}
.carousel-control-next {
    right: 0;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: no-repeat 50%/100% 100%;
}
.carousel-control-prev-icon {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIGZpbGw9JyNmZmYnIHZpZXdCb3g9JzAgMCA4IDgnPjxwYXRoIGQ9J001LjI1IDBsLTQgNCA0IDQgMS41LTEuNS0yLjUtMi41IDIuNS0yLjUtMS41LTEuNXonLz48L3N2Zz4=);
}
.carousel-control-next-icon {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIGZpbGw9JyNmZmYnIHZpZXdCb3g9JzAgMCA4IDgnPjxwYXRoIGQ9J00yLjc1IDBsLTEuNSAxLjUgMi41IDIuNS0yLjUgMi41IDEuNSAxLjUgNC00LTQtNHonLz48L3N2Zz4=);
}
.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
}
.carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
    .carousel-indicators li {
        transition: none;
    }
}
.carousel-indicators .active {
    opacity: 1;
}
.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
}
@keyframes spinner-border {
    to {
        transform: rotate(360deg);
    }
}
.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner-border 0.75s linear infinite;
}
.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
}
@keyframes spinner-grow {
    0% {
        transform: scale(0);
    }
    50% {
        opacity: 1;
    }
}
div.spinner-grow {
    display: inline-block !important;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    color: var(--primary);
    animation: spinner-grow 0.75s linear infinite;
}
.spinner-grow-sm {
    width: 1rem;
    height: 1rem;
}
.align-baseline {
    vertical-align: baseline !important;
}
.align-top {
    vertical-align: top !important;
}
.align-middle {
    vertical-align: middle !important;
}
.align-bottom {
    vertical-align: bottom !important;
}
.align-text-bottom {
    vertical-align: text-bottom !important;
}
.align-text-top {
    vertical-align: text-top !important;
}
.bg-primary {
    background-color: var(--primary) !important;
}
a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
    background-color: #0153c7 !important;
}
.bg-secondary {
    background-color: var(--secondary) !important;
}
.bg-secondary-light {
    background-color: var(--secondary-light) !important;
}
a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
    background-color: #5f6d88 !important;
}
.bg-success {
    background-color: var(--green) !important;
}
a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
    background-color: #0c8842 !important;
}
.bg-info {
    background-color: var(--teal) !important;
}
.bg-info-light {
    background-color: #2eb2c440 !important;
}
a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
    background-color: #008ca1 !important;
}
.bg-warning {
    background-color: var(--orange) !important;
}
.bg-warning-light {
    background-color: #ff980052 !important;
}
a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
    background-color: #d39e00 !important;
}
.bg-danger {
    background-color: var(--red) !important;
}
.bg-danger-light {
    background-color: #f14a5a40 !important;
}
a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
    background-color: #eb3143 !important;
}
.bg-light {
    background-color: #f4f5f8 !important;
}
a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
    background-color: #d5d9e4 !important;
}
.bg-dark {
    background-color: #3b4863 !important;
}
a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
    background-color: #283143 !important;
}
.bg-white {
    background-color: #fff !important;
}
.bg-transparent {
    background-color: transparent !important;
}
.border {
    border: 1px solid rgba(72, 94, 144, 0.16) !important;
}
.border-top {
    border-top: 1px solid rgba(72, 94, 144, 0.16) !important;
}
.border-right {
    border-right: 1px solid rgba(72, 94, 144, 0.16) !important;
}
.border-bottom {
    border-bottom: 1px solid rgba(72, 94, 144, 0.16) !important;
}
.border-left {
    border-left: 1px solid rgba(72, 94, 144, 0.16) !important;
}
.border-0 {
    border: 0 !important;
}
.border-top-0 {
    border-top: 0 !important;
}
.border-right-0 {
    border-right: 0 !important;
}
.border-bottom-0 {
    border-bottom: 0 !important;
}
.border-left-0 {
    border-left: 0 !important;
}
.border-primary {
    border-color: var(--primary) !important;
}
.border-secondary {
    border-color: var(--secondary) !important;
}
.border-success {
    border-color: var(--green) !important;
}
.border-info {
    border-color: var(--teal) !important;
}
.border-warning {
    border-color: var(--orange) !important;
}
.border-danger {
    border-color: var(--red) !important;
}
.border-light {
    border-color: #f4f5f8 !important;
}
.border-dark {
    border-color: #3b4863 !important;
}
.border-white {
    border-color: #fff !important;
}
.square {
    border-radius: 0 !important;
}
.rounded-sm {
    border-radius: 0.2rem !important;
}
.rounded {
    border-radius: 0.25rem !important;
}
.rounded-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
}
.rounded-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
}
.rounded-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
}
.rounded-left {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
}
.rounded-lg {
    border-radius: 0.3rem !important;
}
.rounded-circle {
    border-radius: 50% !important;
}
.rounded-pill {
    border-radius: 50rem !important;
}
.rounded-0 {
    border-radius: 0 !important;
}
.clearfix::after {
    display: block;
    clear: both;
    content: "";
}
.d-none {
    display: none !important;
}
.d-inline {
    display: inline !important;
}
.d-inline-block {
    display: inline-block !important;
}
.d-block {
    display: block !important;
}
.d-grid {
    display: grid !important;
}
.d-table {
    display: table !important;
}
.d-table-row {
    display: table-row !important;
}
.d-table-cell {
    display: table-cell !important;
}
.d-flex {
    display: flex !important;
}
.d-inline-flex {
    display: inline-flex !important;
}
@media (min-width: 576px) {
    .d-sm-none {
        display: none !important;
    }
    .d-sm-inline {
        display: inline !important;
    }
    .d-sm-inline-block {
        display: inline-block !important;
    }
    .d-sm-block {
        display: block !important;
    }
    .d-sm-table {
        display: table !important;
    }
    .d-sm-table-row {
        display: table-row !important;
    }
    .d-sm-table-cell {
        display: table-cell !important;
    }
    .d-sm-flex {
        display: flex !important;
    }
    .d-sm-inline-flex {
        display: inline-flex !important;
    }
}
@media (min-width: 768px) {
    .d-md-none {
        display: none !important;
    }
    .d-md-inline {
        display: inline !important;
    }
    .d-md-inline-block {
        display: inline-block !important;
    }
    .d-md-block {
        display: block !important;
    }
    .d-md-table {
        display: table !important;
    }
    .d-md-table-row {
        display: table-row !important;
    }
    .d-md-table-cell {
        display: table-cell !important;
    }
    .d-md-flex {
        display: flex !important;
    }
    .d-md-inline-flex {
        display: inline-flex !important;
    }
}
@media (min-width: 992px) {
    .d-lg-none {
        display: none !important;
    }
    .d-lg-inline {
        display: inline !important;
    }
    .d-lg-inline-block {
        display: inline-block !important;
    }
    .d-lg-block {
        display: block !important;
    }
    .d-lg-table {
        display: table !important;
    }
    .d-lg-table-row {
        display: table-row !important;
    }
    .d-lg-table-cell {
        display: table-cell !important;
    }
    .d-lg-flex {
        display: flex !important;
    }
    .d-lg-inline-flex {
        display: inline-flex !important;
    }
}
@media (min-width: 1200px) {
    .d-xl-none {
        display: none !important;
    }
    .d-xl-inline {
        display: inline !important;
    }
    .d-xl-inline-block {
        display: inline-block !important;
    }
    .d-xl-block {
        display: block !important;
    }
    .d-xl-table {
        display: table !important;
    }
    .d-xl-table-row {
        display: table-row !important;
    }
    .d-xl-table-cell {
        display: table-cell !important;
    }
    .d-xl-flex {
        display: flex !important;
    }
    .d-xl-inline-flex {
        display: inline-flex !important;
    }
}
@media print {
    .d-print-none {
        display: none !important;
    }
    .d-print-inline {
        display: inline !important;
    }
    .d-print-inline-block {
        display: inline-block !important;
    }
    .d-print-block {
        display: block !important;
    }
    .d-print-table {
        display: table !important;
    }
    .d-print-table-row {
        display: table-row !important;
    }
    .d-print-table-cell {
        display: table-cell !important;
    }
    .d-print-flex {
        display: flex !important;
    }
    .d-print-inline-flex {
        display: inline-flex !important;
    }
}
.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
}
.embed-responsive::before {
    display: block;
    content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}
.embed-responsive-21by9::before {
    padding-top: 42.85714%;
}
.embed-responsive-16by9::before {
    padding-top: 56.25%;
}
.embed-responsive-4by3::before {
    padding-top: 75%;
}
.embed-responsive-1by1::before {
    padding-top: 100%;
}
.flex-row {
    flex-direction: row !important;
}
.flex-column {
    flex-direction: column !important;
}
.flex-row-reverse {
    flex-direction: row-reverse !important;
}
.flex-column-reverse {
    flex-direction: column-reverse !important;
}
.flex-wrap {
    flex-wrap: wrap !important;
}
.flex-nowrap {
    flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
}
.flex-fill {
    flex: 1 1 auto !important;
}
.flex-grow-0 {
    flex-grow: 0 !important;
}
.flex-grow-1 {
    flex-grow: 1 !important;
}
.flex-shrink-0 {
    flex-shrink: 0 !important;
}
.flex-shrink-1 {
    flex-shrink: 1 !important;
}
.justify-content-start {
    justify-content: flex-start !important;
}
.justify-content-end {
    justify-content: flex-end !important;
}
.justify-content-center {
    justify-content: center !important;
}
.justify-content-between {
    justify-content: space-between !important;
}
.justify-content-around {
    justify-content: space-around !important;
}
.align-items-start {
    align-items: flex-start !important;
}
.align-items-end {
    align-items: flex-end !important;
}
.align-items-center {
    align-items: center !important;
}
.align-items-baseline {
    align-items: baseline !important;
}
.align-items-stretch {
    align-items: stretch !important;
}
.align-content-start {
    align-content: flex-start !important;
}
.align-content-end {
    align-content: flex-end !important;
}
.align-content-center {
    align-content: center !important;
}
.align-content-between {
    align-content: space-between !important;
}
.align-content-around {
    align-content: space-around !important;
}
.align-content-stretch {
    align-content: stretch !important;
}
.align-self-auto {
    align-self: auto !important;
}
.align-self-start {
    align-self: flex-start !important;
}
.align-self-end {
    align-self: flex-end !important;
}
.align-self-center {
    align-self: center !important;
}
.align-self-baseline {
    align-self: baseline !important;
}
.align-self-stretch {
    align-self: stretch !important;
}
@media (min-width: 576px) {
    .flex-sm-row {
        flex-direction: row !important;
    }
    .flex-sm-column {
        flex-direction: column !important;
    }
    .flex-sm-row-reverse {
        flex-direction: row-reverse !important;
    }
    .flex-sm-column-reverse {
        flex-direction: column-reverse !important;
    }
    .flex-sm-wrap {
        flex-wrap: wrap !important;
    }
    .flex-sm-nowrap {
        flex-wrap: nowrap !important;
    }
    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .flex-sm-fill {
        flex: 1 1 auto !important;
    }
    .flex-sm-grow-0 {
        flex-grow: 0 !important;
    }
    .flex-sm-grow-1 {
        flex-grow: 1 !important;
    }
    .flex-sm-shrink-0 {
        flex-shrink: 0 !important;
    }
    .flex-sm-shrink-1 {
        flex-shrink: 1 !important;
    }
    .justify-content-sm-start {
        justify-content: flex-start !important;
    }
    .justify-content-sm-end {
        justify-content: flex-end !important;
    }
    .justify-content-sm-center {
        justify-content: center !important;
    }
    .justify-content-sm-between {
        justify-content: space-between !important;
    }
    .justify-content-sm-around {
        justify-content: space-around !important;
    }
    .align-items-sm-start {
        align-items: flex-start !important;
    }
    .align-items-sm-end {
        align-items: flex-end !important;
    }
    .align-items-sm-center {
        align-items: center !important;
    }
    .align-items-sm-baseline {
        align-items: baseline !important;
    }
    .align-items-sm-stretch {
        align-items: stretch !important;
    }
    .align-content-sm-start {
        align-content: flex-start !important;
    }
    .align-content-sm-end {
        align-content: flex-end !important;
    }
    .align-content-sm-center {
        align-content: center !important;
    }
    .align-content-sm-between {
        align-content: space-between !important;
    }
    .align-content-sm-around {
        align-content: space-around !important;
    }
    .align-content-sm-stretch {
        align-content: stretch !important;
    }
    .align-self-sm-auto {
        align-self: auto !important;
    }
    .align-self-sm-start {
        align-self: flex-start !important;
    }
    .align-self-sm-end {
        align-self: flex-end !important;
    }
    .align-self-sm-center {
        align-self: center !important;
    }
    .align-self-sm-baseline {
        align-self: baseline !important;
    }
    .align-self-sm-stretch {
        align-self: stretch !important;
    }
}
@media (min-width: 768px) {
    .flex-md-row {
        flex-direction: row !important;
    }
    .flex-md-column {
        flex-direction: column !important;
    }
    .flex-md-row-reverse {
        flex-direction: row-reverse !important;
    }
    .flex-md-column-reverse {
        flex-direction: column-reverse !important;
    }
    .flex-md-wrap {
        flex-wrap: wrap !important;
    }
    .flex-md-nowrap {
        flex-wrap: nowrap !important;
    }
    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .flex-md-fill {
        flex: 1 1 auto !important;
    }
    .flex-md-grow-0 {
        flex-grow: 0 !important;
    }
    .flex-md-grow-1 {
        flex-grow: 1 !important;
    }
    .flex-md-shrink-0 {
        flex-shrink: 0 !important;
    }
    .flex-md-shrink-1 {
        flex-shrink: 1 !important;
    }
    .justify-content-md-start {
        justify-content: flex-start !important;
    }
    .justify-content-md-end {
        justify-content: flex-end !important;
    }
    .justify-content-md-center {
        justify-content: center !important;
    }
    .justify-content-md-between {
        justify-content: space-between !important;
    }
    .justify-content-md-around {
        justify-content: space-around !important;
    }
    .align-items-md-start {
        align-items: flex-start !important;
    }
    .align-items-md-end {
        align-items: flex-end !important;
    }
    .align-items-md-center {
        align-items: center !important;
    }
    .align-items-md-baseline {
        align-items: baseline !important;
    }
    .align-items-md-stretch {
        align-items: stretch !important;
    }
    .align-content-md-start {
        align-content: flex-start !important;
    }
    .align-content-md-end {
        align-content: flex-end !important;
    }
    .align-content-md-center {
        align-content: center !important;
    }
    .align-content-md-between {
        align-content: space-between !important;
    }
    .align-content-md-around {
        align-content: space-around !important;
    }
    .align-content-md-stretch {
        align-content: stretch !important;
    }
    .align-self-md-auto {
        align-self: auto !important;
    }
    .align-self-md-start {
        align-self: flex-start !important;
    }
    .align-self-md-end {
        align-self: flex-end !important;
    }
    .align-self-md-center {
        align-self: center !important;
    }
    .align-self-md-baseline {
        align-self: baseline !important;
    }
    .align-self-md-stretch {
        align-self: stretch !important;
    }
}
@media (min-width: 992px) {
    .flex-lg-row {
        flex-direction: row !important;
    }
    .flex-lg-column {
        flex-direction: column !important;
    }
    .flex-lg-row-reverse {
        flex-direction: row-reverse !important;
    }
    .flex-lg-column-reverse {
        flex-direction: column-reverse !important;
    }
    .flex-lg-wrap {
        flex-wrap: wrap !important;
    }
    .flex-lg-nowrap {
        flex-wrap: nowrap !important;
    }
    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .flex-lg-fill {
        flex: 1 1 auto !important;
    }
    .flex-lg-grow-0 {
        flex-grow: 0 !important;
    }
    .flex-lg-grow-1 {
        flex-grow: 1 !important;
    }
    .flex-lg-shrink-0 {
        flex-shrink: 0 !important;
    }
    .flex-lg-shrink-1 {
        flex-shrink: 1 !important;
    }
    .justify-content-lg-start {
        justify-content: flex-start !important;
    }
    .justify-content-lg-end {
        justify-content: flex-end !important;
    }
    .justify-content-lg-center {
        justify-content: center !important;
    }
    .justify-content-lg-between {
        justify-content: space-between !important;
    }
    .justify-content-lg-around {
        justify-content: space-around !important;
    }
    .align-items-lg-start {
        align-items: flex-start !important;
    }
    .align-items-lg-end {
        align-items: flex-end !important;
    }
    .align-items-lg-center {
        align-items: center !important;
    }
    .align-items-lg-baseline {
        align-items: baseline !important;
    }
    .align-items-lg-stretch {
        align-items: stretch !important;
    }
    .align-content-lg-start {
        align-content: flex-start !important;
    }
    .align-content-lg-end {
        align-content: flex-end !important;
    }
    .align-content-lg-center {
        align-content: center !important;
    }
    .align-content-lg-between {
        align-content: space-between !important;
    }
    .align-content-lg-around {
        align-content: space-around !important;
    }
    .align-content-lg-stretch {
        align-content: stretch !important;
    }
    .align-self-lg-auto {
        align-self: auto !important;
    }
    .align-self-lg-start {
        align-self: flex-start !important;
    }
    .align-self-lg-end {
        align-self: flex-end !important;
    }
    .align-self-lg-center {
        align-self: center !important;
    }
    .align-self-lg-baseline {
        align-self: baseline !important;
    }
    .align-self-lg-stretch {
        align-self: stretch !important;
    }
}
@media (min-width: 1200px) {
    .flex-xl-row {
        flex-direction: row !important;
    }
    .flex-xl-column {
        flex-direction: column !important;
    }
    .flex-xl-row-reverse {
        flex-direction: row-reverse !important;
    }
    .flex-xl-column-reverse {
        flex-direction: column-reverse !important;
    }
    .flex-xl-wrap {
        flex-wrap: wrap !important;
    }
    .flex-xl-nowrap {
        flex-wrap: nowrap !important;
    }
    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .flex-xl-fill {
        flex: 1 1 auto !important;
    }
    .flex-xl-grow-0 {
        flex-grow: 0 !important;
    }
    .flex-xl-grow-1 {
        flex-grow: 1 !important;
    }
    .flex-xl-shrink-0 {
        flex-shrink: 0 !important;
    }
    .flex-xl-shrink-1 {
        flex-shrink: 1 !important;
    }
    .justify-content-xl-start {
        justify-content: flex-start !important;
    }
    .justify-content-xl-end {
        justify-content: flex-end !important;
    }
    .justify-content-xl-center {
        justify-content: center !important;
    }
    .justify-content-xl-between {
        justify-content: space-between !important;
    }
    .justify-content-xl-around {
        justify-content: space-around !important;
    }
    .align-items-xl-start {
        align-items: flex-start !important;
    }
    .align-items-xl-end {
        align-items: flex-end !important;
    }
    .align-items-xl-center {
        align-items: center !important;
    }
    .align-items-xl-baseline {
        align-items: baseline !important;
    }
    .align-items-xl-stretch {
        align-items: stretch !important;
    }
    .align-content-xl-start {
        align-content: flex-start !important;
    }
    .align-content-xl-end {
        align-content: flex-end !important;
    }
    .align-content-xl-center {
        align-content: center !important;
    }
    .align-content-xl-between {
        align-content: space-between !important;
    }
    .align-content-xl-around {
        align-content: space-around !important;
    }
    .align-content-xl-stretch {
        align-content: stretch !important;
    }
    .align-self-xl-auto {
        align-self: auto !important;
    }
    .align-self-xl-start {
        align-self: flex-start !important;
    }
    .align-self-xl-end {
        align-self: flex-end !important;
    }
    .align-self-xl-center {
        align-self: center !important;
    }
    .align-self-xl-baseline {
        align-self: baseline !important;
    }
    .align-self-xl-stretch {
        align-self: stretch !important;
    }
}
.float-left {
    float: left !important;
}
.float-right {
    float: right !important;
}
.float-none {
    float: none !important;
}
@media (min-width: 576px) {
    .float-sm-left {
        float: left !important;
    }
    .float-sm-right {
        float: right !important;
    }
    .float-sm-none {
        float: none !important;
    }
}
@media (min-width: 768px) {
    .float-md-left {
        float: left !important;
    }
    .float-md-right {
        float: right !important;
    }
    .float-md-none {
        float: none !important;
    }
}
@media (min-width: 992px) {
    .float-lg-left {
        float: left !important;
    }
    .float-lg-right {
        float: right !important;
    }
    .float-lg-none {
        float: none !important;
    }
}
@media (min-width: 1200px) {
    .float-xl-left {
        float: left !important;
    }
    .float-xl-right {
        float: right !important;
    }
    .float-xl-none {
        float: none !important;
    }
}
.overflow-x {
    overflow-x: auto !important;
}
.overflow-y {
    overflow-y: auto !important;
}
.overflow-hidden {
    overflow: hidden !important;
}
.position-static {
    position: static !important;
}
.position-relative {
    position: relative !important;
}
.position-absolute {
    position: absolute !important;
}
.position-fixed {
    position: fixed !important;
}
.position-sticky {
    position: sticky !important;
}
.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}
.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
}
@supports (position: sticky) {
    .sticky-top {
        position: sticky;
        top: 0;
        z-index: 1020;
    }
}
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
}
.shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.shadow-none {
    box-shadow: none !important;
}
.w-25 {
    width: 25% !important;
}
.w-40 {
    width: 40% !important;
}
.w-50 {
    width: 50% !important;
}
.w-60 {
    width: 60% !important;
}
.w-75 {
    width: 75% !important;
}
.w-100 {
    width: 100% !important;
}
.w-auto {
    width: auto !important;
}
.h-25 {
    height: 25% !important;
}
.h-50 {
    height: 50% !important;
}
.h-75 {
    height: 75% !important;
}
.h-100 {
    height: 100% !important;
}
.h-auto {
    height: auto !important;
}
.mw-100 {
    min-width: 100% !important;
}
.mh-100 {
    max-height: 100% !important;
}
.min-vw-100 {
    min-width: 100vw !important;
}
.min-vh-100 {
    min-height: 100vh !important;
}
.vw-100 {
    width: 100vw !important;
}
.vh-100 {
    height: 100vh !important;
}
.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: transparent;
}
.m-0 {
    margin: 0 !important;
}
.mt-0,
.my-0 {
    margin-top: 0 !important;
}
.mr-0,
.mx-0 {
    margin-right: 0 !important;
}
.mb-0,
.my-0 {
    margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
    margin-left: 0 !important;
}
.m-1 {
    margin: 0.25rem !important;
}
.m-1-5 {
    margin: 0.375rem !important;
}
.mt-1,
.my-1 {
    margin-top: 0.25rem !important;
}
.mr-1,
.mx-1 {
    margin-right: 0.25rem !important;
}
.mb-1,
.my-1 {
    margin-bottom: 0.25rem !important;
}
.ml-1,
.mx-1 {
    margin-left: 0.25rem !important;
}
.m-2 {
    margin: 0.5rem !important;
}
.mt-2,
.my-2 {
    margin-top: 0.5rem !important;
}
.mr-2,
.mx-2 {
    margin-right: 0.5rem !important;
}
.mb-2,
.my-2 {
    margin-bottom: 0.5rem !important;
}
.ml-2,
.mx-2 {
    margin-left: 0.5rem !important;
}
.m-3 {
    margin: 1rem !important;
}
.mt-3,
.my-3 {
    margin-top: 1rem !important;
}
.mr-3,
.mx-3 {
    margin-right: 1rem !important;
}
.mb-3,
.my-3 {
    margin-bottom: 1rem !important;
}
.ml-3,
.mx-3 {
    margin-left: 1rem !important;
}
.m-4 {
    margin: 1.5rem !important;
}
.mt-4,
.my-4 {
    margin-top: 1.5rem !important;
}
.mr-4,
.mx-4 {
    margin-right: 1.5rem !important;
}
.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important;
}
.ml-4,
.mx-4 {
    margin-left: 1.5rem !important;
}
.m-5 {
    margin: 3rem !important;
}
.mt-5,
.my-5 {
    margin-top: 3rem !important;
}
.mr-5,
.mx-5 {
    margin-right: 3rem !important;
}
.mb-5,
.my-5 {
    margin-bottom: 3rem !important;
}
.ml-5,
.mx-5 {
    margin-left: 3rem !important;
}
.p-0 {
    padding: 0 !important;
}
.p-0-5 {
    padding: 0.125rem !important;
}
.pt-0,
.py-0 {
    padding-top: 0 !important;
}
.pr-0,
.px-0 {
    padding-right: 0 !important;
}
.pb-0,
.py-0 {
    padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
    padding-left: 0 !important;
}
.p-1 {
    padding: 0.25rem !important;
}
.p-6p {
    padding: 6px !important;
}
.pt-1,
.py-1 {
    padding-top: 0.25rem !important;
}
.pr-1,
.px-1 {
    padding-right: 0.25rem !important;
}
.pb-1,
.py-1 {
    padding-bottom: 0.25rem !important;
}
.pl-1,
.px-1 {
    padding-left: 0.25rem !important;
}
.p-2 {
    padding: 0.5rem !important;
}
.pt-2,
.py-2 {
    padding-top: 0.5rem !important;
}
.pr-2,
.px-2 {
    padding-right: 0.5rem !important;
}
.pb-2,
.py-2 {
    padding-bottom: 0.5rem !important;
}
.pl-2,
.px-2 {
    padding-left: 0.5rem !important;
}
.p-3 {
    padding: 1rem !important;
}
.pt-3,
.py-3 {
    padding-top: 1rem !important;
}
.pr-3,
.px-3 {
    padding-right: 1rem !important;
}
.pb-3,
.py-3 {
    padding-bottom: 1rem !important;
}
.pl-3,
.px-3 {
    padding-left: 1rem !important;
}
.p-4 {
    padding: 1.5rem !important;
}
.pt-4,
.py-4 {
    padding-top: 1.5rem !important;
}
.pr-4,
.px-4 {
    padding-right: 1.5rem !important;
}
.pb-4,
.py-4 {
    padding-bottom: 1.5rem !important;
}
.pl-4,
.px-4 {
    padding-left: 1.5rem !important;
}
.p-5 {
    padding: 3rem !important;
}
.pt-5,
.py-5 {
    padding-top: 3rem !important;
}
.pr-5,
.px-5 {
    padding-right: 3rem !important;
}
.pb-5,
.py-5 {
    padding-bottom: 3rem !important;
}
.pl-5,
.px-5 {
    padding-left: 3rem !important;
}
.m-n1 {
    margin: -0.25rem !important;
}
.mt-n1,
.my-n1 {
    margin-top: -0.25rem !important;
}
.mr-n1,
.mx-n1 {
    margin-right: -0.25rem !important;
}
.mb-n1,
.my-n1 {
    margin-bottom: -0.25rem !important;
}
.ml-n1,
.mx-n1 {
    margin-left: -0.25rem !important;
}
.m-n2 {
    margin: -0.5rem !important;
}
.mt-n2,
.my-n2 {
    margin-top: -0.5rem !important;
}
.mr-n2,
.mx-n2 {
    margin-right: -0.5rem !important;
}
.mb-n2,
.my-n2 {
    margin-bottom: -0.5rem !important;
}
.ml-n2,
.mx-n2 {
    margin-left: -0.5rem !important;
}
.m-n3 {
    margin: -1rem !important;
}
.mt-n3,
.my-n3 {
    margin-top: -1rem !important;
}
.mr-n3,
.mx-n3 {
    margin-right: -1rem !important;
}
.mb-n3,
.my-n3 {
    margin-bottom: -1rem !important;
}
.ml-n3,
.mx-n3 {
    margin-left: -1rem !important;
}
.m-n4 {
    margin: -1.5rem !important;
}
.mt-n4,
.my-n4 {
    margin-top: -1.5rem !important;
}
.mr-n4,
.mx-n4 {
    margin-right: -1.5rem !important;
}
.mb-n4,
.my-n4 {
    margin-bottom: -1.5rem !important;
}
.ml-n4,
.mx-n4 {
    margin-left: -1.5rem !important;
}
.m-n5 {
    margin: -3rem !important;
}
.mt-n5,
.my-n5 {
    margin-top: -3rem !important;
}
.mr-n5,
.mx-n5 {
    margin-right: -3rem !important;
}
.mb-n5,
.my-n5 {
    margin-bottom: -3rem !important;
}
.ml-n5,
.mx-n5 {
    margin-left: -3rem !important;
}
.m-auto {
    margin: auto !important;
}
.mt-auto,
.my-auto {
    margin-top: auto !important;
}
.mr-auto,
.mx-auto {
    margin-right: auto !important;
}
.mb-auto,
.my-auto {
    margin-bottom: auto !important;
}
.ml-auto,
.mx-auto {
    margin-left: auto !important;
}
@media (min-width: 576px) {
    .m-sm-0 {
        margin: 0 !important;
    }
    .mt-sm-0,
    .my-sm-0 {
        margin-top: 0 !important;
    }
    .mr-sm-0,
    .mx-sm-0 {
        margin-right: 0 !important;
    }
    .mb-sm-0,
    .my-sm-0 {
        margin-bottom: 0 !important;
    }
    .ml-sm-0,
    .mx-sm-0 {
        margin-left: 0 !important;
    }
    .m-sm-1 {
        margin: 0.25rem !important;
    }
    .mt-sm-1,
    .my-sm-1 {
        margin-top: 0.25rem !important;
    }
    .mr-sm-1,
    .mx-sm-1 {
        margin-right: 0.25rem !important;
    }
    .mb-sm-1,
    .my-sm-1 {
        margin-bottom: 0.25rem !important;
    }
    .ml-sm-1,
    .mx-sm-1 {
        margin-left: 0.25rem !important;
    }
    .m-sm-2 {
        margin: 0.5rem !important;
    }
    .mt-sm-2,
    .my-sm-2 {
        margin-top: 0.5rem !important;
    }
    .mr-sm-2,
    .mx-sm-2 {
        margin-right: 0.5rem !important;
    }
    .mb-sm-2,
    .my-sm-2 {
        margin-bottom: 0.5rem !important;
    }
    .ml-sm-2,
    .mx-sm-2 {
        margin-left: 0.5rem !important;
    }
    .m-sm-3 {
        margin: 1rem !important;
    }
    .mt-sm-3,
    .my-sm-3 {
        margin-top: 1rem !important;
    }
    .mr-sm-3,
    .mx-sm-3 {
        margin-right: 1rem !important;
    }
    .mb-sm-3,
    .my-sm-3 {
        margin-bottom: 1rem !important;
    }
    .ml-sm-3,
    .mx-sm-3 {
        margin-left: 1rem !important;
    }
    .m-sm-4 {
        margin: 1.5rem !important;
    }
    .mt-sm-4,
    .my-sm-4 {
        margin-top: 1.5rem !important;
    }
    .mr-sm-4,
    .mx-sm-4 {
        margin-right: 1.5rem !important;
    }
    .mb-sm-4,
    .my-sm-4 {
        margin-bottom: 1.5rem !important;
    }
    .ml-sm-4,
    .mx-sm-4 {
        margin-left: 1.5rem !important;
    }
    .m-sm-5 {
        margin: 3rem !important;
    }
    .mt-sm-5,
    .my-sm-5 {
        margin-top: 3rem !important;
    }
    .mr-sm-5,
    .mx-sm-5 {
        margin-right: 3rem !important;
    }
    .mb-sm-5,
    .my-sm-5 {
        margin-bottom: 3rem !important;
    }
    .ml-sm-5,
    .mx-sm-5 {
        margin-left: 3rem !important;
    }
    .p-sm-0 {
        padding: 0 !important;
    }
    .pt-sm-0,
    .py-sm-0 {
        padding-top: 0 !important;
    }
    .pr-sm-0,
    .px-sm-0 {
        padding-right: 0 !important;
    }
    .pb-sm-0,
    .py-sm-0 {
        padding-bottom: 0 !important;
    }
    .pl-sm-0,
    .px-sm-0 {
        padding-left: 0 !important;
    }
    .p-sm-1 {
        padding: 0.25rem !important;
    }
    .pt-sm-1,
    .py-sm-1 {
        padding-top: 0.25rem !important;
    }
    .pr-sm-1,
    .px-sm-1 {
        padding-right: 0.25rem !important;
    }
    .pb-sm-1,
    .py-sm-1 {
        padding-bottom: 0.25rem !important;
    }
    .pl-sm-1,
    .px-sm-1 {
        padding-left: 0.25rem !important;
    }
    .p-sm-2 {
        padding: 0.5rem !important;
    }
    .pt-sm-2,
    .py-sm-2 {
        padding-top: 0.5rem !important;
    }
    .pr-sm-2,
    .px-sm-2 {
        padding-right: 0.5rem !important;
    }
    .pb-sm-2,
    .py-sm-2 {
        padding-bottom: 0.5rem !important;
    }
    .pl-sm-2,
    .px-sm-2 {
        padding-left: 0.5rem !important;
    }
    .p-sm-3 {
        padding: 1rem !important;
    }
    .pt-sm-3,
    .py-sm-3 {
        padding-top: 1rem !important;
    }
    .pr-sm-3,
    .px-sm-3 {
        padding-right: 1rem !important;
    }
    .pb-sm-3,
    .py-sm-3 {
        padding-bottom: 1rem !important;
    }
    .pl-sm-3,
    .px-sm-3 {
        padding-left: 1rem !important;
    }
    .p-sm-4 {
        padding: 1.5rem !important;
    }
    .pt-sm-4,
    .py-sm-4 {
        padding-top: 1.5rem !important;
    }
    .pr-sm-4,
    .px-sm-4 {
        padding-right: 1.5rem !important;
    }
    .pb-sm-4,
    .py-sm-4 {
        padding-bottom: 1.5rem !important;
    }
    .pl-sm-4,
    .px-sm-4 {
        padding-left: 1.5rem !important;
    }
    .p-sm-5 {
        padding: 3rem !important;
    }
    .pt-sm-5,
    .py-sm-5 {
        padding-top: 3rem !important;
    }
    .pr-sm-5,
    .px-sm-5 {
        padding-right: 3rem !important;
    }
    .pb-sm-5,
    .py-sm-5 {
        padding-bottom: 3rem !important;
    }
    .pl-sm-5,
    .px-sm-5 {
        padding-left: 3rem !important;
    }
    .m-sm-n1 {
        margin: -0.25rem !important;
    }
    .mt-sm-n1,
    .my-sm-n1 {
        margin-top: -0.25rem !important;
    }
    .mr-sm-n1,
    .mx-sm-n1 {
        margin-right: -0.25rem !important;
    }
    .mb-sm-n1,
    .my-sm-n1 {
        margin-bottom: -0.25rem !important;
    }
    .ml-sm-n1,
    .mx-sm-n1 {
        margin-left: -0.25rem !important;
    }
    .m-sm-n2 {
        margin: -0.5rem !important;
    }
    .mt-sm-n2,
    .my-sm-n2 {
        margin-top: -0.5rem !important;
    }
    .mr-sm-n2,
    .mx-sm-n2 {
        margin-right: -0.5rem !important;
    }
    .mb-sm-n2,
    .my-sm-n2 {
        margin-bottom: -0.5rem !important;
    }
    .ml-sm-n2,
    .mx-sm-n2 {
        margin-left: -0.5rem !important;
    }
    .m-sm-n3 {
        margin: -1rem !important;
    }
    .mt-sm-n3,
    .my-sm-n3 {
        margin-top: -1rem !important;
    }
    .mr-sm-n3,
    .mx-sm-n3 {
        margin-right: -1rem !important;
    }
    .mb-sm-n3,
    .my-sm-n3 {
        margin-bottom: -1rem !important;
    }
    .ml-sm-n3,
    .mx-sm-n3 {
        margin-left: -1rem !important;
    }
    .m-sm-n4 {
        margin: -1.5rem !important;
    }
    .mt-sm-n4,
    .my-sm-n4 {
        margin-top: -1.5rem !important;
    }
    .mr-sm-n4,
    .mx-sm-n4 {
        margin-right: -1.5rem !important;
    }
    .mb-sm-n4,
    .my-sm-n4 {
        margin-bottom: -1.5rem !important;
    }
    .ml-sm-n4,
    .mx-sm-n4 {
        margin-left: -1.5rem !important;
    }
    .m-sm-n5 {
        margin: -3rem !important;
    }
    .mt-sm-n5,
    .my-sm-n5 {
        margin-top: -3rem !important;
    }
    .mr-sm-n5,
    .mx-sm-n5 {
        margin-right: -3rem !important;
    }
    .mb-sm-n5,
    .my-sm-n5 {
        margin-bottom: -3rem !important;
    }
    .ml-sm-n5,
    .mx-sm-n5 {
        margin-left: -3rem !important;
    }
    .m-sm-auto {
        margin: auto !important;
    }
    .mt-sm-auto,
    .my-sm-auto {
        margin-top: auto !important;
    }
    .mr-sm-auto,
    .mx-sm-auto {
        margin-right: auto !important;
    }
    .mb-sm-auto,
    .my-sm-auto {
        margin-bottom: auto !important;
    }
    .ml-sm-auto,
    .mx-sm-auto {
        margin-left: auto !important;
    }
}
@media (min-width: 768px) {
    .m-md-0 {
        margin: 0 !important;
    }
    .mt-md-0,
    .my-md-0 {
        margin-top: 0 !important;
    }
    .mr-md-0,
    .mx-md-0 {
        margin-right: 0 !important;
    }
    .mb-md-0,
    .my-md-0 {
        margin-bottom: 0 !important;
    }
    .ml-md-0,
    .mx-md-0 {
        margin-left: 0 !important;
    }
    .m-md-1 {
        margin: 0.25rem !important;
    }
    .mt-md-1,
    .my-md-1 {
        margin-top: 0.25rem !important;
    }
    .mr-md-1,
    .mx-md-1 {
        margin-right: 0.25rem !important;
    }
    .mb-md-1,
    .my-md-1 {
        margin-bottom: 0.25rem !important;
    }
    .ml-md-1,
    .mx-md-1 {
        margin-left: 0.25rem !important;
    }
    .m-md-2 {
        margin: 0.5rem !important;
    }
    .mt-md-2,
    .my-md-2 {
        margin-top: 0.5rem !important;
    }
    .mr-md-2,
    .mx-md-2 {
        margin-right: 0.5rem !important;
    }
    .mb-md-2,
    .my-md-2 {
        margin-bottom: 0.5rem !important;
    }
    .ml-md-2,
    .mx-md-2 {
        margin-left: 0.5rem !important;
    }
    .m-md-3 {
        margin: 1rem !important;
    }
    .mt-md-3,
    .my-md-3 {
        margin-top: 1rem !important;
    }
    .mr-md-3,
    .mx-md-3 {
        margin-right: 1rem !important;
    }
    .mb-md-3,
    .my-md-3 {
        margin-bottom: 1rem !important;
    }
    .ml-md-3,
    .mx-md-3 {
        margin-left: 1rem !important;
    }
    .m-md-4 {
        margin: 1.5rem !important;
    }
    .mt-md-4,
    .my-md-4 {
        margin-top: 1.5rem !important;
    }
    .mr-md-4,
    .mx-md-4 {
        margin-right: 1.5rem !important;
    }
    .mb-md-4,
    .my-md-4 {
        margin-bottom: 1.5rem !important;
    }
    .ml-md-4,
    .mx-md-4 {
        margin-left: 1.5rem !important;
    }
    .m-md-5 {
        margin: 3rem !important;
    }
    .mt-md-5,
    .my-md-5 {
        margin-top: 3rem !important;
    }
    .mr-md-5,
    .mx-md-5 {
        margin-right: 3rem !important;
    }
    .mb-md-5,
    .my-md-5 {
        margin-bottom: 3rem !important;
    }
    .ml-md-5,
    .mx-md-5 {
        margin-left: 3rem !important;
    }
    .p-md-0 {
        padding: 0 !important;
    }
    .pt-md-0,
    .py-md-0 {
        padding-top: 0 !important;
    }
    .pr-md-0,
    .px-md-0 {
        padding-right: 0 !important;
    }
    .pb-md-0,
    .py-md-0 {
        padding-bottom: 0 !important;
    }
    .pl-md-0,
    .px-md-0 {
        padding-left: 0 !important;
    }
    .p-md-1 {
        padding: 0.25rem !important;
    }
    .pt-md-1,
    .py-md-1 {
        padding-top: 0.25rem !important;
    }
    .pr-md-1,
    .px-md-1 {
        padding-right: 0.25rem !important;
    }
    .pb-md-1,
    .py-md-1 {
        padding-bottom: 0.25rem !important;
    }
    .pl-md-1,
    .px-md-1 {
        padding-left: 0.25rem !important;
    }
    .p-md-2 {
        padding: 0.5rem !important;
    }
    .pt-md-2,
    .py-md-2 {
        padding-top: 0.5rem !important;
    }
    .pr-md-2,
    .px-md-2 {
        padding-right: 0.5rem !important;
    }
    .pb-md-2,
    .py-md-2 {
        padding-bottom: 0.5rem !important;
    }
    .pl-md-2,
    .px-md-2 {
        padding-left: 0.5rem !important;
    }
    .p-md-3 {
        padding: 1rem !important;
    }
    .pt-md-3,
    .py-md-3 {
        padding-top: 1rem !important;
    }
    .pr-md-3,
    .px-md-3 {
        padding-right: 1rem !important;
    }
    .pb-md-3,
    .py-md-3 {
        padding-bottom: 1rem !important;
    }
    .pl-md-3,
    .px-md-3 {
        padding-left: 1rem !important;
    }
    .p-md-4 {
        padding: 1.5rem !important;
    }
    .pt-md-4,
    .py-md-4 {
        padding-top: 1.5rem !important;
    }
    .pr-md-4,
    .px-md-4 {
        padding-right: 1.5rem !important;
    }
    .pb-md-4,
    .py-md-4 {
        padding-bottom: 1.5rem !important;
    }
    .pl-md-4,
    .px-md-4 {
        padding-left: 1.5rem !important;
    }
    .p-md-5 {
        padding: 3rem !important;
    }
    .pt-md-5,
    .py-md-5 {
        padding-top: 3rem !important;
    }
    .pr-md-5,
    .px-md-5 {
        padding-right: 3rem !important;
    }
    .pb-md-5,
    .py-md-5 {
        padding-bottom: 3rem !important;
    }
    .pl-md-5,
    .px-md-5 {
        padding-left: 3rem !important;
    }
    .m-md-n1 {
        margin: -0.25rem !important;
    }
    .mt-md-n1,
    .my-md-n1 {
        margin-top: -0.25rem !important;
    }
    .mr-md-n1,
    .mx-md-n1 {
        margin-right: -0.25rem !important;
    }
    .mb-md-n1,
    .my-md-n1 {
        margin-bottom: -0.25rem !important;
    }
    .ml-md-n1,
    .mx-md-n1 {
        margin-left: -0.25rem !important;
    }
    .m-md-n2 {
        margin: -0.5rem !important;
    }
    .mt-md-n2,
    .my-md-n2 {
        margin-top: -0.5rem !important;
    }
    .mr-md-n2,
    .mx-md-n2 {
        margin-right: -0.5rem !important;
    }
    .mb-md-n2,
    .my-md-n2 {
        margin-bottom: -0.5rem !important;
    }
    .ml-md-n2,
    .mx-md-n2 {
        margin-left: -0.5rem !important;
    }
    .m-md-n3 {
        margin: -1rem !important;
    }
    .mt-md-n3,
    .my-md-n3 {
        margin-top: -1rem !important;
    }
    .mr-md-n3,
    .mx-md-n3 {
        margin-right: -1rem !important;
    }
    .mb-md-n3,
    .my-md-n3 {
        margin-bottom: -1rem !important;
    }
    .ml-md-n3,
    .mx-md-n3 {
        margin-left: -1rem !important;
    }
    .m-md-n4 {
        margin: -1.5rem !important;
    }
    .mt-md-n4,
    .my-md-n4 {
        margin-top: -1.5rem !important;
    }
    .mr-md-n4,
    .mx-md-n4 {
        margin-right: -1.5rem !important;
    }
    .mb-md-n4,
    .my-md-n4 {
        margin-bottom: -1.5rem !important;
    }
    .ml-md-n4,
    .mx-md-n4 {
        margin-left: -1.5rem !important;
    }
    .m-md-n5 {
        margin: -3rem !important;
    }
    .mt-md-n5,
    .my-md-n5 {
        margin-top: -3rem !important;
    }
    .mr-md-n5,
    .mx-md-n5 {
        margin-right: -3rem !important;
    }
    .mb-md-n5,
    .my-md-n5 {
        margin-bottom: -3rem !important;
    }
    .ml-md-n5,
    .mx-md-n5 {
        margin-left: -3rem !important;
    }
    .m-md-auto {
        margin: auto !important;
    }
    .mt-md-auto,
    .my-md-auto {
        margin-top: auto !important;
    }
    .mr-md-auto,
    .mx-md-auto {
        margin-right: auto !important;
    }
    .mb-md-auto,
    .my-md-auto {
        margin-bottom: auto !important;
    }
    .ml-md-auto,
    .mx-md-auto {
        margin-left: auto !important;
    }
}
@media (min-width: 992px) {
    .m-lg-0 {
        margin: 0 !important;
    }
    .mt-lg-0,
    .my-lg-0 {
        margin-top: 0 !important;
    }
    .mr-lg-0,
    .mx-lg-0 {
        margin-right: 0 !important;
    }
    .mb-lg-0,
    .my-lg-0 {
        margin-bottom: 0 !important;
    }
    .ml-lg-0,
    .mx-lg-0 {
        margin-left: 0 !important;
    }
    .m-lg-1 {
        margin: 0.25rem !important;
    }
    .mt-lg-1,
    .my-lg-1 {
        margin-top: 0.25rem !important;
    }
    .mr-lg-1,
    .mx-lg-1 {
        margin-right: 0.25rem !important;
    }
    .mb-lg-1,
    .my-lg-1 {
        margin-bottom: 0.25rem !important;
    }
    .ml-lg-1,
    .mx-lg-1 {
        margin-left: 0.25rem !important;
    }
    .m-lg-2 {
        margin: 0.5rem !important;
    }
    .mt-lg-2,
    .my-lg-2 {
        margin-top: 0.5rem !important;
    }
    .mr-lg-2,
    .mx-lg-2 {
        margin-right: 0.5rem !important;
    }
    .mb-lg-2,
    .my-lg-2 {
        margin-bottom: 0.5rem !important;
    }
    .ml-lg-2,
    .mx-lg-2 {
        margin-left: 0.5rem !important;
    }
    .m-lg-3 {
        margin: 1rem !important;
    }
    .mt-lg-3,
    .my-lg-3 {
        margin-top: 1rem !important;
    }
    .mr-lg-3,
    .mx-lg-3 {
        margin-right: 1rem !important;
    }
    .mb-lg-3,
    .my-lg-3 {
        margin-bottom: 1rem !important;
    }
    .ml-lg-3,
    .mx-lg-3 {
        margin-left: 1rem !important;
    }
    .m-lg-4 {
        margin: 1.5rem !important;
    }
    .mt-lg-4,
    .my-lg-4 {
        margin-top: 1.5rem !important;
    }
    .mr-lg-4,
    .mx-lg-4 {
        margin-right: 1.5rem !important;
    }
    .mb-lg-4,
    .my-lg-4 {
        margin-bottom: 1.5rem !important;
    }
    .ml-lg-4,
    .mx-lg-4 {
        margin-left: 1.5rem !important;
    }
    .m-lg-5 {
        margin: 3rem !important;
    }
    .mt-lg-5,
    .my-lg-5 {
        margin-top: 3rem !important;
    }
    .mr-lg-5,
    .mx-lg-5 {
        margin-right: 3rem !important;
    }
    .mb-lg-5,
    .my-lg-5 {
        margin-bottom: 3rem !important;
    }
    .ml-lg-5,
    .mx-lg-5 {
        margin-left: 3rem !important;
    }
    .p-lg-0 {
        padding: 0 !important;
    }
    .pt-lg-0,
    .py-lg-0 {
        padding-top: 0 !important;
    }
    .pr-lg-0,
    .px-lg-0 {
        padding-right: 0 !important;
    }
    .pb-lg-0,
    .py-lg-0 {
        padding-bottom: 0 !important;
    }
    .pl-lg-0,
    .px-lg-0 {
        padding-left: 0 !important;
    }
    .p-lg-1 {
        padding: 0.25rem !important;
    }
    .pt-lg-1,
    .py-lg-1 {
        padding-top: 0.25rem !important;
    }
    .pr-lg-1,
    .px-lg-1 {
        padding-right: 0.25rem !important;
    }
    .pb-lg-1,
    .py-lg-1 {
        padding-bottom: 0.25rem !important;
    }
    .pl-lg-1,
    .px-lg-1 {
        padding-left: 0.25rem !important;
    }
    .p-lg-2 {
        padding: 0.5rem !important;
    }
    .pt-lg-2,
    .py-lg-2 {
        padding-top: 0.5rem !important;
    }
    .pr-lg-2,
    .px-lg-2 {
        padding-right: 0.5rem !important;
    }
    .pb-lg-2,
    .py-lg-2 {
        padding-bottom: 0.5rem !important;
    }
    .pl-lg-2,
    .px-lg-2 {
        padding-left: 0.5rem !important;
    }
    .p-lg-3 {
        padding: 1rem !important;
    }
    .pt-lg-3,
    .py-lg-3 {
        padding-top: 1rem !important;
    }
    .pr-lg-3,
    .px-lg-3 {
        padding-right: 1rem !important;
    }
    .pb-lg-3,
    .py-lg-3 {
        padding-bottom: 1rem !important;
    }
    .pl-lg-3,
    .px-lg-3 {
        padding-left: 1rem !important;
    }
    .p-lg-4 {
        padding: 1.5rem !important;
    }
    .pt-lg-4,
    .py-lg-4 {
        padding-top: 1.5rem !important;
    }
    .pr-lg-4,
    .px-lg-4 {
        padding-right: 1.5rem !important;
    }
    .pb-lg-4,
    .py-lg-4 {
        padding-bottom: 1.5rem !important;
    }
    .pl-lg-4,
    .px-lg-4 {
        padding-left: 1.5rem !important;
    }
    .p-lg-5 {
        padding: 3rem !important;
    }
    .pt-lg-5,
    .py-lg-5 {
        padding-top: 3rem !important;
    }
    .pr-lg-5,
    .px-lg-5 {
        padding-right: 3rem !important;
    }
    .pb-lg-5,
    .py-lg-5 {
        padding-bottom: 3rem !important;
    }
    .pl-lg-5,
    .px-lg-5 {
        padding-left: 3rem !important;
    }
    .m-lg-n1 {
        margin: -0.25rem !important;
    }
    .mt-lg-n1,
    .my-lg-n1 {
        margin-top: -0.25rem !important;
    }
    .mr-lg-n1,
    .mx-lg-n1 {
        margin-right: -0.25rem !important;
    }
    .mb-lg-n1,
    .my-lg-n1 {
        margin-bottom: -0.25rem !important;
    }
    .ml-lg-n1,
    .mx-lg-n1 {
        margin-left: -0.25rem !important;
    }
    .m-lg-n2 {
        margin: -0.5rem !important;
    }
    .mt-lg-n2,
    .my-lg-n2 {
        margin-top: -0.5rem !important;
    }
    .mr-lg-n2,
    .mx-lg-n2 {
        margin-right: -0.5rem !important;
    }
    .mb-lg-n2,
    .my-lg-n2 {
        margin-bottom: -0.5rem !important;
    }
    .ml-lg-n2,
    .mx-lg-n2 {
        margin-left: -0.5rem !important;
    }
    .m-lg-n3 {
        margin: -1rem !important;
    }
    .mt-lg-n3,
    .my-lg-n3 {
        margin-top: -1rem !important;
    }
    .mr-lg-n3,
    .mx-lg-n3 {
        margin-right: -1rem !important;
    }
    .mb-lg-n3,
    .my-lg-n3 {
        margin-bottom: -1rem !important;
    }
    .ml-lg-n3,
    .mx-lg-n3 {
        margin-left: -1rem !important;
    }
    .m-lg-n4 {
        margin: -1.5rem !important;
    }
    .mt-lg-n4,
    .my-lg-n4 {
        margin-top: -1.5rem !important;
    }
    .mr-lg-n4,
    .mx-lg-n4 {
        margin-right: -1.5rem !important;
    }
    .mb-lg-n4,
    .my-lg-n4 {
        margin-bottom: -1.5rem !important;
    }
    .ml-lg-n4,
    .mx-lg-n4 {
        margin-left: -1.5rem !important;
    }
    .m-lg-n5 {
        margin: -3rem !important;
    }
    .mt-lg-n5,
    .my-lg-n5 {
        margin-top: -3rem !important;
    }
    .mr-lg-n5,
    .mx-lg-n5 {
        margin-right: -3rem !important;
    }
    .mb-lg-n5,
    .my-lg-n5 {
        margin-bottom: -3rem !important;
    }
    .ml-lg-n5,
    .mx-lg-n5 {
        margin-left: -3rem !important;
    }
    .m-lg-auto {
        margin: auto !important;
    }
    .mt-lg-auto,
    .my-lg-auto {
        margin-top: auto !important;
    }
    .mr-lg-auto,
    .mx-lg-auto {
        margin-right: auto !important;
    }
    .mb-lg-auto,
    .my-lg-auto {
        margin-bottom: auto !important;
    }
    .ml-lg-auto,
    .mx-lg-auto {
        margin-left: auto !important;
    }
}
@media (min-width: 1200px) {
    .m-xl-0 {
        margin: 0 !important;
    }
    .mt-xl-0,
    .my-xl-0 {
        margin-top: 0 !important;
    }
    .mr-xl-0,
    .mx-xl-0 {
        margin-right: 0 !important;
    }
    .mb-xl-0,
    .my-xl-0 {
        margin-bottom: 0 !important;
    }
    .ml-xl-0,
    .mx-xl-0 {
        margin-left: 0 !important;
    }
    .m-xl-1 {
        margin: 0.25rem !important;
    }
    .mt-xl-1,
    .my-xl-1 {
        margin-top: 0.25rem !important;
    }
    .mr-xl-1,
    .mx-xl-1 {
        margin-right: 0.25rem !important;
    }
    .mb-xl-1,
    .my-xl-1 {
        margin-bottom: 0.25rem !important;
    }
    .ml-xl-1,
    .mx-xl-1 {
        margin-left: 0.25rem !important;
    }
    .m-xl-2 {
        margin: 0.5rem !important;
    }
    .mt-xl-2,
    .my-xl-2 {
        margin-top: 0.5rem !important;
    }
    .mr-xl-2,
    .mx-xl-2 {
        margin-right: 0.5rem !important;
    }
    .mb-xl-2,
    .my-xl-2 {
        margin-bottom: 0.5rem !important;
    }
    .ml-xl-2,
    .mx-xl-2 {
        margin-left: 0.5rem !important;
    }
    .m-xl-3 {
        margin: 1rem !important;
    }
    .mt-xl-3,
    .my-xl-3 {
        margin-top: 1rem !important;
    }
    .mr-xl-3,
    .mx-xl-3 {
        margin-right: 1rem !important;
    }
    .mb-xl-3,
    .my-xl-3 {
        margin-bottom: 1rem !important;
    }
    .ml-xl-3,
    .mx-xl-3 {
        margin-left: 1rem !important;
    }
    .m-xl-4 {
        margin: 1.5rem !important;
    }
    .mt-xl-4,
    .my-xl-4 {
        margin-top: 1.5rem !important;
    }
    .mr-xl-4,
    .mx-xl-4 {
        margin-right: 1.5rem !important;
    }
    .mb-xl-4,
    .my-xl-4 {
        margin-bottom: 1.5rem !important;
    }
    .ml-xl-4,
    .mx-xl-4 {
        margin-left: 1.5rem !important;
    }
    .m-xl-5 {
        margin: 3rem !important;
    }
    .mt-xl-5,
    .my-xl-5 {
        margin-top: 3rem !important;
    }
    .mr-xl-5,
    .mx-xl-5 {
        margin-right: 3rem !important;
    }
    .mb-xl-5,
    .my-xl-5 {
        margin-bottom: 3rem !important;
    }
    .ml-xl-5,
    .mx-xl-5 {
        margin-left: 3rem !important;
    }
    .p-xl-0 {
        padding: 0 !important;
    }
    .pt-xl-0,
    .py-xl-0 {
        padding-top: 0 !important;
    }
    .pr-xl-0,
    .px-xl-0 {
        padding-right: 0 !important;
    }
    .pb-xl-0,
    .py-xl-0 {
        padding-bottom: 0 !important;
    }
    .pl-xl-0,
    .px-xl-0 {
        padding-left: 0 !important;
    }
    .p-xl-1 {
        padding: 0.25rem !important;
    }
    .pt-xl-1,
    .py-xl-1 {
        padding-top: 0.25rem !important;
    }
    .pr-xl-1,
    .px-xl-1 {
        padding-right: 0.25rem !important;
    }
    .pb-xl-1,
    .py-xl-1 {
        padding-bottom: 0.25rem !important;
    }
    .pl-xl-1,
    .px-xl-1 {
        padding-left: 0.25rem !important;
    }
    .p-xl-2 {
        padding: 0.5rem !important;
    }
    .pt-xl-2,
    .py-xl-2 {
        padding-top: 0.5rem !important;
    }
    .pr-xl-2,
    .px-xl-2 {
        padding-right: 0.5rem !important;
    }
    .pb-xl-2,
    .py-xl-2 {
        padding-bottom: 0.5rem !important;
    }
    .pl-xl-2,
    .px-xl-2 {
        padding-left: 0.5rem !important;
    }
    .p-xl-3 {
        padding: 1rem !important;
    }
    .pt-xl-3,
    .py-xl-3 {
        padding-top: 1rem !important;
    }
    .pr-xl-3,
    .px-xl-3 {
        padding-right: 1rem !important;
    }
    .pb-xl-3,
    .py-xl-3 {
        padding-bottom: 1rem !important;
    }
    .pl-xl-3,
    .px-xl-3 {
        padding-left: 1rem !important;
    }
    .p-xl-4 {
        padding: 1.5rem !important;
    }
    .pt-xl-4,
    .py-xl-4 {
        padding-top: 1.5rem !important;
    }
    .pr-xl-4,
    .px-xl-4 {
        padding-right: 1.5rem !important;
    }
    .pb-xl-4,
    .py-xl-4 {
        padding-bottom: 1.5rem !important;
    }
    .pl-xl-4,
    .px-xl-4 {
        padding-left: 1.5rem !important;
    }
    .p-xl-5 {
        padding: 3rem !important;
    }
    .pt-xl-5,
    .py-xl-5 {
        padding-top: 3rem !important;
    }
    .pr-xl-5,
    .px-xl-5 {
        padding-right: 3rem !important;
    }
    .pb-xl-5,
    .py-xl-5 {
        padding-bottom: 3rem !important;
    }
    .pl-xl-5,
    .px-xl-5 {
        padding-left: 3rem !important;
    }
    .m-xl-n1 {
        margin: -0.25rem !important;
    }
    .mt-xl-n1,
    .my-xl-n1 {
        margin-top: -0.25rem !important;
    }
    .mr-xl-n1,
    .mx-xl-n1 {
        margin-right: -0.25rem !important;
    }
    .mb-xl-n1,
    .my-xl-n1 {
        margin-bottom: -0.25rem !important;
    }
    .ml-xl-n1,
    .mx-xl-n1 {
        margin-left: -0.25rem !important;
    }
    .m-xl-n2 {
        margin: -0.5rem !important;
    }
    .mt-xl-n2,
    .my-xl-n2 {
        margin-top: -0.5rem !important;
    }
    .mr-xl-n2,
    .mx-xl-n2 {
        margin-right: -0.5rem !important;
    }
    .mb-xl-n2,
    .my-xl-n2 {
        margin-bottom: -0.5rem !important;
    }
    .ml-xl-n2,
    .mx-xl-n2 {
        margin-left: -0.5rem !important;
    }
    .m-xl-n3 {
        margin: -1rem !important;
    }
    .mt-xl-n3,
    .my-xl-n3 {
        margin-top: -1rem !important;
    }
    .mr-xl-n3,
    .mx-xl-n3 {
        margin-right: -1rem !important;
    }
    .mb-xl-n3,
    .my-xl-n3 {
        margin-bottom: -1rem !important;
    }
    .ml-xl-n3,
    .mx-xl-n3 {
        margin-left: -1rem !important;
    }
    .m-xl-n4 {
        margin: -1.5rem !important;
    }
    .mt-xl-n4,
    .my-xl-n4 {
        margin-top: -1.5rem !important;
    }
    .mr-xl-n4,
    .mx-xl-n4 {
        margin-right: -1.5rem !important;
    }
    .mb-xl-n4,
    .my-xl-n4 {
        margin-bottom: -1.5rem !important;
    }
    .ml-xl-n4,
    .mx-xl-n4 {
        margin-left: -1.5rem !important;
    }
    .m-xl-n5 {
        margin: -3rem !important;
    }
    .mt-xl-n5,
    .my-xl-n5 {
        margin-top: -3rem !important;
    }
    .mr-xl-n5,
    .mx-xl-n5 {
        margin-right: -3rem !important;
    }
    .mb-xl-n5,
    .my-xl-n5 {
        margin-bottom: -3rem !important;
    }
    .ml-xl-n5,
    .mx-xl-n5 {
        margin-left: -3rem !important;
    }
    .m-xl-auto {
        margin: auto !important;
    }
    .mt-xl-auto,
    .my-xl-auto {
        margin-top: auto !important;
    }
    .mr-xl-auto,
    .mx-xl-auto {
        margin-right: auto !important;
    }
    .mb-xl-auto,
    .my-xl-auto {
        margin-bottom: auto !important;
    }
    .ml-xl-auto,
    .mx-xl-auto {
        margin-left: auto !important;
    }
}
.text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, liberation mono,
        courier new, monospace !important;
}
.text-justify {
    text-align: justify !important;
}
.text-wrap {
    white-space: normal !important;
}
.text-nowrap {
    white-space: nowrap !important;
}
.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.text-left {
    text-align: left !important;
}
.text-right {
    text-align: right !important;
}
.text-center,
.tx-c {
    text-align: center !important;
}
@media (min-width: 576px) {
    .text-sm-left {
        text-align: left !important;
    }
    .text-sm-right {
        text-align: right !important;
    }
    .text-sm-center {
        text-align: center !important;
    }
}
@media (min-width: 768px) {
    .text-md-left {
        text-align: left !important;
    }
    .text-md-right {
        text-align: right !important;
    }
    .text-md-center {
        text-align: center !important;
    }
}
@media (min-width: 992px) {
    .text-lg-left {
        text-align: left !important;
    }
    .text-lg-right {
        text-align: right !important;
    }
    .text-lg-center {
        text-align: center !important;
    }
}
@media (min-width: 1200px) {
    .text-xl-left {
        text-align: left !important;
    }
    .text-xl-right {
        text-align: right !important;
    }
    .text-xl-center {
        text-align: center !important;
    }
}
.text-lowercase {
    text-transform: lowercase !important;
}
.t-u,
.text-uppercase {
    text-transform: uppercase !important;
}
.text-capitalize {
    text-transform: capitalize !important;
}
.fw-200 {
    font-weight: 200 !important;
}
.fw-300 {
    font-weight: 300 !important;
}
.fw-400 {
    font-weight: 400 !important;
}
.fw-500 {
    font-weight: 500 !important;
}
.fw-600 {
    font-weight: 600 !important;
}
.font-italic {
    font-style: italic !important;
}
.text-white {
    color: #fff !important;
}
.text-primary {
    color: var(--primary) !important;
}
a.text-primary:focus,
a.text-primary:hover {
    color: var(--primary-dark) !important;
}
.text-secondary {
    color: var(--secondary) !important;
}
a.text-secondary:focus,
a.text-secondary:hover {
    color: var(--secondary-dark) !important;
}
.text-success {
    color: var(--green) !important;
}
a.text-success:focus,
a.text-success:hover {
    color: #0a7137 !important;
}
.text-info {
    color: var(--teal) !important;
}
a.text-info:focus,
a.text-info:hover {
    color: #007688 !important;
}
.text-warning {
    color: var(--orange) !important;
}
a.text-warning:focus,
a.text-warning:hover {
    color: #ba8b00 !important;
}
.text-danger {
    color: var(--red) !important;
}
a.text-danger:focus,
a.text-danger:hover {
    color: var(--red-dark) !important;
}
.text-light {
    color: #f4f5f8 !important;
}
a.text-light:focus,
a.text-light:hover {
    color: #c5cbda !important;
}
.text-dark {
    color: #3b4863 !important;
}
a.text-dark:focus,
a.text-dark:hover {
    color: #1e2533 !important;
}
.text-body {
    color: #001737 !important;
}
.text-muted {
    color: var(--secondary) !important;
}
.text-black-50 {
    color: rgba(0, 0, 0, 0.5) !important;
}
.text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important;
}
.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}
.text-decoration-none {
    text-decoration: none !important;
}
.text-break {
    word-break: break-word !important;
    overflow-wrap: break-word !important;
}
.text-reset {
    color: inherit !important;
}
.visible {
    visibility: visible !important;
}
.invisible {
    visibility: hidden !important;
}
@media print {
    *,
    ::after,
    ::before {
        text-shadow: none !important;
        box-shadow: none !important;
    }
    a:not(.btn) {
        text-decoration: underline;
    }
    abbr[title]::after {
        content: " (" attr(title) ")";
    }
    pre {
        white-space: pre-wrap !important;
    }
    blockquote,
    pre {
        border: 1px solid #97a3b9;
        page-break-inside: avoid;
    }
    thead {
        display: table-header-group;
    }
    img,
    tr {
        page-break-inside: avoid;
    }
    h2,
    h3,
    p {
        orphans: 3;
        widows: 3;
    }
    h2,
    h3 {
        page-break-after: avoid;
    }
    @page {
        size: a3;
    }
    body {
        min-width: 992px !important;
    }
    .container {
        min-width: 992px !important;
    }
    .navbar {
        display: none;
    }
    .badge {
        border: 1px solid #000;
    }
    .table {
        border-collapse: collapse !important;
    }
    .table td,
    .table th {
        background-color: #fff !important;
    }
    .table-bordered td,
    .table-bordered th {
        border: 1px solid #cdd4e0 !important;
    }
    .table-dark {
        color: inherit;
    }
    .table-dark tbody + tbody,
    .table-dark td,
    .table-dark th,
    .table-dark thead th {
        border-color: rgba(72, 94, 144, 0.16);
    }
    .table .thead-dark th {
        color: inherit;
        border-color: rgba(72, 94, 144, 0.16);
    }
}
hr {
    border-color: rgba(72, 94, 144, 0.16);
}
strong {
    font-weight: 600;
}
.alert {
    padding: 12px 15px;
}
.alert-secondary {
    background-color: #e3e7ed;
    border-color: #cdd4e0;
}
.alert-light {
    background-color: #eeeff4;
    border-color: #e3e7ed;
}
.alert-dark {
    background-color: #97a3b9;
    border-color: #8e9bb3;
}
.alert-dismissible .close {
    padding: 0;
    top: 9px;
    right: 15px;
    font-weight: 500;
}
.alert-outline {
    background-color: transparent;
    border-width: 2px;
    font-weight: 500;
    padding: 11px 15px;
    border-radius: 3px;
}
.alert-outline.alert-primary {
    border-color: var(--primary);
    color: var(--primary);
}
.alert-outline.alert-secondary {
    border-color: #97a3b9;
    color: var(--secondary);
}
.alert-outline.alert-success {
    border-color: var(--green);
    color: var(--green);
}
.alert-outline.alert-danger {
    border-color: var(--red);
    color: var(--red);
}
.alert-outline.alert-warning {
    border-color: #edb100;
    color: #edb100;
}
.alert-outline.alert-info {
    border-color: var(--teal);
    color: var(--teal);
}
.alert-outline.alert-light {
    border-color: #cdd4e0;
    color: #97a3b9;
}
.alert-outline.alert-dark {
    border-color: var(--secondary);
    color: #596882;
}
.alert-solid {
    border-width: 0;
}
.alert-solid:not(.alert-light),
.alert-solid:not(.alert-secondary) {
    color: #fff;
}
.alert-solid.alert-primary {
    background-color: var(--primary);
}
.alert-solid.alert-secondary {
    background-color: var(--secondary);
}
.alert-solid.alert-success {
    background-color: var(--green);
}
.alert-solid.alert-danger {
    background-color: var(--red);
}
.alert-solid.alert-warning {
    background-color: var(--orange);
}
.alert-solid.alert-info {
    background-color: var(--teal);
}
.alert-solid.alert-light {
    background-color: #b4bdce;
}
.alert-solid.alert-dark {
    background-color: #596882;
}
.badge {
    font-size: 10px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, inter ui, Roboto, sans-serif;
    padding: 3px 5px;
    border-radius: 3px;
}
.badge-light {
    background-color: #cdd4e0;
}
.badge-pill {
    padding-left: 8px;
    padding-right: 8px;
}
.breadcrumb {
    background-color: #e5e9f2;
}
.breadcrumb-style1,
.breadcrumb-style2,
.breadcrumb-style3 {
    border-radius: 0;
    background-color: transparent;
    padding: 0;
}
.breadcrumb-style1 .breadcrumb-item,
.breadcrumb-style2 .breadcrumb-item,
.breadcrumb-style3 .breadcrumb-item {
    font-size: 10px;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, inter ui, Roboto, sans-serif;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}
.breadcrumb-style1 .breadcrumb-item a,
.breadcrumb-style2 .breadcrumb-item a,
.breadcrumb-style3 .breadcrumb-item a {
    color: #1c273c;
}
.breadcrumb-style1 .breadcrumb-item a:focus,
.breadcrumb-style1 .breadcrumb-item a:hover,
.breadcrumb-style2 .breadcrumb-item a:focus,
.breadcrumb-style2 .breadcrumb-item a:hover,
.breadcrumb-style3 .breadcrumb-item a:focus,
.breadcrumb-style3 .breadcrumb-item a:hover {
    color: var(--primary);
}
.breadcrumb-style1 .breadcrumb-item.active,
.breadcrumb-style2 .breadcrumb-item.active,
.breadcrumb-style3 .breadcrumb-item.active {
    color: var(--primary);
}
.breadcrumb-style1 .breadcrumb-item + .breadcrumb-item::before,
.breadcrumb-style2 .breadcrumb-item + .breadcrumb-item::before,
.breadcrumb-style3 .breadcrumb-item + .breadcrumb-item::before {
    color: #97a3b9;
}
.breadcrumb-style2 .breadcrumb-item + .breadcrumb-item::before,
.breadcrumb-style3 .breadcrumb-item + .breadcrumb-item::before {
    width: 16px;
    text-align: center;
    font-family: ionicons;
}
.breadcrumb-style2 .breadcrumb-item + .breadcrumb-item::before {
    content: "\f3d1";
}
.breadcrumb-style3 .breadcrumb-item + .breadcrumb-item::before {
    content: "\f287";
}
.btn,
.sp-container button,
.ui-datepicker-buttonpane button {
    font-size: inherit;
}
.btn:active,
.btn:focus,
.sp-container button:active,
.sp-container button:focus,
.ui-datepicker-buttonpane button:active,
.ui-datepicker-buttonpane button:focus {
    box-shadow: none;
}
.btn svg,
.sp-container button svg,
.ui-datepicker-buttonpane button svg {
    width: 14px;
    height: 14px;
    stroke-width: 2.5px;
    margin-top: -2px;
}
.btn.active-primary,
.btn.active-primary:focus,
.btn.active-primary:hover,
.sp-container button.active-primary,
.sp-container button.active-primary:focus,
.sp-container button.active-primary:hover,
.ui-datepicker-buttonpane button.active-primary,
.ui-datepicker-buttonpane button.active-primary:focus,
.ui-datepicker-buttonpane button.active-primary:hover {
    background-color: #fff;
    border-color: var(--primary);
    color: var(--primary);
}
.btn-group-sm > .btn,
.btn-sm,
.sp-container .btn-group-sm > button,
.ui-datepicker-buttonpane .btn-group-sm > button,
.ui-datepicker-buttonpane button {
    font-size: 13px;
    padding-left: 15px;
    padding-right: 15px;
}
.btn-xs {
    font-size: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 0.25rem;
}
.btn-group-lg > .btn,
.btn-lg,
.sp-container .btn-group-lg > button,
.ui-datepicker-buttonpane .btn-group-lg > button {
    font-size: 0.875rem;
    border-radius: 0.25rem;
}
.btn-light {
    background-color: #e5e9f2;
    border-color: #cdd5e6;
    color: #8392a5;
}
.btn-light:focus,
.btn-light:hover {
    background-color: #e5e9f2;
    border-color: #cdd5e6;
}
.btn-outline-light {
    border-color: #b4bdce;
    color: var(--secondary);
}
.btn-outline-light:active,
.btn-outline-light:focus,
.btn-outline-light:hover {
    background-color: #b4bdce;
    border-color: #b4bdce;
}
.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show > .btn-outline-light.dropdown-toggle {
    background-color: #cdd4e0;
    border-color: #b4bdce;
}
.btn-white {
    background-color: #fff;
    border-color: #c0ccda;
    color: rgba(27, 46, 75, 0.7);
}
.btn-white:focus,
.btn-white:hover {
    border-color: #8392a5;
    color: #1b2e4b;
}
.btn-white.active,
.btn-white:active {
    background-color: #f5f6fa;
}
.btn-icon {
    padding-left: 10px;
    padding-right: 10px;
}
.btn-group-sm > .btn-icon.btn,
.btn-icon.btn-sm,
.sp-container .btn-group-sm > button.btn-icon,
.ui-datepicker-buttonpane button.btn-icon {
    padding-left: 8px;
    padding-right: 8px;
}
.btn-icon.btn-xs {
    padding-left: 6px;
    padding-right: 6px;
}
.btn-icon svg {
    margin: 0;
}
.btn-brand-01 {
    background-color: #015de1;
    border-color: #015de1;
    color: #fff;
}
.btn-brand-01:focus,
.btn-brand-01:hover {
    background-color: #0153c7;
    border-color: #0153c7;
    color: #fff;
}
.btn-brand-02 {
    background-color: #042893;
    border-color: #042893;
    color: #fff;
}
.btn-brand-02:focus,
.btn-brand-02:hover {
    background-color: #063cdd;
    border-color: #063cdd;
    color: #fff;
}
.btn-outline-facebook {
    background-color: transparent;
    border-color: #4064ac;
    color: #4064ac;
}
.btn-outline-facebook:focus,
.btn-outline-facebook:hover {
    background-color: #4064ac;
    border-color: #4064ac;
    color: #fff;
}
.btn-outline-twitter {
    background-color: transparent;
    border-color: #00a7e6;
    color: #00a7e6;
}
.btn-outline-twitter:focus,
.btn-outline-twitter:hover {
    background-color: #00a7e6;
    border-color: #00a7e6;
    color: #fff;
}
.btn-uppercase {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 1.75;
}
.btn-group-sm > .btn-uppercase.btn,
.btn-uppercase.btn-sm,
.sp-container .btn-group-sm > button.btn-uppercase,
.ui-datepicker-buttonpane button.btn-uppercase {
    font-size: 11px;
    line-height: 1.773;
}
.btn-uppercase.btn-xs {
    font-size: 10px;
    line-height: 1.8;
}
.card.bg-danger,
.card.bg-dark,
.card.bg-info,
.card.bg-primary,
.card.bg-secondary,
.card.bg-success,
.card.bg-warning {
    border-width: 0;
}
.card.bg-danger .card-header,
.card.bg-dark .card-header,
.card.bg-info .card-header,
.card.bg-primary .card-header,
.card.bg-secondary .card-header,
.card.bg-success .card-header,
.card.bg-warning .card-header {
    background-color: transparent;
    border-bottom-color: rgba(255, 255, 255, 0.1);
}
.card-footer,
.card-header {
    background-color: transparent;
    border-color: rgba(72, 94, 144, 0.16);
}
.card-header {
    padding: 15px;
}
@media (min-width: 576px) {
    .card-header {
        padding: 15px 20px;
    }
}
.card-body {
    padding: 15px;
}
@media (min-width: 576px) {
    .card-body {
        padding: 20px;
    }
}
.card-img {
    border-radius: 0.25rem;
}
.card-img-top {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}
.card-img-bottom {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
    background-image: none;
    display: block;
    width: auto;
    height: auto;
}
.carousel-control-next-icon svg,
.carousel-control-prev-icon svg {
    width: 48px;
    height: 48px;
}
.carousel-indicators li {
    width: 10px;
    height: 10px;
    border-radius: 100%;
}
.custom-control {
    min-height: inherit;
}
.custom-checkbox
    .custom-control-input:disabled:checked
    ~ .custom-control-label::before,
.custom-radio
    .custom-control-input:disabled:checked
    ~ .custom-control-label::before,
.custom-switch
    .custom-control-input:disabled:checked
    ~ .custom-control-label::before {
    border-color: rgba(1, 104, 250, 0.2);
}
.custom-checkbox .custom-control-label::after,
.custom-checkbox .custom-control-label::before,
.custom-radio .custom-control-label::after,
.custom-radio .custom-control-label::before,
.custom-switch .custom-control-label::after,
.custom-switch .custom-control-label::before {
    top: 54%;
    transform: translateY(-50%);
}
.custom-checkbox .custom-control-label:empty,
.custom-radio .custom-control-label:empty,
.custom-switch .custom-control-label:empty {
    height: 20px;
}
.custom-checkbox .custom-control-label:empty::after,
.custom-checkbox .custom-control-label:empty::before,
.custom-radio .custom-control-label:empty::after,
.custom-radio .custom-control-label:empty::before,
.custom-switch .custom-control-label:empty::after,
.custom-switch .custom-control-label:empty::before {
    left: -16px;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    transform: translate(0.75rem, -50%);
}
.custom-select {
    border-color: #c0ccda;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.custom-select:invalid {
    color: #97a3b9;
}
.custom-select option {
    color: #001737;
}
.custom-file-label,
.custom-file-label::after {
    display: flex;
    align-items: center;
}
.custom-file-label::after {
    background-color: #f5f6fa;
}
.dropdown-menu {
    border-color: rgba(131, 146, 165, 0.27);
    box-shadow: var(--box-shadow);
    padding: 5px;
}
.dropdown-item {
    padding: 6px 15px;
    border-radius: 0.25rem;
    transition: all 0.25s ease;
}
@media (prefers-reduced-motion: reduce) {
    .dropdown-item {
        transition: none;
    }
}
.dropdown-item:focus,
.dropdown-item:hover {
    background-color: #e5e9f2;
}
.dropdown-item.active {
    background-color: var(--primary);
}
.dropdown-header {
    padding-left: 15px;
    padding-right: 15px;
}
.dropdown-divider {
    margin: 2px 15px;
}
.form-control {
    color: #1b2e4b;
    font-size: inherit;
}
.form-control::placeholder {
    color: #c0ccda;
}
.form-control:disabled,
.form-control[readonly] {
    background-color: #f5f6fa;
}
textarea.form-control {
    min-height: 38px;
}
.form-group {
    margin-bottom: 20px;
}
.form-fieldset {
    background-color: #fff;
    border: 1px solid rgba(72, 94, 144, 0.16);
    padding: 20px 25px 25px;
    border-radius: 0.25rem;
}
.form-fieldset .form-group:last-child {
    margin-bottom: 0;
}
.form-fieldset legend {
    width: auto;
    background-color: inherit;
    padding: 0 10px;
    position: relative;
    margin-left: -10px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: inherit;
    color: #1c273c;
    letter-spacing: 0.5;
    margin-bottom: 0;
}
.row-sm {
    margin-left: -10px;
    margin-right: -10px;
}
.row-sm > div {
    padding-left: 10px;
    padding-right: 10px;
}
.row-xs {
    margin-left: -5px;
    margin-right: -5px;
}
.row-xs > div {
    padding-left: 5px;
    padding-right: 5px;
}
.row-xxs {
    margin-left: -1px;
    margin-right: -1px;
}
.row-xxs > div {
    padding-left: 1px;
    padding-right: 1px;
}
@media (min-width: 576px) {
    .row-xs--sm {
        margin-left: -5px;
        margin-right: -5px;
    }
    .row-xs--sm > div {
        padding-left: 5px;
        padding-right: 5px;
    }
    .row-sm--sm {
        margin-left: -10px;
        margin-right: -10px;
    }
    .row-sm--sm > div {
        padding-left: 10px;
        padding-right: 10px;
    }
    .row--sm {
        margin-left: -15px;
        margin-right: -15px;
    }
    .row--sm > div {
        padding-left: 15px;
        padding-right: 15px;
    }
}
@media (min-width: 768px) {
    .row-xs--md {
        margin-left: -5px;
        margin-right: -5px;
    }
    .row-xs--md > div {
        padding-left: 5px;
        padding-right: 5px;
    }
    .row-sm--md {
        margin-left: -10px;
        margin-right: -10px;
    }
    .row-sm--md > div {
        padding-left: 10px;
        padding-right: 10px;
    }
    .row--md {
        margin-left: -15px;
        margin-right: -15px;
    }
    .row--md > div {
        padding-left: 15px;
        padding-right: 15px;
    }
}
@media (min-width: 992px) {
    .row-xs--lg {
        margin-left: -5px;
        margin-right: -5px;
    }
    .row-xs--lg > div {
        padding-left: 5px;
        padding-right: 5px;
    }
    .row-sm--lg {
        margin-left: -10px;
        margin-right: -10px;
    }
    .row-sm--lg > div {
        padding-left: 10px;
        padding-right: 10px;
    }
    .row--lg {
        margin-left: -15px;
        margin-right: -15px;
    }
    .row--lg > div {
        padding-left: 15px;
        padding-right: 15px;
    }
}
@media (min-width: 1200px) {
    .row-xs--xl {
        margin-left: -5px;
        margin-right: -5px;
    }
    .row-xs--xl > div {
        padding-left: 5px;
        padding-right: 5px;
    }
    .row-sm--xl {
        margin-left: -10px;
        margin-right: -10px;
    }
    .row-sm--xl > div {
        padding-left: 10px;
        padding-right: 10px;
    }
    .row--xl {
        margin-left: -15px;
        margin-right: -15px;
    }
    .row--xl > div {
        padding-left: 15px;
        padding-right: 15px;
    }
}
.list-group-item {
    padding: 10px 15px;
    border-color: #e5e9f2;
}
.modal-content,
.modal-content-main {
    border-color: #cdcdcdd4;
    border-radius: 0.25rem;
}
.modal-content .close,
.modal-content-main .close {
    font-weight: 300;
    font-size: 28px;
    line-height: 0.87;
    margin-top: -20px;
}
.modal-backdrop {
    background-color: rgba(15, 21, 32, 0.7);
}
.modal-backdrop.show {
    opacity: 1;
}
.nav-link {
    color: #596882;
}
.nav-link:focus,
.nav-link:hover {
    color: var(--primary);
}
.nav-link.active {
    color: var(--primary);
}
.nav-pills .nav-link {
    transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .nav-pills .nav-link {
        transition: none;
    }
}
.nav-pills .nav-link:not(.active):focus,
.nav-pills .nav-link:not(.active):hover {
    background-color: #eeeff4;
    color: #001737;
}
.nav-pills:not(.flex-column) .nav-item + .nav-item,
.nav-pills:not(.flex-column) > .nav-link + .nav-link {
    margin-left: 2px;
}
.navbar-toggler {
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-width: 0;
    color: var(--secondary);
}
.navbar-toggler:focus,
.navbar-toggler:hover {
    color: #1c273c;
}
.navbar-toggler .feather {
    stroke-width: 2.5px;
}
.navbar-light .navbar-toggler {
    color: var(--secondary);
}
.navbar-light .navbar-toggler:focus,
.navbar-light .navbar-toggler:hover {
    color: #1c273c;
}
.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-toggler:focus,
.navbar-dark .navbar-toggler:hover {
    color: #fff;
}
.pagination {
    font-size: 14px;
    font-weight: 500;
    justify-content: flex-end;
    margin-top: 1rem;
    overflow-x: auto;
}
.page-item.disabled {
    font-weight: 400;
}
.page-item.disabled .page-link {
    color: #97a3b9;
}
.page-item.active .page-link {
    background-color: var(--primary);
}
.page-link {
    min-width: 36px;
    height: 100%;
    padding: 8px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary);
    transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .page-link {
        transition: none;
    }
}
.page-link:focus,
.page-link:hover {
    z-index: auto;
    background-color: #f1f2f6;
}
.page-link .feather {
    width: 16px;
    height: 16px;
    stroke-width: 2.5px;
}
.page-link-icon {
    padding: 9px 8px;
}
.pagination-space .page-item + .page-item {
    margin-left: 5px;
}
.pagination-space .page-link {
    margin-left: 0;
    border-radius: 0.25rem;
}
.pagination-outline .page-item + .page-item {
    margin-left: 5px;
}
.pagination-outline .page-item:first-child .page-link,
.pagination-outline .page-item:last-child .page-link {
    border-radius: 3px;
}
.pagination-outline .page-link {
    padding: 8px 7px;
    margin-left: 0;
    border-width: 2px;
    border-color: var(--primary);
    border-radius: 3px;
}
.pagination-filled .page-item + .page-item {
    margin-left: 5px;
}
.pagination-filled .page-item.disabled .page-link {
    background-color: #e9ecf1;
    color: #b4bdce;
}
.pagination-filled .page-link {
    min-width: 34px;
    padding: 9px 6px;
    border-width: 0;
    background-color: #b4bdce;
    color: #596882;
    border-radius: 0.25rem;
}
.pagination-filled .page-link:focus,
.pagination-filled .page-link:hover {
    background-color: #97a3b9;
}
.pagination-circle .page-item + .page-item {
    margin-left: 5px;
}
.pagination-circle .page-item:first-child .page-link,
.pagination-circle .page-item:last-child .page-link {
    border-radius: 100%;
}
.pagination-circle .page-link {
    margin-left: 0;
    width: 36px;
    height: 36px;
    border-radius: 100%;
}
.popover {
    border-color: rgba(28, 39, 60, 0.12);
    box-shadow: 0 0 8px rgba(28, 39, 60, 0.08);
    border-radius: 0.25rem;
    font-size: 13px;
    font-family: var(--font-family);
}
.popover-body {
    padding: 8px 12px;
}
.popover-header {
    background-color: #f1f2f6;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    font-size: 14px;
    color: #1c273c;
    border-bottom-color: #e3e7ed;
    padding: 10px 15px;
}
.popover-header-danger .popover-header,
.popover-header-info .popover-header,
.popover-header-primary .popover-header,
.popover-header-secondary .popover-header,
.popover-header-success .popover-header,
.popover-header-warning .popover-header {
    color: #fff;
    border-bottom-width: 0;
    position: relative;
    margin-top: -1px;
    margin-left: -1px;
    margin-right: -1px;
}
.popover-header-danger.bs-popover-auto[x-placement^="bottom"]
    .popover-header::before,
.popover-header-danger.bs-popover-bottom .popover-header::before,
.popover-header-info.bs-popover-auto[x-placement^="bottom"]
    .popover-header::before,
.popover-header-info.bs-popover-bottom .popover-header::before,
.popover-header-primary.bs-popover-auto[x-placement^="bottom"]
    .popover-header::before,
.popover-header-primary.bs-popover-bottom .popover-header::before,
.popover-header-secondary.bs-popover-auto[x-placement^="bottom"]
    .popover-header::before,
.popover-header-secondary.bs-popover-bottom .popover-header::before,
.popover-header-success.bs-popover-auto[x-placement^="bottom"]
    .popover-header::before,
.popover-header-success.bs-popover-bottom .popover-header::before,
.popover-header-warning.bs-popover-auto[x-placement^="bottom"]
    .popover-header::before,
.popover-header-warning.bs-popover-bottom .popover-header::before {
    display: none;
}
.popover-header-warning .popover-header {
    color: #001737;
}
.popover-header-primary.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.popover-header-primary.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover-header-primary.bs-popover-bottom .arrow::after,
.popover-header-primary.bs-popover-bottom .arrow::before {
    border-bottom-color: var(--primary);
}
.popover-header-primary .popover-header {
    background-color: var(--primary);
}
.popover-header-secondary.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.popover-header-secondary.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover-header-secondary.bs-popover-bottom .arrow::after,
.popover-header-secondary.bs-popover-bottom .arrow::before {
    border-bottom-color: var(--secondary);
}
.popover-header-secondary .popover-header {
    background-color: var(--secondary);
}
.popover-header-success.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.popover-header-success.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover-header-success.bs-popover-bottom .arrow::after,
.popover-header-success.bs-popover-bottom .arrow::before {
    border-bottom-color: var(--green);
}
.popover-header-success .popover-header {
    background-color: var(--green);
}
.popover-header-danger.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.popover-header-danger.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover-header-danger.bs-popover-bottom .arrow::after,
.popover-header-danger.bs-popover-bottom .arrow::before {
    border-bottom-color: var(--red);
}
.popover-header-danger .popover-header {
    background-color: var(--red);
}
.popover-header-warning.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.popover-header-warning.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover-header-warning.bs-popover-bottom .arrow::after,
.popover-header-warning.bs-popover-bottom .arrow::before {
    border-bottom-color: var(--orange);
}
.popover-header-warning .popover-header {
    background-color: var(--orange);
}
.popover-header-info.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.popover-header-info.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover-header-info.bs-popover-bottom .arrow::after,
.popover-header-info.bs-popover-bottom .arrow::before {
    border-bottom-color: var(--teal);
}
.popover-header-info .popover-header {
    background-color: var(--teal);
}
.popover-danger,
.popover-info,
.popover-primary,
.popover-secondary,
.popover-success,
.popover-warning {
    border-width: 0;
}
.popover-danger .popover-header,
.popover-info .popover-header,
.popover-primary .popover-header,
.popover-secondary .popover-header,
.popover-success .popover-header,
.popover-warning .popover-header {
    background-color: transparent;
    border-bottom-color: rgba(255, 255, 255, 0.08);
    color: #fff;
}
.popover-danger .popover-header::before,
.popover-info .popover-header::before,
.popover-primary .popover-header::before,
.popover-secondary .popover-header::before,
.popover-success .popover-header::before,
.popover-warning .popover-header::before {
    display: none;
}
.popover-danger .popover-body,
.popover-info .popover-body,
.popover-primary .popover-body,
.popover-secondary .popover-body,
.popover-success .popover-body,
.popover-warning .popover-body {
    color: rgba(255, 255, 255, 0.7);
}
.popover-danger .arrow::before,
.popover-info .arrow::before,
.popover-primary .arrow::before,
.popover-secondary .arrow::before,
.popover-success .arrow::before,
.popover-warning .arrow::before {
    display: none;
}
.popover-primary {
    background-color: var(--primary);
}
.popover-primary.bs-popover-auto[x-placement^="top"] .arrow::after,
.popover-primary.bs-popover-top .arrow::after {
    border-top-color: var(--primary);
}
.popover-primary.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.popover-primary.bs-popover-bottom .arrow::after {
    border-bottom-color: var(--primary);
}
.popover-primary.bs-popover-auto[x-placement^="right"] .arrow::after,
.popover-primary.bs-popover-right .arrow::after {
    border-right-color: var(--primary);
}
.popover-primary.bs-popover-auto[x-placement^="left"] .arrow::after,
.popover-primary.bs-popover-left .arrow::after {
    border-left-color: var(--primary);
}
.popover-secondary {
    background-color: var(--secondary);
}
.popover-secondary.bs-popover-auto[x-placement^="top"] .arrow::after,
.popover-secondary.bs-popover-top .arrow::after {
    border-top-color: var(--secondary);
}
.popover-secondary.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.popover-secondary.bs-popover-bottom .arrow::after {
    border-bottom-color: var(--secondary);
}
.popover-secondary.bs-popover-auto[x-placement^="right"] .arrow::after,
.popover-secondary.bs-popover-right .arrow::after {
    border-right-color: var(--secondary);
}
.popover-secondary.bs-popover-auto[x-placement^="left"] .arrow::after,
.popover-secondary.bs-popover-left .arrow::after {
    border-left-color: var(--secondary);
}
.popover-success {
    background-color: var(--green);
}
.popover-success.bs-popover-auto[x-placement^="top"] .arrow::after,
.popover-success.bs-popover-top .arrow::after {
    border-top-color: var(--green);
}
.popover-success.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.popover-success.bs-popover-bottom .arrow::after {
    border-bottom-color: var(--green);
}
.popover-success.bs-popover-auto[x-placement^="right"] .arrow::after,
.popover-success.bs-popover-right .arrow::after {
    border-right-color: var(--green);
}
.popover-success.bs-popover-auto[x-placement^="left"] .arrow::after,
.popover-success.bs-popover-left .arrow::after {
    border-left-color: var(--green);
}
.popover-warning {
    background-color: var(--orange);
}
.popover-warning.bs-popover-auto[x-placement^="top"] .arrow::after,
.popover-warning.bs-popover-top .arrow::after {
    border-top-color: var(--orange);
}
.popover-warning.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.popover-warning.bs-popover-bottom .arrow::after {
    border-bottom-color: var(--orange);
}
.popover-warning.bs-popover-auto[x-placement^="right"] .arrow::after,
.popover-warning.bs-popover-right .arrow::after {
    border-right-color: var(--orange);
}
.popover-warning.bs-popover-auto[x-placement^="left"] .arrow::after,
.popover-warning.bs-popover-left .arrow::after {
    border-left-color: var(--orange);
}
.popover-danger {
    background-color: var(--red);
}
.popover-danger.bs-popover-auto[x-placement^="top"] .arrow::after,
.popover-danger.bs-popover-top .arrow::after {
    border-top-color: var(--red);
}
.popover-danger.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.popover-danger.bs-popover-bottom .arrow::after {
    border-bottom-color: var(--red);
}
.popover-danger.bs-popover-auto[x-placement^="right"] .arrow::after,
.popover-danger.bs-popover-right .arrow::after {
    border-right-color: var(--red);
}
.popover-danger.bs-popover-auto[x-placement^="left"] .arrow::after,
.popover-danger.bs-popover-left .arrow::after {
    border-left-color: var(--red);
}
.popover-info {
    background-color: var(--teal);
}
.popover-info.bs-popover-auto[x-placement^="top"] .arrow::after,
.popover-info.bs-popover-top .arrow::after {
    border-top-color: var(--teal);
}
.popover-info.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.popover-info.bs-popover-bottom .arrow::after {
    border-bottom-color: var(--teal);
}
.popover-info.bs-popover-auto[x-placement^="right"] .arrow::after,
.popover-info.bs-popover-right .arrow::after {
    border-right-color: var(--teal);
}
.popover-info.bs-popover-auto[x-placement^="left"] .arrow::after,
.popover-info.bs-popover-left .arrow::after {
    border-left-color: var(--teal);
}
.popover-warning .popover-header {
    color: #001737;
    border-bottom-color: rgba(0, 23, 55, 0.05);
}
.popover-warning .popover-body {
    color: rgba(0, 23, 55, 0.8);
}
.progress {
    height: 10px;
}
.toast {
    background-color: rgba(255, 255, 255, 0.95);
    border-color: rgba(72, 94, 144, 0.16);
    box-shadow: 0 2px 3px rgba(28, 39, 60, 0.06);
    border-radius: 0.25rem;
}
.toast-header {
    border-bottom-color: rgba(205, 212, 224, 0.4);
}
.toast-header .close {
    font-weight: 300;
    color: #97a3b9;
}
.tooltip-inner {
    font-size: 12px;
    padding: 5px 10px;
}
.tooltip-primary .tooltip-inner {
    background-color: var(--primary);
}
.tooltip-primary.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.tooltip-primary.bs-tooltip-top .arrow::before {
    border-top-color: var(--primary);
}
.tooltip-primary.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.tooltip-primary.bs-tooltip-bottom .arrow::before {
    border-bottom-color: var(--primary);
}
.tooltip-primary.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.tooltip-primary.bs-tooltip-left .arrow::before {
    border-left-color: var(--primary);
}
.tooltip-primary.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.tooltip-primary.bs-tooltip-right .arrow::before {
    border-right-color: var(--primary);
}
.tooltip-secondary .tooltip-inner {
    background-color: var(--secondary);
}
.tooltip-secondary.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.tooltip-secondary.bs-tooltip-top .arrow::before {
    border-top-color: var(--secondary);
}
.tooltip-secondary.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.tooltip-secondary.bs-tooltip-bottom .arrow::before {
    border-bottom-color: var(--secondary);
}
.tooltip-secondary.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.tooltip-secondary.bs-tooltip-left .arrow::before {
    border-left-color: var(--secondary);
}
.tooltip-secondary.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.tooltip-secondary.bs-tooltip-right .arrow::before {
    border-right-color: var(--secondary);
}
.tooltip-success .tooltip-inner {
    background-color: var(--green);
}
.tooltip-success.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.tooltip-success.bs-tooltip-top .arrow::before {
    border-top-color: var(--green);
}
.tooltip-success.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.tooltip-success.bs-tooltip-bottom .arrow::before {
    border-bottom-color: var(--green);
}
.tooltip-success.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.tooltip-success.bs-tooltip-left .arrow::before {
    border-left-color: var(--green);
}
.tooltip-success.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.tooltip-success.bs-tooltip-right .arrow::before {
    border-right-color: var(--green);
}
.tooltip-danger .tooltip-inner {
    background-color: var(--red);
}
.tooltip-danger.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.tooltip-danger.bs-tooltip-top .arrow::before {
    border-top-color: var(--red);
}
.tooltip-danger.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.tooltip-danger.bs-tooltip-bottom .arrow::before {
    border-bottom-color: var(--red);
}
.tooltip-danger.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.tooltip-danger.bs-tooltip-left .arrow::before {
    border-left-color: var(--red);
}
.tooltip-danger.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.tooltip-danger.bs-tooltip-right .arrow::before {
    border-right-color: var(--red);
}
.tooltip-warning .tooltip-inner {
    background-color: var(--orange);
}
.tooltip-warning.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.tooltip-warning.bs-tooltip-top .arrow::before {
    border-top-color: var(--orange);
}
.tooltip-warning.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.tooltip-warning.bs-tooltip-bottom .arrow::before {
    border-bottom-color: var(--orange);
}
.tooltip-warning.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.tooltip-warning.bs-tooltip-left .arrow::before {
    border-left-color: var(--orange);
}
.tooltip-warning.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.tooltip-warning.bs-tooltip-right .arrow::before {
    border-right-color: var(--orange);
}
.tooltip-info .tooltip-inner {
    background-color: var(--teal);
}
.tooltip-info.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.tooltip-info.bs-tooltip-top .arrow::before {
    border-top-color: var(--teal);
}
.tooltip-info.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.tooltip-info.bs-tooltip-bottom .arrow::before {
    border-bottom-color: var(--teal);
}
.tooltip-info.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.tooltip-info.bs-tooltip-left .arrow::before {
    border-left-color: var(--teal);
}
.tooltip-info.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.tooltip-info.bs-tooltip-right .arrow::before {
    border-right-color: var(--teal);
}
.tooltip-light .tooltip-inner {
    background-color: #b4bdce;
}
.tooltip-light.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.tooltip-light.bs-tooltip-top .arrow::before {
    border-top-color: #b4bdce;
}
.tooltip-light.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.tooltip-light.bs-tooltip-bottom .arrow::before {
    border-bottom-color: #b4bdce;
}
.tooltip-light.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.tooltip-light.bs-tooltip-left .arrow::before {
    border-left-color: #b4bdce;
}
.tooltip-light.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.tooltip-light.bs-tooltip-right .arrow::before {
    border-right-color: #b4bdce;
}
.tooltip-dark .tooltip-inner {
    background-color: #3b4863;
}
.tooltip-dark.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.tooltip-dark.bs-tooltip-top .arrow::before {
    border-top-color: #3b4863;
}
.tooltip-dark.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.tooltip-dark.bs-tooltip-bottom .arrow::before {
    border-bottom-color: #3b4863;
}
.tooltip-dark.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.tooltip-dark.bs-tooltip-left .arrow::before {
    border-left-color: #3b4863;
}
.tooltip-dark.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.tooltip-dark.bs-tooltip-right .arrow::before {
    border-right-color: #3b4863;
}
.table td,
.table th {
    padding: 8px 10px;
    line-height: 1.5;
    vertical-align: middle;
}
.table tbody th,
.table thead th {
    font-weight: 500;
}
.table thead th {
    border-bottom-width: 0;
}
.table-striped tbody tr:nth-of-type(2n + 1) {
    background-color: #f4f7fc8a;
}
.table-hover tbody tr:hover {
    background-color: #e9ecf1;
}
.table-sm td,
.table-sm th {
    padding: 5px 10px;
}
.thead-danger,
.thead-dark,
.thead-info,
.thead-light,
.thead-primary,
.thead-secondary,
.thead-success,
.thead-warning {
    color: #fff;
}
.thead-danger th,
.thead-dark th,
.thead-info th,
.thead-light th,
.thead-primary th,
.thead-secondary th,
.thead-success th,
.thead-warning th {
    border-top-width: 0;
}
.thead-danger + tbody tr:first-child td,
.thead-danger + tbody tr:first-child th,
.thead-dark + tbody tr:first-child td,
.thead-dark + tbody tr:first-child th,
.thead-info + tbody tr:first-child td,
.thead-info + tbody tr:first-child th,
.thead-light + tbody tr:first-child td,
.thead-light + tbody tr:first-child th,
.thead-primary + tbody tr:first-child td,
.thead-primary + tbody tr:first-child th,
.thead-secondary + tbody tr:first-child td,
.thead-secondary + tbody tr:first-child th,
.thead-success + tbody tr:first-child td,
.thead-success + tbody tr:first-child th,
.thead-warning + tbody tr:first-child td,
.thead-warning + tbody tr:first-child th {
    border-top-width: 0;
}
.thead-primary {
    background-color: var(--primary);
}
.thead-secondary {
    background-color: var(--secondary);
}
.thead-success {
    background-color: var(--green);
}
.thead-danger {
    background-color: var(--red);
}
.thead-warning {
    background-color: var(--orange);
}
.thead-info {
    background-color: var(--teal);
}
.thead-dark {
    background-color: #3b4863;
}
.thead-light {
    background-color: rgba(72, 94, 144, 0.16);
    color: #596882;
}
.table-danger thead,
.table-dark thead,
.table-info thead,
.table-light thead,
.table-primary thead,
.table-secondary thead,
.table-success thead,
.table-warning thead {
    color: #fff;
}
.table-danger thead th,
.table-dark thead th,
.table-info thead th,
.table-light thead th,
.table-primary thead th,
.table-secondary thead th,
.table-success thead th,
.table-warning thead th {
    border-top-width: 0;
}
.table-danger tbody tr td,
.table-danger tbody tr th,
.table-dark tbody tr td,
.table-dark tbody tr th,
.table-info tbody tr td,
.table-info tbody tr th,
.table-light tbody tr td,
.table-light tbody tr th,
.table-primary tbody tr td,
.table-primary tbody tr th,
.table-secondary tbody tr td,
.table-secondary tbody tr th,
.table-success tbody tr td,
.table-success tbody tr th,
.table-warning tbody tr td,
.table-warning tbody tr th {
    border-top-color: rgba(255, 255, 255, 0.45);
}
.table-danger tbody tr:first-child td,
.table-danger tbody tr:first-child th,
.table-dark tbody tr:first-child td,
.table-dark tbody tr:first-child th,
.table-info tbody tr:first-child td,
.table-info tbody tr:first-child th,
.table-light tbody tr:first-child td,
.table-light tbody tr:first-child th,
.table-primary tbody tr:first-child td,
.table-primary tbody tr:first-child th,
.table-secondary tbody tr:first-child td,
.table-secondary tbody tr:first-child th,
.table-success tbody tr:first-child td,
.table-success tbody tr:first-child th,
.table-warning tbody tr:first-child td,
.table-warning tbody tr:first-child th {
    border-top-width: 0;
}
.table-primary thead {
    background-color: var(--primary);
}
.table-primary tbody tr {
    background-color: #f1f7ff;
}
.table-primary.table-striped tbody tr:nth-of-type(2n + 1) {
    background-color: #e2eeff;
}
.table-primary.table-hover tbody tr:hover {
    background-color: #d2e5ff;
}
.table-secondary thead {
    background-color: var(--secondary);
}
.table-secondary tbody tr {
    background-color: #e3e7ed;
}
.table-secondary.table-striped tbody tr:nth-of-type(2n + 1) {
    background-color: #dadfe7;
}
.table-secondary.table-hover tbody tr:hover {
    background-color: #d0d7e1;
}
.table-success thead {
    background-color: var(--green);
}
.table-success tbody tr {
    background-color: #e8fdf1;
}
.table-success.table-striped tbody tr:nth-of-type(2n + 1) {
    background-color: #dafce9;
}
.table-success.table-hover tbody tr:hover {
    background-color: #ccfae0;
}
.table-danger thead {
    background-color: var(--red);
}
.table-danger tbody tr {
    background-color: #fef9f9;
}
.table-danger.table-striped tbody tr:nth-of-type(2n + 1) {
    background-color: #fceced;
}
.table-danger.table-hover tbody tr:hover {
    background-color: #f9dfe1;
}
.table-warning thead {
    background-color: var(--orange);
}
.table-warning tbody tr {
    background-color: #fffbf8;
}
.table-warning.table-striped tbody tr:nth-of-type(2n + 1) {
    background-color: #fff3e8;
}
.table-warning.table-hover tbody tr:hover {
    background-color: #ffead9;
}
.table-info thead {
    background-color: var(--teal);
}
.table-info tbody tr {
    background-color: #eefdff;
}
.table-info.table-striped tbody tr:nth-of-type(2n + 1) {
    background-color: #defbff;
}
.table-info.table-hover tbody tr:hover {
    background-color: #cff9ff;
}
.table-light thead {
    background-color: #e3e7ed;
}
.table-light tbody tr {
    background-color: #f9fafb;
}
.table-light.table-striped tbody tr:nth-of-type(2n + 1) {
    background-color: #eff2f5;
}
.table-light.table-hover tbody tr:hover {
    background-color: #e6eaef;
}
.table-dark thead {
    background-color: #3b4863;
}
.table-dark tbody tr {
    background-color: #e3e7ed;
}
.table-dark.table-striped tbody tr:nth-of-type(2n + 1) {
    background-color: #dadfe7;
}
.table-dark.table-hover tbody tr:hover {
    background-color: #d0d7e1;
}
.table-light thead {
    color: #001737;
}
.table-dark tbody {
    color: #001737;
}
.accordion {
    border-radius: 0.25rem;
}
.ui-accordion-header {
    font-size: 14px;
    font-weight: 500;
    color: #1b2e4b;
    margin-bottom: 0;
    border: 1px solid rgba(72, 94, 144, 0.16);
    background-color: #f5f6fa;
    padding: 12px 15px 12px 35px;
    position: relative;
    outline: 0;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .ui-accordion-header {
        transition: none;
    }
}
.ui-accordion-header ~ .ui-accordion-header {
    margin-top: -1px;
}
.ui-accordion-header:first-of-type {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}
.ui-accordion-header:last-of-type:not(.ui-state-active) {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}
.ui-accordion-header:hover {
    color: #001737;
    background-color: #eef0f7;
}
.ui-accordion-header .ui-accordion-header-icon {
    position: absolute;
    left: 15px;
    top: 12px;
    display: inline-block;
    margin-right: 10px;
}
.ui-accordion-header .ui-accordion-header-icon::before {
    content: "\f273";
    font-family: ionicons;
    font-size: 14px;
    display: inline-block;
}
.ui-accordion-header.ui-state-active {
    background-color: #fff;
    border-bottom-width: 0;
    color: var(--primary);
}
.ui-accordion-header.ui-state-active .ui-accordion-header-icon::before {
    content: "\f368";
}
.ui-accordion-content {
    border: 1px solid rgba(72, 94, 144, 0.16);
    border-top-width: 0;
    padding: 0 20px 20px 35px;
    position: relative;
}
.ui-accordion-content:last-of-type {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}
.accordion-style1 .ui-accordion-header.ui-state-active {
    border-color: var(--primary);
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    background-color: #f1f7ff;
}
.accordion-style1 .ui-accordion-content.ui-accordion-content-active {
    z-index: 5;
    border-color: var(--primary);
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    background-color: #f1f7ff;
}
.accordion-style2 {
    box-shadow: none;
}
.accordion-style2 .ui-accordion-header {
    border-width: 0;
    background-color: var(--primary);
    color: #fff;
    border-width: 2px;
    border-bottom-width: 0;
    border-color: transparent;
    border-radius: 0.25rem;
}
.accordion-style2 .ui-accordion-header ~ .ui-accordion-header {
    margin-top: 5px;
}
.accordion-style2 .ui-accordion-header.ui-state-active {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-color: #f1f7ff;
    color: var(--primary);
    border-color: var(--primary);
}
.accordion-style2 .ui-accordion-content {
    background-color: #f1f7ff;
    border-width: 2px;
    border-top-width: 0;
    border-color: var(--primary);
}
.accordion-style2 .ui-accordion-content.ui-accordion-content-active {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}
.accordion-pink .ui-accordion-header.ui-state-active {
    color: #f10075;
}
.accordion-pink.accordion-style1 .ui-accordion-header.ui-state-active {
    border-color: #f10075;
    background-color: #fffbfd;
}
.accordion-pink.accordion-style1
    .ui-accordion-content.ui-accordion-content-active {
    border-color: #f10075;
    background-color: #fffbfd;
}
.accordion-pink.accordion-style2 .ui-accordion-header {
    background-color: #f10075;
}
.accordion-pink.accordion-style2 .ui-accordion-header:hover {
    background-color: #e70070;
}
.accordion-pink.accordion-style2 .ui-accordion-header.ui-state-active {
    background-color: #fffbfd;
    color: #f10075;
    border-color: #f10075;
}
.accordion-pink.accordion-style2 .ui-accordion-content {
    background-color: #fffbfd;
    border-color: #f10075;
}
.accordion-teal .ui-accordion-header.ui-state-active {
    color: #0cc;
}
.accordion-teal.accordion-style1 .ui-accordion-header.ui-state-active {
    border-color: #0cc;
    background-color: #ebffff;
}
.accordion-teal.accordion-style1
    .ui-accordion-content.ui-accordion-content-active {
    border-color: #0cc;
    background-color: #ebffff;
}
.accordion-teal.accordion-style2 .ui-accordion-header {
    background-color: #0cc;
}
.accordion-teal.accordion-style2 .ui-accordion-header:hover {
    background-color: #00c2c2;
}
.accordion-teal.accordion-style2 .ui-accordion-header.ui-state-active {
    background-color: #ebffff;
    color: #0cc;
    border-color: #0cc;
}
.accordion-teal.accordion-style2 .ui-accordion-content {
    background-color: #ebffff;
    border-color: #0cc;
}
.accordion-dark .ui-accordion-header.ui-state-active {
    color: #596882;
}
.accordion-dark.accordion-style1 .ui-accordion-header.ui-state-active {
    border-color: #596882;
    background-color: #e3e7ed;
}
.accordion-dark.accordion-style1
    .ui-accordion-content.ui-accordion-content-active {
    border-color: #596882;
    background-color: #e3e7ed;
}
.accordion-dark.accordion-style2 .ui-accordion-header {
    background-color: #596882;
}
.accordion-dark.accordion-style2 .ui-accordion-header:hover {
    background-color: #55637c;
}
.accordion-dark.accordion-style2 .ui-accordion-header.ui-state-active {
    background-color: #e3e7ed;
    color: #596882;
    border-color: #596882;
}
.accordion-dark.accordion-style2 .ui-accordion-content {
    background-color: #e3e7ed;
    border-color: #596882;
}
.avatar {
    position: relative;
    width: 38px;
    height: 38px;
}
.avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.avatar-initial {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #8392a5;
    color: #fff;
    font-weight: 700;
    font-size: 15px;
    font-family: -apple-system, BlinkMacSystemFont, inter ui, Roboto, sans-serif;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
}
.avatar-offline::after,
.avatar-online::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 2px;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    box-shadow: 0 0 0 2px #fff;
}
.avatar-offline::after {
    background-color: #c0ccda;
}
.avatar-online::after {
    background-color: var(--green);
}
.avatar-xs {
    width: 26px;
    height: 26px;
}
.avatar-xs .avatar-initial {
    font-size: 10px;
}
.avatar-xs.avatar-offline::after,
.avatar-xs.avatar-online::after {
    width: 5px;
    height: 5px;
}
.avatar-sm {
    width: 32px;
    height: 32px;
}
.avatar-sm .avatar-initial {
    font-size: 13px;
}
.avatar-sm.avatar-offline::after,
.avatar-sm.avatar-online::after {
    width: 7px;
    height: 7px;
}
.avatar-md {
    width: 48px;
    height: 48px;
}
.avatar-md .avatar-initial {
    font-size: 18px;
}
.avatar-md.avatar-offline::after,
.avatar-md.avatar-online::after {
    width: 9px;
    height: 9px;
    right: 2px;
    bottom: 2px;
}
.avatar-lg {
    width: 64px;
    height: 64px;
}
.avatar-lg .avatar-initial {
    font-size: 24px;
}
.avatar-lg.avatar-offline::after,
.avatar-lg.avatar-online::after {
    width: 10px;
    height: 10px;
    bottom: 3px;
    right: 4px;
}
.avatar-xl {
    width: 72px;
    height: 72px;
}
.avatar-xl .avatar-initial {
    font-size: 30px;
}
.avatar-xl.avatar-offline::after,
.avatar-xl.avatar-online::after {
    width: 11px;
    height: 11px;
    bottom: 4px;
    right: 5px;
    box-shadow: 0 0 0 2.5px #fff;
}
.avatar-xxl {
    width: 100px;
    height: 100px;
}
.avatar-xxl .avatar-initial {
    font-size: 45px;
}
.avatar-xxl.avatar-offline::after,
.avatar-xxl.avatar-online::after {
    width: 13px;
    height: 13px;
    bottom: 6px;
    right: 7px;
    box-shadow: 0 0 0 3px #fff;
}
.button-group {
    display: flex;
    align-items: stretch;
    height: 38px;
}
.button-group .btn,
.button-group .sp-container button,
.button-group .ui-datepicker-buttonpane button,
.sp-container .button-group button,
.ui-datepicker-buttonpane .button-group button {
    display: flex;
    align-items: center;
    font-size: inherit;
    font-weight: inherit;
}
.button-group .btn + .btn,
.button-group .sp-container .btn + button,
.button-group .sp-container .ui-datepicker-buttonpane button + button,
.button-group .sp-container button + .btn,
.button-group .sp-container button + button,
.button-group .ui-datepicker-buttonpane .btn + button,
.button-group .ui-datepicker-buttonpane .sp-container button + button,
.button-group .ui-datepicker-buttonpane button + .btn,
.button-group .ui-datepicker-buttonpane button + button,
.sp-container .button-group .btn + button,
.sp-container .button-group .ui-datepicker-buttonpane button + button,
.sp-container .button-group button + .btn,
.sp-container .button-group button + button,
.sp-container .ui-datepicker-buttonpane .button-group button + button,
.ui-datepicker-buttonpane .button-group .btn + button,
.ui-datepicker-buttonpane .button-group .sp-container button + button,
.ui-datepicker-buttonpane .button-group button + .btn,
.ui-datepicker-buttonpane .button-group button + button,
.ui-datepicker-buttonpane .sp-container .button-group button + button {
    margin-left: 5px;
}
.card-profile {
    border-width: 0;
}
.card-profile .card-img-top {
    height: 100px;
    object-fit: cover;
}
.card-profile .card-body {
    padding: 15px;
    border: 1px solid rgba(72, 94, 144, 0.16);
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}
@media (min-width: 992px) {
    .card-profile .card-body {
        padding: 20px;
    }
}
.card-profile .card-body > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: -55px;
}
.card-profile h5 {
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 0;
}
@media (min-width: 992px) {
    .card-profile h5 {
        font-size: 15px;
    }
}
.card-profile h5 a {
    color: #1b2e4b;
}
.card-profile h5 a:focus,
.card-profile h5 a:hover {
    color: var(--primary);
}
.card-profile p {
    color: #8392a5;
}
.card-event {
    border-width: 0;
}
.card-event .card-img-top {
    height: 150px;
    object-fit: cover;
}
.card-event .card-body {
    border: 1px solid rgba(72, 94, 144, 0.16);
    border-top-width: 0;
    padding: 18px 20px;
}
.card-event .card-body h5 {
    font-size: 15px;
    margin-bottom: 0;
}
.card-event .card-body h5 a {
    color: #1b2e4b;
}
.card-event .card-body h5 a:focus,
.card-event .card-body h5 a:hover {
    color: var(--primary);
}
.card-event .card-footer {
    border: 1px solid rgba(72, 94, 144, 0.16);
    border-top-width: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.card-help .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.card-help .card-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.card-help .card-footer .icon::before {
    width: auto;
}
.divider-text {
    position: relative;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #8392a5;
    font-size: 10px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, inter ui, Roboto, sans-serif;
    letter-spacing: 0.5px;
    margin: 15px 0;
}
.divider-text::after,
.divider-text::before {
    content: "";
    display: block;
    flex: 1;
    height: 1px;
    background-color: #e5e9f2;
}
.divider-text::before {
    margin-right: 10px;
}
.divider-text::after {
    margin-left: 10px;
}
.divider-vertical {
    flex-direction: column;
    margin: 0 15px;
}
.divider-vertical::after,
.divider-vertical::before {
    height: auto;
    width: 1px;
}
.divider-vertical::before {
    margin-right: 0;
    margin-bottom: 10px;
}
.divider-vertical::after {
    margin-left: 0;
    margin-top: 10px;
}
.dropdown-message .dropdown-link,
.dropdown-notification .dropdown-link {
    position: relative;
    color: #8392a5;
}
.dropdown-message .dropdown-link svg,
.dropdown-notification .dropdown-link svg {
    width: 20px;
    margin: 0;
    height: 20px;
    stroke-width: 2.25px;
}
@media (min-width: 576px) {
    .dropdown-message .dropdown-link svg,
    .dropdown-notification .dropdown-link svg {
        width: 22px;
        height: 22px;
        stroke-width: 2.25px;
    }
}
.dropdown-message .dropdown-link.new-indicator,
.dropdown-notification .dropdown-link.new-indicator {
    color: #1b2e4b;
}
.dropdown-message .dropdown-link.new-indicator span,
.dropdown-notification .dropdown-link.new-indicator span {
    display: flex;
    width: 15px;
    height: 15px;
    justify-content: center;
    position: absolute;
    top: -8px;
    right: -2px;
    background-color: var(--red);
    color: #fff;
    font-size: 8px;
    font-weight: 400;
    font-family: sans-serif;
    line-height: 2;
    border-radius: 100%;
}
.dropdown-message .dropdown-link:focus,
.dropdown-message .dropdown-link:hover,
.dropdown-notification .dropdown-link:focus,
.dropdown-notification .dropdown-link:hover {
    color: #001737;
}
.dropdown-message .dropdown-menu,
.dropdown-notification .dropdown-menu {
    width: 300px;
    padding: 0;
    margin-right: -20px;
    box-shadow: none;
}
.dropdown-message .dropdown-menu::before,
.dropdown-notification .dropdown-menu::before {
    content: "";
    position: absolute;
    top: -10px;
    left: 25px;
    border-bottom: 10px solid rgba(72, 94, 144, 0.16);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
}
.dropdown-message .dropdown-menu::after,
.dropdown-notification .dropdown-menu::after {
    content: "";
    position: absolute;
    top: -8.5px;
    left: 26px;
    border-bottom: 9px solid #fff;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
}
.dropdown-message .dropdown-menu.dropdown-menu-right,
.dropdown-notification .dropdown-menu.dropdown-menu-right {
    margin-right: -20px;
}
.dropdown-message .dropdown-menu.dropdown-menu-right::before,
.dropdown-notification .dropdown-menu.dropdown-menu-right::before {
    left: auto;
    right: 20px;
}
.dropdown-message .dropdown-menu.dropdown-menu-right::after,
.dropdown-notification .dropdown-menu.dropdown-menu-right::after {
    left: auto;
    right: 21px;
}
.dropdown-message .dropdown-header,
.dropdown-notification .dropdown-header {
    padding: 12px 15px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: #1b2e4b;
    border-bottom: 1.5px solid #f4f7fc;
    margin-bottom: 5px;
}
.dropdown-message .dropdown-item,
.dropdown-notification .dropdown-item {
    transition: none;
    border-radius: 0.25rem;
    white-space: normal;
    padding: 8px 10px;
    margin: 0 5px;
    width: auto;
}
@media (prefers-reduced-motion: reduce) {
    .dropdown-message .dropdown-item,
    .dropdown-notification .dropdown-item {
        transition: none;
    }
}
.dropdown-message .dropdown-item:focus,
.dropdown-message .dropdown-item:hover,
.dropdown-notification .dropdown-item:focus,
.dropdown-notification .dropdown-item:hover {
    background-color: rgba(229, 233, 242, 0.5);
}
.dropdown-message .dropdown-item:focus .avatar-offline::after,
.dropdown-message .dropdown-item:focus .avatar-online::after,
.dropdown-message .dropdown-item:hover .avatar-offline::after,
.dropdown-message .dropdown-item:hover .avatar-online::after,
.dropdown-notification .dropdown-item:focus .avatar-offline::after,
.dropdown-notification .dropdown-item:focus .avatar-online::after,
.dropdown-notification .dropdown-item:hover .avatar-offline::after,
.dropdown-notification .dropdown-item:hover .avatar-online::after {
    box-shadow: 0 0 0 2px #f2f4f8;
}
.dropdown-message .avatar,
.dropdown-notification .avatar {
    flex-shrink: 0;
    margin-top: 2px;
}
.dropdown-message .media-body,
.dropdown-notification .media-body {
    font-size: 13px;
    color: rgba(27, 46, 75, 0.85);
}
.dropdown-message .media-body p,
.dropdown-notification .media-body p {
    margin-bottom: 2px;
}
.dropdown-message .media-body span,
.dropdown-notification .media-body span {
    color: #8392a5;
    font-size: 11px;
    font-family: -apple-system, BlinkMacSystemFont, inter ui, Roboto, sans-serif;
}
.dropdown-message .media-body strong,
.dropdown-notification .media-body strong {
    font-weight: 500;
    color: #1b2e4b;
}
.dropdown-message .dropdown-footer,
.dropdown-notification .dropdown-footer {
    margin-top: 5px;
    border-top: 1.5px solid #f4f7fc;
    padding: 10px;
    text-align: center;
    font-size: 10px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, inter ui, Roboto, sans-serif;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}
.dropdown-message .dropdown-footer a,
.dropdown-notification .dropdown-footer a {
    color: #8392a5;
    transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .dropdown-message .dropdown-footer a,
    .dropdown-notification .dropdown-footer a {
        transition: none;
    }
}
.dropdown-message .dropdown-footer a:focus,
.dropdown-message .dropdown-footer a:hover,
.dropdown-notification .dropdown-footer a:focus,
.dropdown-notification .dropdown-footer a:hover {
    color: #1b2e4b;
}
.dropdown-profile .dropdown-link {
    display: flex;
    align-items: center;
}
.dropdown-profile .dropdown-link:focus .avatar img,
.dropdown-profile .dropdown-link:hover .avatar img {
    transform: scale(1.23, 1.23);
    padding: 3px;
    background-color: #f5f6fa;
}
.dropdown-profile .dropdown-menu {
    border-color: var(--border-light);
    width: 230px;
    padding: 20px;
    margin-top: 14.5px;
    box-shadow: var(--box-shadow);
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    padding-top: 12px;
}
.dropdown-profile .dropdown-menu::before {
    content: "";
    position: absolute;
    top: -10px;
    left: 25px;
    border-bottom: 10px solid rgba(72, 94, 144, 0.16);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
}
.dropdown-profile .dropdown-menu::after {
    content: "";
    position: absolute;
    top: -8.5px;
    left: 26px;
    border-bottom: 9px solid #fff;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
}
.dropdown-profile .dropdown-menu.dropdown-menu-right {
    margin-right: -10px;
}
.dropdown-profile .dropdown-menu.dropdown-menu-right::before {
    left: auto;
    right: 15px;
}
.dropdown-profile .dropdown-menu.dropdown-menu-right::after {
    left: auto;
    right: 16px;
}
.dropdown-profile .dropdown-divider {
    margin: 15px 0;
}
.dropdown-profile .dropdown-item {
    padding: 0;
    display: flex;
    align-items: center;
    color: #1b2e4b;
}
.dropdown-profile .dropdown-item + .dropdown-item {
    margin-top: 10px;
}
.dropdown-profile .dropdown-item svg {
    width: 16px;
    height: 16px;
    margin-right: 12px;
}
.dropdown-profile .dropdown-item:focus,
.dropdown-profile .dropdown-item:hover {
    background-color: transparent;
    color: var(--primary);
}
.dropdown-icon .dropdown-item {
    display: flex;
    align-items: center;
    padding: 5px 10px;
}
.dropdown-icon .dropdown-item svg {
    width: 16px;
    height: 16px;
    margin-right: 10px;
}
.dropdown-icon .dropdown-item span {
    display: block;
    position: relative;
    top: 1.5px;
}
.dropdown-icon .dropdown-divider {
    margin: 10px 15px;
}
.img-group {
    display: flex;
    align-items: center;
    justify-content: center;
}
.img-group .img {
    position: relative;
    background-color: #fff;
    padding: 2px;
}
@media (min-width: 576px) {
    .img-group .img {
        padding: 3px;
    }
}
.img-group .img + .img {
    margin-left: -15px;
}
@media (min-width: 576px) {
    .img-group .img + .img {
        margin-left: -25px;
    }
}
.img-group.img-group-sm .img + .img {
    margin-left: -15px;
}
.link-01 {
    color: #001737;
}
.link-01:focus,
.link-01:hover {
    color: var(--primary);
}
.link-02 {
    color: #1b2e4b;
}
.link-02:focus,
.link-02:hover {
    color: #001737;
}
.link-03 {
    color: #8392a5;
}
.link-03:focus,
.link-03:hover {
    color: #1b2e4b;
}
button.link {
    background: 0 0;
    border: none;
    padding: 0;
}
.dropdown-toggle.no-arrow::after {
    display: none;
}
.hover-primary:focus,
.hover-primary:hover {
    color: var(--primary);
}
.activity {
    margin: 0;
    padding: 0;
    list-style: none;
}
.activity-item {
    display: flex;
    padding: 5px 0 10px;
    position: relative;
    min-height: 70px;
}
.activity-item::before {
    content: "";
    position: absolute;
    top: 50px;
    bottom: 0;
    left: 20px;
    border-left: 2px dotted #c0ccda;
}
.activity-item:last-child {
    padding-bottom: 0;
    min-height: inherit;
}
.activity-icon {
    width: 40px;
    height: 40px;
    background-color: #e5e9f2;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    color: #8392a5;
}
.activity-icon svg {
    width: 20px;
    height: 20px;
}
.activity-body {
    margin-left: 15px;
    flex: 1;
    color: #5c6c7f;
}
.activity-body strong {
    color: #1b2e4b;
    font-weight: 500;
}
.activity-body small {
    font-size: 11px;
}
.marker {
    background-color: #f3f5f9;
    color: #001737;
    padding: 5px 10px;
    font-size: 10px;
    font-weight: 700;
    font-family: -apple-system, BlinkMacSystemFont, inter ui, Roboto, sans-serif;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}
.marker-icon {
    position: relative;
    overflow: hidden;
    display: flex;
    width: 45px;
    height: 45px;
}
.marker-icon::before {
    content: "";
    position: absolute;
    top: -70%;
    left: -70%;
    width: 140%;
    height: 140%;
    background-color: #f3f5f9;
    transform: rotate(45deg);
}
.marker-icon svg {
    width: 16px;
    height: 16px;
    margin-top: calc(30% - 8px);
    margin-left: calc(30% - 8px);
    position: relative;
    z-index: 1;
}
.marker-icon.marker-top-right::before {
    left: auto;
    right: -70%;
}
.marker-icon.marker-top-right svg {
    margin-left: auto;
    margin-right: calc(30% - 8px);
}
.marker-icon.marker-bottom-left::before {
    top: auto;
    bottom: -70%;
}
.marker-icon.marker-bottom-left svg {
    margin-top: auto;
    margin-bottom: calc(30% - 8px);
}
.marker-icon.marker-bottom-right::before {
    top: auto;
    bottom: -70%;
    left: auto;
    right: -70%;
}
.marker-icon.marker-bottom-right svg {
    margin-top: auto;
    margin-left: auto;
    margin-bottom: calc(30% - 8px);
    margin-right: calc(30% - 8px);
}
.marker-ribbon {
    margin-left: -10px;
    box-shadow: 1px 1px 1px rgba(28, 39, 60, 0.16);
}
.marker-ribbon::after {
    content: "";
    position: absolute;
    bottom: -6px;
    left: 0;
    border-bottom: 7px solid #b4bdce;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    transform: rotate(45deg);
}
.marker-ribbon.marker-top-right {
    margin-left: 0;
    margin-right: -10px;
}
.marker-ribbon.marker-top-right::after {
    left: auto;
    right: 0;
    transform: rotate(-45deg);
}
.marker-primary {
    color: #fff;
}
.marker-primary.marker-icon::before {
    background-color: var(--primary);
}
.marker-primary.marker-ribbon {
    background-color: var(--primary);
}
.marker-primary.marker-ribbon::after {
    border-bottom-color: #0148ae;
}
.marker-secondary {
    color: #fff;
}
.marker-secondary.marker-icon::before {
    background-color: #97a3b9;
}
.marker-secondary.marker-ribbon {
    background-color: #97a3b9;
}
.marker-secondary.marker-ribbon::after {
    border-bottom-color: #697b9a;
}
.marker-success {
    color: #fff;
}
.marker-success.marker-icon::before {
    background-color: var(--green);
}
.marker-success.marker-ribbon {
    background-color: var(--green);
}
.marker-success.marker-ribbon::after {
    border-bottom-color: #0a7137;
}
.marker-danger {
    color: #fff;
}
.marker-danger.marker-icon::before {
    background-color: var(--red);
}
.marker-danger.marker-ribbon {
    background-color: var(--red);
}
.marker-danger.marker-ribbon::after {
    border-bottom-color: #a71d2a;
}
.marker-warning {
    color: #fff;
}
.marker-warning.marker-icon::before {
    background-color: var(--orange);
}
.marker-warning.marker-ribbon {
    background-color: var(--orange);
}
.marker-warning.marker-ribbon::after {
    border-bottom-color: #ba8b00;
}
.marker-info {
    color: #fff;
}
.marker-info.marker-icon::before {
    background-color: var(--teal);
}
.marker-info.marker-ribbon {
    background-color: var(--teal);
}
.marker-info.marker-ribbon::after {
    border-bottom-color: #007688;
}
.marker-dark {
    color: #fff;
}
.marker-dark.marker-icon::before {
    background-color: #1c273c;
}
.marker-dark.marker-ribbon {
    background-color: #1c273c;
}
.marker-dark.marker-ribbon::after {
    border-bottom-color: #040508;
}
.modal.animated .modal-dialog {
    transform: translate(0, 0);
}
.modal.effect-scale .modal-dialog {
    transform: scale(0.7);
    opacity: 0;
    transition: all 0.3s;
}
.modal.effect-scale.show .modal-dialog {
    transform: scale(1);
    opacity: 1;
}
.modal.effect-slide-in-right .modal-dialog {
    transform: translateX(20%);
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
}
.modal.effect-slide-in-right.show .modal-dialog {
    transform: translateX(0);
    opacity: 1;
}
.modal.effect-slide-in-bottom .modal-dialog {
    transform: translateY(20%);
    opacity: 0;
    transition: all 0.3s;
}
.modal.effect-slide-in-bottom.show .modal-dialog {
    transform: translateY(0);
    opacity: 1;
}
.modal.effect-newspaper .modal-dialog {
    transform: scale(0) rotate(720deg);
    opacity: 0;
}
.modal.effect-newspaper .modal-dialog,
.modal.effect-newspaper.show ~ .modal-backdrop {
    transition: all 0.5s;
}
.modal.effect-newspaper.show .modal-dialog {
    transform: scale(1) rotate(0);
    opacity: 1;
}
.modal.effect-fall {
    -webkit-perspective: 1300px;
    -moz-perspective: 1300px;
    perspective: 1300px;
}
.modal.effect-fall .modal-dialog {
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transform: translateZ(600px) rotateX(20deg);
    opacity: 0;
}
.modal.effect-fall.show .modal-dialog {
    transition: all 0.3s ease-in;
    transform: translateZ(0) rotateX(0);
    opacity: 1;
}
.modal.effect-flip-horizontal {
    perspective: 1300px;
}
.modal.effect-flip-horizontal .modal-dialog {
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transform: rotateY(-70deg);
    transition: all 0.3s;
    opacity: 0;
}
.modal.effect-flip-horizontal.show .modal-dialog {
    transform: rotateY(0);
    opacity: 1;
}
.modal.effect-flip-vertical {
    perspective: 1300px;
}
.modal.effect-flip-vertical .modal-dialog {
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transform: rotateX(-70deg);
    transition: all 0.3s;
    opacity: 0;
}
.modal.effect-flip-vertical.show .modal-dialog {
    transform: rotateX(0);
    opacity: 1;
}
.modal.effect-super-scaled .modal-dialog {
    transform: scale(2);
    opacity: 0;
    transition: all 0.3s;
}
.modal.effect-super-scaled.show .modal-dialog {
    transform: scale(1);
    opacity: 1;
}
.modal.effect-sign {
    perspective: 1300px;
}
.modal.effect-sign .modal-dialog {
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transform: rotateX(-60deg);
    transform-origin: 50% 0;
    opacity: 0;
    transition: all 0.3s;
}
.modal.effect-sign.show .modal-dialog {
    transform: rotateX(0);
    opacity: 1;
}
.modal.effect-rotate-bottom {
    perspective: 1300px;
}
.modal.effect-rotate-bottom .modal-dialog {
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transform: translateY(100%) rotateX(90deg);
    transform-origin: 0 100%;
    opacity: 0;
    transition: all 0.3s ease-out;
}
.modal.effect-rotate-bottom.show .modal-dialog {
    transform: translateY(0) rotateX(0);
    opacity: 1;
}
.modal.effect-rotate-left {
    perspective: 1300px;
}
.modal.effect-rotate-left .modal-dialog {
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transform: translateZ(100px) translateX(-30%) rotateY(90deg);
    transform-origin: 0 100%;
    opacity: 0;
    transition: all 0.3s;
}
.modal.effect-rotate-left.show .modal-dialog {
    transform: translateZ(0) translateX(0) rotateY(0);
    opacity: 1;
}
.modal.effect-just-me .modal-dialog {
    transform: scale(0.8);
    opacity: 0;
    transition: all 0.3s;
}
.modal.effect-just-me .modal-content,
.modal.effect-just-me .modal-content-main {
    background-color: transparent;
}
.modal.effect-just-me .close {
    text-shadow: none;
    color: #fff;
}
.modal.effect-just-me .modal-header {
    background-color: transparent;
    border-bottom-color: rgba(255, 255, 255, 0.1);
    padding-left: 0;
    padding-right: 0;
}
.modal.effect-just-me .modal-header h6 {
    color: #fff;
    font-weight: 500;
}
.modal.effect-just-me .modal-body {
    color: rgba(255, 255, 255, 0.8);
    padding-left: 0;
    padding-right: 0;
}
.modal.effect-just-me .modal-body h6 {
    color: #fff;
}
.modal.effect-just-me .modal-footer {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
    border-top-color: rgba(255, 255, 255, 0.1);
}
.modal.effect-just-me.show ~ .modal-backdrop {
    opacity: 0.96;
}
.modal.effect-just-me.show .modal-dialog {
    transform: scale(1);
    opacity: 1;
}
.nav-social {
    align-items: center;
}
.nav-social .nav-link {
    padding: 0;
    color: #1b2e4b;
}
.nav-social .nav-link:focus,
.nav-social .nav-link:hover {
    color: var(--primary);
}
.nav-social .nav-link + .nav-link {
    margin-left: 8px;
}
.nav-social .nav-link svg {
    width: 18px;
}
.nav-sidebar {
    flex-direction: column;
}
.nav-sidebar .nav-link {
    padding: 6px 10px;
    display: flex;
    align-items: center;
    color: #1b2e4b;
    border-radius: 0.25rem;
}
.nav-sidebar .nav-link:focus,
.nav-sidebar .nav-link:hover {
    background-color: #f5f6fa;
}
.nav-sidebar .nav-link svg {
    width: 18px;
    height: 18px;
    stroke-width: 2.3px;
    margin-right: 12px;
    color: #566476;
    fill: rgba(27, 46, 75, 0.06);
}
.nav-sidebar .nav-link .badge {
    margin-left: auto;
    color: #c0ccda;
    font-weight: 400;
    font-size: 11px;
}
.nav-sidebar .nav-link.active {
    font-weight: 500;
    color: var(--primary);
    background-color: #eef0f7;
}
.nav-sidebar .nav-link.active svg {
    color: var(--primary);
    fill: rgba(1, 104, 250, 0.2);
}
.nav-sidebar .nav-link + .nav-link {
    margin-top: 2px;
}
.nav-classic {
    flex-direction: column;
}
.nav-classic .nav-link {
    padding: 0;
    display: flex;
    align-items: center;
    color: #1b2e4b;
}
.nav-classic .nav-link:focus,
.nav-classic .nav-link:hover {
    color: var(--primary);
}
.nav-classic .nav-link:focus svg,
.nav-classic .nav-link:hover svg {
    color: var(--primary);
    fill: rgba(1, 104, 250, 0.2);
}
.nav-classic .nav-link svg {
    width: 18px;
    height: 18px;
    stroke-width: 2.3px;
    margin-right: 12px;
    color: #566476;
    fill: rgba(27, 46, 75, 0.06);
}
.nav-classic .nav-link span:not(.badge) {
    display: block;
    transform: translateY(1px);
}
.nav-classic .nav-link .badge {
    margin-left: auto;
    color: #c0ccda;
    font-weight: 400;
    font-size: 11px;
}
.nav-classic .nav-link.active {
    font-weight: 500;
    color: var(--primary);
    background-color: transparent;
}
.nav-classic .nav-link.active svg {
    color: var(--primary);
}
.nav-classic .nav-link + .nav-link {
    margin-top: 10px;
}
.nav-row .nav-link {
    padding: 0;
}
.nav-row .nav-link + .nav-link {
    margin-left: 15px;
}
.nav-icon-only {
    align-items: center;
}
.nav-icon-only .nav-link {
    padding: 0;
    color: #8392a5;
}
.nav-icon-only .nav-link:focus,
.nav-icon-only .nav-link:hover {
    color: #1b2e4b;
}
.nav-icon-only .nav-link + .nav-link {
    margin-left: 10px;
}
.nav-icon-only .nav-link svg {
    width: 18px;
    height: 18px;
}
.nav-icon-only .nav-divider {
    width: 1px;
    background-color: #e5e9f2;
    margin: 0 15px;
}
.nav-icon-only.nav-lg .nav-link + .nav-link {
    margin-left: 15px;
}
.nav-icon-only.nav-lg .nav-link svg {
    width: 28px;
    height: 28px;
}
.nav-with-icon .nav-link {
    padding: 0;
    display: flex;
    align-items: center;
}
.nav-with-icon .nav-link + .nav-link {
    margin-left: 15px;
}
@media (min-width: 576px) {
    .nav-with-icon .nav-link + .nav-link {
        margin-left: 25px;
    }
}
.nav-with-icon svg {
    width: 16px;
    height: 16px;
    stroke-width: 2.5px;
    margin-right: 7px;
    position: relative;
    top: -1px;
}
.nav-row-xs {
    font-size: 11px;
}
.nav-row-xs .nav-link {
    padding: 0;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #8392a5;
}
.nav-row-xs .nav-link.active {
    font-weight: 600;
    color: var(--primary);
}
.nav-row-xs .nav-link + .nav-link {
    margin-left: 10px;
}
.nav-wrapper {
    overflow: hidden;
    height: 40px;
}
.nav-wrapper > div {
    height: 60px;
    overflow-x: auto;
    padding-right: 20px;
}
@media (min-width: 992px) {
    .nav-wrapper > div {
        padding-right: 0;
    }
}
.nav-wrapper .nav {
    flex-wrap: nowrap;
    white-space: nowrap;
}
.overlay {
    position: relative;
}
.overlay-body {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
@keyframes shimmer {
    0% {
        background-position: -400px 0;
    }
    100% {
        background-position: 400px 0;
    }
}
.nav-tabs {
    border-color: rgba(72, 94, 144, 0.16);
}
.nav-tabs .nav-item {
    margin-bottom: 0;
}
.nav-tabs .nav-item + .nav-item {
    margin-left: 5px;
}
.nav-tabs .nav-link {
    background-color: rgba(72, 94, 144, 0.16);
    border-color: rgba(72, 94, 144, 0.16);
    color: #596882;
    border-bottom-width: 0;
    position: relative;
    margin-bottom: -1px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    transition: background-color 0.2s;
}
@media (prefers-reduced-motion: reduce) {
    .nav-tabs .nav-link {
        transition: none;
    }
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    background-color: rgba(81, 105, 161, 0.16);
    color: #1c273c;
}
.nav-tabs .nav-link.active {
    background-color: #fff;
    z-index: 5;
    color: var(--primary);
}
.nav-tabs .nav-link.disabled {
    background-color: transparent;
    color: #b4bdce;
}
.nav-tabs.flex-column {
    border-bottom: 0;
    border-right: 1px solid rgba(72, 94, 144, 0.16);
    justify-content: flex-start;
}
.nav-tabs.flex-column .nav-item + .nav-item {
    margin-left: 0;
    margin-top: 5px;
}
.nav-tabs.flex-column .nav-item .nav-link {
    border: 1px solid rgba(72, 94, 144, 0.16);
    border-right-width: 0;
    margin-bottom: 0;
    margin-right: -1px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}
.nav-line {
    border-bottom: 2px solid rgba(72, 94, 144, 0.16);
}
.nav-line .nav-item + .nav-item {
    margin-left: 25px;
}
.nav-line > .nav-link + .nav-link {
    margin-left: 25px;
}
.nav-line .nav-link {
    padding: 8px 0;
}
.nav-line .nav-link.active {
    position: relative;
}
.nav-line .nav-link.active::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: var(--primary);
}
.nav-line.flex-column {
    border-bottom: 0;
    border-right: 2px solid rgba(72, 94, 144, 0.16);
}
.nav-line.flex-column .nav-item + .nav-item {
    margin-left: 0;
    margin-top: 25px;
}
.nav-line.flex-column > .nav-link + .nav-link {
    margin-top: 25px;
    margin-left: 0;
}
.nav-line.flex-column .nav-link {
    padding: 0 15px 0 25px;
}
.nav-line.flex-column .nav-link.active::after {
    top: 0;
    bottom: 0;
    width: 2px;
    height: auto;
    left: auto;
    right: -2px;
}
.legendColorBox > div {
    border: 0 !important;
    padding: 0 !important;
    border-radius: 100%;
    overflow: hidden;
}
.legendLabel {
    font-size: 10px;
    font-weight: 500;
    color: #8392a5;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    text-transform: uppercase;
    padding-left: 5px;
}
.irs--flat .irs-max,
.irs--flat .irs-min {
    color: #8392a5;
    background-color: #e5e9f2;
    border-radius: 0.25rem;
}
.irs--flat .irs-from,
.irs--flat .irs-single,
.irs--flat .irs-to {
    background-color: var(--primary);
    border-radius: 0.25rem;
}
.irs--flat .irs-from::before,
.irs--flat .irs-single::before,
.irs--flat .irs-to::before {
    border-top-color: var(--primary);
}
.irs--flat .irs-line {
    background-color: rgba(72, 94, 144, 0.16);
    border-radius: 0.25rem;
}
.irs--flat .irs-bar {
    background-color: var(--primary);
}
.irs--flat .irs-bar--single {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}
.irs--flat .irs-handle > i:first-child {
    background-color: #015de1;
}
.irs--flat .irs-handle:active,
.irs--flat .irs-handle:focus,
.irs--flat .irs-handle:hover {
    cursor: w-resize;
}
.irs--flat .irs-handle:active > i:first-child,
.irs--flat .irs-handle:focus > i:first-child,
.irs--flat .irs-handle:hover > i:first-child {
    background-color: #0159d6;
}
.irs--flat .irs-handle.state_hover:active > i:first-child,
.irs--flat .irs-handle.state_hover:focus > i:first-child,
.irs--flat .irs-handle.state_hover:hover > i:first-child,
.irs--flat .irs-handle.state_hover > i:first-child {
    background-color: #0159d6;
}
.irs--flat .irs-grid-text {
    color: #8392a5;
}
.irs--flat .irs-grid-pol {
    background-color: rgba(72, 94, 144, 0.16);
}
.irs--round .irs-from,
.irs--round .irs-max,
.irs--round .irs-min,
.irs--round .irs-to {
    font-size: 10px;
    border-radius: 0.25rem;
}
.irs--round .irs-max,
.irs--round .irs-min {
    background-color: #e5e9f2;
    color: #8392a5;
}
.irs--round .irs-grid-text {
    font-size: 10px;
    color: #8392a5;
}
.irs--round .irs-grid-pol {
    background-color: rgba(72, 94, 144, 0.16);
}
.irs--square .irs-grid-pol,
.irs--square .irs-line {
    background-color: #e5e9f2;
}
.irs--square .irs-max,
.irs--square .irs-min {
    background-color: #e5e9f2;
    font-size: 10px;
    color: #8392a5;
}
.irs--square .irs-grid-text {
    font-size: 10px;
    color: #8392a5;
}
.irs--square .irs-from,
.irs--square .irs-to {
    background-color: #1b2e4b;
    font-size: 10px;
}
.irs--square .irs-handle {
    border-color: #1b2e4b;
}
.irs--square .irs-bar {
    background-color: #1b2e4b;
}
.select2-results__option {
    border-radius: 0;
    margin-bottom: 1px;
    font-size: inherit;
}
.select2-container--default .select2-selection--single {
    background-color: #fff;
    border-color: #c0ccda;
    border-radius: 0.25rem;
    height: 38px;
    outline: 0;
}
.select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    color: #596882;
    line-height: calc(1.5em + 0.9375rem + 2px) -0.1rem;
    padding-left: 0.625rem;
    height: 100%;
    display: flex;
    align-items: center;
}
.select2-container--default
    .select2-selection--single
    .select2-selection__placeholder {
    color: var(--secondary);
}
.select2-container--default
    .select2-selection--single
    .select2-selection__arrow {
    width: 30px;
    height: 38px;
    line-height: calc(1.5em + 0.9375rem + 2px);
}
.select2-container--default
    .select2-selection--single
    .select2-selection__arrow
    b {
    margin-top: -3px;
}
.select2-container--default
    .select2-selection--single
    .select2-selection__clear {
    font-size: 20px;
    font-weight: 400;
    order: 2;
    margin-left: auto;
    margin-right: 5px;
    color: #8392a5;
    position: relative;
    z-index: 100;
}
.select2-container--default
    .select2-selection--single
    .select2-selection__clear:hover {
    color: #1b2e4b;
}
.select2-container--default .select2-selection--multiple {
    background-color: #fff;
    border-color: #c0ccda;
    border-radius: 2px;
    min-height: 38px;
    outline: 0;
}
.select2-container--default
    .select2-selection--multiple
    .select2-selection__rendered {
    padding: 0 4px;
    display: block;
}
.select2-container--default
    .select2-selection--multiple
    .select2-selection__choice {
    position: relative;
    margin-top: 4px;
    margin-right: 4px;
    padding: 3px 10px 3px 20px !important;
    border-color: transparent;
    border-radius: 1px;
    background-color: var(--primary);
    color: #fff;
    font-size: 13px;
    line-height: 1.45;
}
.select2-container--default
    .select2-selection--multiple
    .select2-selection__choice__remove {
    color: #fff;
    opacity: 0.75;
    font-size: 15px;
    font-weight: 400;
    display: inline-block;
    position: absolute;
    top: 3px;
    left: 7px;
    line-height: 1.2;
}
.select2-container--default.select2-container--focus
    .select2-selection--multiple {
    border-color: #c0ccda;
    display: inline-table;
    width: 100%;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
    border-color: #c0ccda;
    border-radius: 0;
    padding: 5px 10px;
}
.select2-container--default
    .select2-search--dropdown
    .select2-search__field::placeholder {
    color: #8392a5;
}
.select2-container--default .select2-results__option[aria-selected="true"] {
    background-color: #fff;
}
.select2-container--default
    .select2-results__option--highlighted[aria-selected] {
    background-color: var(--primary);
}
.select2-container--default .select2-results > .select2-results__options {
    margin: 4px;
}
.select2-container--default .select2-search--inline .select2-search__field {
    margin-top: 6px;
    line-height: 26px;
    padding-left: 10px;
    opacity: 1;
}
.select2-container--default
    .select2-search--inline
    .select2-search__field::placeholder {
    color: #8392a5;
    font-weight: 400;
    opacity: 1;
}
.select2-container--default.select2-container--disabled
    .select2-selection--single {
    background-color: #f5f6fa;
}
.select2-container--default.select2-container--disabled
    .select2-selection__choice {
    padding-left: 10px;
    background-color: #97a3b9;
}
.select2-container--default.select2-container--disabled
    .select2-selection__choice
    .select2-selection__choice__remove {
    display: none;
}
.select2-container--open {
    font-size: 14px;
}
.select2-container--open .select2-selection--multiple,
.select2-container--open .select2-selection--single {
    background-color: #fff;
    border-color: #c0ccda;
}
.select2-container--open .select2-dropdown--above {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.select2-container--open .select2-dropdown--below {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    top: 0;
}
.select2-dropdown {
    border-color: #c0ccda;
    z-index: 200;
}
.select2-search--dropdown {
    padding-bottom: 0;
}
.select2-results__option {
    padding: 6px 10px;
    font-size: inherit;
}
.has-success .select2-container--default .select2-selection--single {
    border-color: var(--green);
}
.has-warning .select2-container--default .select2-selection--single {
    border-color: var(--orange);
}
.has-danger .select2-container--default .select2-selection--single {
    border-color: var(--red);
}
.select2-xs + .select2-container {
    font-size: 12px;
}
.select2-dropdown-xs .select2-results__option {
    font-size: 12px;
}
.select2-sm + .select2-container {
    font-size: 14px;
}
.select2-dropdown-sm .select2-results__option {
    font-size: 14px;
}
.select2-bd-0 + .select2-container--default .select2-selection--single {
    border-width: 0;
}
.bg-gray + .select2-container--default .select2-selection--single {
    background-color: #455473;
}
.bg-gray
    + .select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    color: #cdd4e0;
}
.sp-container {
    background-color: #fff;
    border-color: rgba(72, 94, 144, 0.16);
    z-index: 200;
}
.sp-container button {
    border: 0;
    padding: 8px 15px;
    background-image: none;
    background-color: #b4bdce;
    font-size: 12px;
    text-shadow: none;
    text-transform: capitalize;
    border-radius: 2px;
}
.sp-container button:focus,
.sp-container button:hover {
    border: 0;
    background-image: none;
    background-color: #97a3b9;
    text-shadow: none;
}
.sp-button-container .sp-cancel {
    text-transform: capitalize;
    color: var(--secondary) !important;
    float: left;
    font-size: 13px;
    margin-top: 3px;
}
.sp-button-container .sp-cancel:focus,
.sp-button-container .sp-cancel:hover {
    color: #3b4863 !important;
    text-decoration: none;
}
.sp-picker-container {
    border-left: 0;
}
.sp-replacer {
    border-color: rgba(72, 94, 144, 0.16);
    background-color: #fff;
}
.sp-replacer:focus,
.sp-replacer:hover {
    border-color: #b4bdce;
}
.sp-replacer {
    border-color: #b4bdce;
    border-radius: 4px;
    box-shadow: var(--box-shadow);
    border: var(--border-light);
    background: #fff;
    border-color: #c0ccda;
    padding: 7px;
}
.sp-dd {
    text-indent: -99999px;
    position: relative;
    width: 10px;
}
.sp-dd::before {
    border-color: #888 transparent transparent transparent !important;
    margin-top: 7px;
    right: 0;
    position: absolute;
    display: inline-block;
    margin-left: 6px;
    vertical-align: 0.1em;
    content: "";
    border-top: 0.4em solid;
    border-right: 0.4em solid transparent;
    border-bottom: 0;
    border-left: 0.4em solid transparent;
}
.sp-preview {
    border: 0;
}
.sp-dragger {
    background-color: transparent;
}
.sp-choose {
    background-color: #cdd4e0;
}
.sp-palette .sp-thumb-el.sp-thumb-active,
.sp-palette .sp-thumb-el:hover {
    border-color: #3b4863;
}
.bootstrap-tagsinput {
    display: block;
    padding: 5px;
    box-shadow: none;
    border-color: #c0ccda;
    border-radius: 0.25rem;
}
.bootstrap-tagsinput .tag {
    display: inline-flex;
    align-items: center;
    background-color: var(--primary);
    color: #fff;
    padding: 2px 7px;
    border-radius: 1px;
}
.bootstrap-tagsinput .tag [data-role="remove"] {
    display: inline-block;
    height: 16px;
    width: 10px;
    position: relative;
    opacity: 0.5;
}
.bootstrap-tagsinput .tag [data-role="remove"]::after {
    content: "\f406";
    font-family: ionicons;
    font-size: 18px;
    position: absolute;
    top: calc(50% + 0.5px);
    line-height: 0;
}
.bootstrap-tagsinput .tag [data-role="remove"]:focus,
.bootstrap-tagsinput .tag [data-role="remove"]:hover {
    opacity: 1;
    box-shadow: none;
}
.bootstrap-tagsinput .tt-menu {
    margin-top: 7px;
    margin-left: -10px;
}
.tt-menu {
    min-width: 150px;
    background-color: #fff;
    border: 1px solid rgba(72, 94, 144, 0.16);
    padding: 5px;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    box-shadow: 0 6px 8px 2px rgba(28, 39, 60, 0.04);
}
.tt-suggestion {
    padding: 2px 10px;
}
.tt-suggestion:focus,
.tt-suggestion:hover {
    cursor: pointer;
    background-color: var(--primary);
    color: #fff;
}
.backdrop {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(15, 21, 32, 0.7);
    z-index: 40;
    transition: all 0.4s;
}
@media (prefers-reduced-motion: reduce) {
    .backdrop {
        transition: none;
    }
}
.backdrop.show {
    visibility: visible;
    opacity: 1;
}
@media (max-width: 991.98px) {
    .inner-navbar {
        margin: 0 !important;
        width: 100%;
    }
    .navbar-nav-show {
        overflow: hidden;
    }
    .navbar-nav-show .navbar-menu-wrapper {
        transform: translateX(0);
    }
    .navbar-nav-show .backdrop {
        opacity: 1;
        visibility: visible;
        left: 260px;
    }
}
.navbar-header {
    align-items: stretch;
    height: 55px;
    padding: 0;
    justify-content: flex-start;
    z-index: 999;
    width: 100%;
    background: 0 0;
}
.navbar-header > .container {
    padding: 0;
}
@media (max-width: 1139px) {
    .navbar-header > .container {
        max-width: none;
    }
}
@media (min-width: 1140px) {
    .navbar-header > .container .navbar-brand {
        padding-left: 0;
    }
    .navbar-header > .container .navbar-right {
        padding-right: 0;
    }
}
.navbar-header .navbar-brand {
    display: flex;
    align-items: center;
    padding-left: 0;
    margin-right: 0;
    order: 1;
}
@media (min-width: 992px) {
    .navbar-header .navbar-brand {
        width: unset;
        padding: 0 0 0 20px;
        margin-top: -3px;
    }
}
.navbar-header .burger-menu {
    padding: 0 10px 0 15px;
    color: #8392a5;
    display: flex;
    align-items: center;
}
@media (min-width: 576px) {
    .navbar-header .burger-menu {
        margin-left: 20px;
    }
}
@media (min-width: 992px) {
    .navbar-header .burger-menu {
        display: none;
    }
}
.navbar-header .burger-menu svg {
    width: 28px;
    height: 28px;
    stroke-width: 2px;
}
.navbar-header .burger-menu:hover {
    color: var(--primary);
}
.navbar-header .btn-social {
    padding: 0;
    font-size: 18px;
    color: #1b2e4b;
    min-height: inherit;
    display: none;
}
@media (min-width: 1200px) {
    .navbar-header .btn-social {
        display: inline-block;
    }
}
.navbar-header .btn-social:focus,
.navbar-header .btn-social:hover {
    color: #001737;
}
.navbar-header .btn-social:focus:first-of-type,
.navbar-header .btn-social:hover:first-of-type {
    color: #f10075;
}
.navbar-header .btn-social:focus:nth-of-type(3),
.navbar-header .btn-social:hover:nth-of-type(3) {
    color: #00aced;
}
.navbar-header .btn-social + .btn-social {
    margin-left: 10px;
}
.navbar-header .btn-social.btn-for-dark {
    color: rgba(255, 255, 255, 0.5);
}
.navbar-header .btn-social.btn-for-dark:focus,
.navbar-header .btn-social.btn-for-dark:hover {
    color: #fff;
}
.navbar-header .btn-buy {
    letter-spacing: 1px;
    padding: 0;
    line-height: 1.454;
    margin-left: 15px;
    display: flex;
    align-items: center;
    color: var(--primary);
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
}
@media (min-width: 992px) {
    .navbar-header .btn-buy {
        min-height: inherit;
        padding: 7px 12px;
        color: var(--primary);
        border: 2px solid var(--primary);
    }
    .navbar-header .btn-buy:focus,
    .navbar-header .btn-buy:hover {
        background-color: var(--primary);
        color: #fff;
    }
}
.navbar-header .btn-buy .feather {
    width: 18px;
    height: 18px;
    margin-right: 0;
}
@media (min-width: 992px) {
    .navbar-header .btn-buy .feather {
        width: 14px;
        height: 14px;
    }
}
.navbar-header .btn-buy span {
    margin-left: 8px;
    display: none;
}
@media (min-width: 992px) {
    .navbar-header .btn-buy span {
        display: inline;
    }
}
.navbar-header .btn-buy.btn-for-dark {
    border-color: #fff;
    color: #fff;
}
.navbar-header .navbar-right {
    padding: 0 15px;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    order: 2;
}
@media (min-width: 576px) {
    .navbar-header .navbar-right {
        padding-right: 20px;
    }
}
@media (min-width: 992px) {
    .navbar-header .navbar-right {
        order: 3;
        padding: 0 20px 0 0;
    }
}
@media (min-width: 1200px) {
    .navbar-header .navbar-right {
        width: unset;
    }
}
.navbar-header .navbar-right .search-link {
    color: #001737;
}
.navbar-header .navbar-right .search-link:focus,
.navbar-header .navbar-right .search-link:hover {
    color: rgba(0, 23, 55, 0.8);
}
.navbar-header .navbar-right .search-link svg {
    width: 16px;
    height: 16px;
    stroke-width: 3px;
}
@media (min-width: 576px) {
    .navbar-header .navbar-right .search-link svg {
        width: 20px;
        height: 20px;
        stroke-width: 2.8px;
    }
}
.navbar-header .navbar-right .dropdown {
    margin-left: 8px;
    margin-top: -1px;
}
.navbar-header .navbar-right .dropdown:last-child {
    margin-left: 15px;
}
@media (min-width: 992px) {
    .navbar-header .navbar-right .dropdown:last-child {
        margin-left: 20px;
    }
}
@media (min-width: 1200px) {
    .navbar-header .navbar-right .dropdown:last-child {
        margin-left: 22px;
    }
}
.navbar-header .navbar-right .dropdown-message .dropdown-menu {
    margin-top: 17.5px;
    margin-right: -88px;
}
.navbar-header .navbar-right .dropdown-message .dropdown-menu::before {
    right: 83px;
}
.navbar-header .navbar-right .dropdown-message .dropdown-menu::after {
    right: 84px;
}
@media (min-width: 576px) {
    .navbar-header .navbar-right .dropdown-message .dropdown-menu {
        margin-right: -20px;
    }
    .navbar-header .navbar-right .dropdown-message .dropdown-menu::before {
        right: 20px;
    }
    .navbar-header .navbar-right .dropdown-message .dropdown-menu::after {
        right: 21px;
    }
}
@media (min-width: 992px) {
    .navbar-header .navbar-right .dropdown-message .dropdown-menu {
        margin-top: 19.5px;
    }
}
.navbar-header .navbar-right .dropdown-notification .dropdown-menu {
    margin-top: 18.5px;
    margin-right: -115px;
}
.navbar-header .navbar-right .dropdown-notification .dropdown-menu::before {
    right: 115px;
}
.navbar-header .navbar-right .dropdown-notification .dropdown-menu::after {
    right: 116px;
}
@media (min-width: 576px) {
    .navbar-header .navbar-right .dropdown-notification .dropdown-menu {
        margin-right: -20px;
    }
    .navbar-header .navbar-right .dropdown-notification .dropdown-menu::before {
        right: 20px;
    }
    .navbar-header .navbar-right .dropdown-notification .dropdown-menu::after {
        right: 21px;
    }
}
@media (min-width: 992px) {
    .navbar-header .navbar-right .dropdown-notification .dropdown-menu {
        margin-top: 19.5px;
    }
}
@media (max-width: 991.98px) {
    .navbar-header .navbar-right .dropdown-profile .dropdown-link .avatar {
        width: 30px;
        height: 30px;
    }
}
@media (max-width: 991.98px) {
    .navbar-header .navbar-right .dropdown-profile .dropdown-menu {
        margin-top: 14px;
    }
}
@media (min-width: 576px) {
    .navbar-header-fixed {
        position: fixed;
        top: 0;
        left: 1rem;
        right: 1rem;
        z-index: 1000;
        background: var(--body-background);
    }
}
.navbar-menu-wrapper {
    order: 3;
    width: 260px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    overflow: hidden;
    z-index: 1060;
    transform: translateX(-260px);
    transition: all 0.2s ease;
    box-shadow: var(--box-shadow);
}
@media (prefers-reduced-motion: reduce) {
    .navbar-menu-wrapper {
        transition: none;
    }
}
@media (min-width: 992px) {
    .navbar-menu-wrapper {
        width: auto;
        padding: 0 25px;
        position: relative;
        top: auto;
        left: auto;
        bottom: auto;
        background-color: transparent;
        overflow: visible;
        display: flex;
        justify-content: center;
        order: 2;
        transform: none;
        z-index: auto;
        flex: 1;
        box-shadow: none;
    }
}
@media (min-width: 1200px) {
    .navbar-menu-wrapper {
        padding: 0 30px;
    }
}
.navbar-menu-wrapper > .ps__rail-y {
    display: none;
}
.navbar-menu-header {
    height: 55px;
    padding: 0 15px 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: var(--border-light);
}
@media (min-width: 992px) {
    .navbar-menu-header {
        display: none;
    }
}
.navbar-menu-header .feather-x {
    width: 25px;
    height: 25px;
    stroke-width: 2.5px;
}
.navbar-menu-header a:last-child {
    color: var(--secondary);
}
.navbar-menu-header a:last-child:focus,
.navbar-menu-header a:last-child:hover {
    color: #1c273c;
}
.navbar-menu {
    flex-direction: column;
    padding: 25px 0;
}
@media (min-width: 992px) {
    .navbar-menu {
        align-items: center;
        flex-direction: row;
        padding: 0;
        width: 100%;
        max-width: 780px;
        margin-top: -3px;
        justify-content: center;
    }
}
.navbar-menu .nav-item + .nav-item {
    margin-top: 10px;
}
@media (min-width: 992px) {
    .navbar-menu .nav-item + .nav-item {
        margin-top: 0;
        margin-left: 22px;
    }
}
@media (min-width: 1200px) {
    .navbar-menu .nav-item + .nav-item {
        margin-left: 22px;
    }
}
.nav-sub-link.active,
.navbar-menu .nav-item.active .nav-link {
    position: relative;
    color: var(--primary) !important;
}
.navbar-menu .nav-item.active .nav-link::before {
    content: "";
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
    width: 10px;
    height: 4px;
    background-color: var(--primary);
}
@media (min-width: 992px) {
    .navbar-menu .nav-item.active .nav-link::before {
        display: none;
    }
}
.navbar-menu .nav-item.active .nav-link svg {
    color: var(--primary);
    fill: var(--primary-light);
}
.navbar-menu .nav-item.active.with-sub .nav-link::after {
    border-color: var(--primary);
}
.navbar-menu .nav-item.with-sub {
    position: relative;
}
.navbar-menu .nav-item.with-sub .nav-link::after {
    content: "";
    width: 6px;
    height: 6px;
    border-right: 1.5px solid #8392a5;
    border-bottom: 1.5px solid #8392a5;
    display: inline-block;
    transform: rotate(45deg);
    margin-left: auto;
    transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .navbar-menu .nav-item.with-sub .nav-link::after {
        transition: none;
    }
}
@media (min-width: 992px) {
    .navbar-menu .nav-item.with-sub .nav-link::after {
        margin-left: 6px;
    }
}
.navbar-menu .nav-item.show .navbar-menu-sub {
    display: block;
}
.navbar-menu .nav-link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 15px;
    font-weight: 500;
    padding: 0 20px;
    color: #001737;
    transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .navbar-menu .nav-link {
        transition: none;
    }
}
@media (min-width: 992px) {
    .navbar-menu .nav-link {
        font-weight: 500;
        padding: 0;
    }
}
.navbar-menu .nav-link:hover {
    color: var(--primary);
}
.navbar-menu .nav-link:hover svg {
    color: var(--primary);
    fill: var(--primary-light);
}
.navbar-menu .nav-link svg {
    width: 17px;
    height: 18px;
    stroke-width: 2.2px;
    margin-right: 19px;
    color: var(--primary);
    transition: all 0.2s ease-in-out;
    margin-top: 2px;
}
@media (prefers-reduced-motion: reduce) {
    .navbar-menu .nav-link svg {
        transition: none;
    }
}
@media (min-width: 992px) {
    .navbar-menu .nav-link svg {
        color: var(--secondary);
        margin-right: 8px;
    }
}
.navbar-menu .nav-label {
    font-size: 10px;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, inter ui, Roboto, sans-serif;
    letter-spacing: 0.7px;
    color: #8392a5;
    text-transform: uppercase;
    display: block;
    margin-bottom: 15px;
}
.navbar-menu-sub {
    position: relative;
    min-width: 200px;
    list-style: none;
    padding: 0 15px 3px 24px;
    border-left: 1px solid rgba(72, 94, 144, 0.16);
    margin-top: 10px;
    margin-left: 28px;
    z-index: 1000;
    display: none;
}
@media (min-width: 992px) {
    .navbar-menu-sub {
        position: absolute;
        top: 41.5px;
        left: -25px;
        background-color: #fff;
        padding: 15px 20px;
        margin-top: 0;
        margin-left: 0;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        box-shadow: var(--box-shadow);
        border: var(--border-light);
    }
}
.navbar-menu-sub::before {
    content: "";
    position: absolute;
    top: -10px;
    left: 25px;
    border-bottom: 10px solid rgba(192, 204, 218, 0.53);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
}
.navbar-menu-sub::after {
    content: "";
    position: absolute;
    top: -8.5px;
    left: 26px;
    border-bottom: 9px solid #fff;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
}
.navbar-menu-sub::after,
.navbar-menu-sub::before {
    display: none;
}
@media (min-width: 992px) {
    .navbar-menu-sub::after,
    .navbar-menu-sub::before {
        display: block;
    }
}
.navbar-menu-sub ul {
    padding: 0;
    margin: 0;
    list-style: none;
    position: relative;
    min-width: 150px;
}
.navbar-menu-sub ul + ul {
    margin-top: 25px;
}
@media (min-width: 992px) {
    .navbar-menu-sub ul + ul {
        margin-top: 0;
        margin-left: 25px;
        padding-left: 25px;
        border-left: 1px solid rgba(72, 94, 144, 0.16);
    }
}
.navbar-menu-sub .nav-sub-item + .nav-sub-item {
    margin-top: 5px;
}
.navbar-menu-sub .nav-sub-link {
    color: #737f95;
    white-space: nowrap;
    font-size: 14px;
    display: flex;
    align-items: center;
    transition: all 0.2s ease-in-out;
    font-weight: 500;
    padding: 4px 0;
}
@media (prefers-reduced-motion: reduce) {
    .navbar-menu-sub .nav-sub-link {
        transition: none;
    }
}
.navbar-menu-sub .nav-sub-link svg {
    width: 16px;
    height: 16px;
    margin-right: 15px;
    fill: rgba(0, 23, 55, 0.08);
    display: none;
}
@media (min-width: 992px) {
    .navbar-menu-sub .nav-sub-link svg {
        display: block;
    }
}
.navbar-menu-sub .nav-sub-link .feather-bar-chart-2 {
    margin-left: -4px;
}
.navbar-menu-sub .nav-sub-link:hover {
    color: var(--primary);
}
.navbar-menu-sub .nav-sub-link:hover svg {
    fill: var(--primary-light);
}
.navbar-menu-sub.navbar-inverse {
    background-color: #141c2b;
    border-width: 0;
}
.navbar-menu-sub.navbar-inverse::before {
    border-bottom-color: #141c2b;
}
.navbar-menu-sub.navbar-inverse::after {
    display: none;
}
.navbar-menu-sub.navbar-inverse .nav-label {
    color: rgba(255, 255, 255, 0.3);
    font-weight: 500;
}
.navbar-menu-sub.navbar-inverse ul + ul {
    border-left-color: rgba(255, 255, 255, 0.05);
}
.navbar-menu-sub.navbar-inverse .nav-sub-link {
    color: rgba(255, 255, 255, 0.5);
    font-weight: 400;
}
.navbar-menu-sub.navbar-inverse .nav-sub-link:focus,
.navbar-menu-sub.navbar-inverse .nav-sub-link:hover {
    color: #fff;
}
.navbar-menu-sub.navbar-inverse .nav-sub-link:focus .feather,
.navbar-menu-sub.navbar-inverse .nav-sub-link:hover .feather {
    color: #fff;
}
.navbar-search {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .navbar-search {
        transition: none;
    }
}
.navbar-search.visible {
    visibility: visible;
    opacity: 1;
}
.navbar-search-header {
    height: 55px;
    background-color: #fff;
    padding: 0 15px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(72, 94, 144, 0.16);
}
@media (min-width: 576px) {
    .navbar-search-header {
        padding: 0 20px;
        height: 60px;
    }
}
@media (min-width: 992px) {
    .navbar-search-header {
        padding: 0 25px;
    }
}
.navbar-search-header .form-control {
    padding: 0;
    font-size: 14px;
    font-weight: 500;
    color: #001737;
    border-width: 0;
    background-color: transparent;
}
@media (min-width: 992px) {
    .navbar-search-header .form-control {
        font-size: 16px;
    }
}
.navbar-search-header .form-control::placeholder {
    font-weight: 400;
}
.navbar-search-header .form-control:active,
.navbar-search-header .form-control:focus {
    box-shadow: none !important;
}
.navbar-search-header .btn,
.navbar-search-header .sp-container button,
.navbar-search-header .ui-datepicker-buttonpane button,
.sp-container .navbar-search-header button,
.ui-datepicker-buttonpane .navbar-search-header button {
    padding: 0;
}
.navbar-search-header .btn svg,
.navbar-search-header .sp-container button svg,
.navbar-search-header .ui-datepicker-buttonpane button svg,
.sp-container .navbar-search-header button svg,
.ui-datepicker-buttonpane .navbar-search-header button svg {
    width: 20px;
    height: 20px;
    stroke-width: 2.8px;
}
.navbar-search-body {
    padding: 15px 15px 20px;
    background-color: #fff;
    font-size: 13px;
}
@media (min-width: 992px) {
    .navbar-search-body {
        padding: 25px 25px 30px;
    }
}
.navbar-search-body ul {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
}
@media (min-width: 992px) {
    .navbar-search-body ul {
        flex-direction: row;
    }
}
.navbar-search-body li + li {
    margin-top: 2px;
}
@media (min-width: 992px) {
    .navbar-search-body li + li {
        margin-top: 0;
        margin-left: 5px;
    }
}
.navbar-search-body a {
    padding: 5px 10px;
    display: block;
    color: #1b2e4b;
    border-radius: 0.25rem;
}
@media (min-width: 992px) {
    .navbar-search-body a {
        border: 1px solid rgba(72, 94, 144, 0.16);
    }
}
.navbar-search-body a:focus,
.navbar-search-body a:hover {
    background-color: #e5e9f2;
}
@media (min-width: 992px) {
    .navbar-search-body a:focus,
    .navbar-search-body a:hover {
        background-color: #c0ccda;
        border-color: transparent;
    }
}
.pq-logo {
    font-weight: 700;
    font-size: 24px;
    letter-spacing: -1px;
    color: inherit;
    display: flex;
    align-items: center;
    position: relative;
    color: #031a61;
    cursor: pointer;
    margin-top: -2px;
}
@media (min-width: 576px) {
    .pq-logo {
        font-size: 24px;
    }
}
@media (min-width: 992px) {
    .pq-logo {
        font-size: 24px;
    }
}
.pq-logo:focus,
.pq-logo:hover {
    color: #03217a;
}
.pq-logo span {
    display: inline-block;
    font-weight: 400;
    color: var(--primary);
}
.pq-logo-initial {
    width: 40px;
    height: 40px;
    border: 2px solid var(--primary);
    color: var(--primary);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pq-logo-initial p {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -2px;
    margin-bottom: 0;
}
@media (max-width: 991.98px) {
    .sidebar-show {
        overflow: hidden;
        position: relative;
    }
    .sidebar-show .sidebar {
        left: 0;
        box-shadow: 0 0 50px rgba(28, 39, 60, 0.06);
    }
    .sidebar-show .off-canvas-pusher {
        position: static;
    }
    .sidebar-show .off-canvas {
        display: none;
    }
    .sidebar-show .backdrop {
        opacity: 1;
        visibility: visible;
        left: 260px;
    }
}
.sidebar {
    width: 260px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: -260px;
    z-index: 1050;
    overflow: hidden;
    transition: all 0.4s;
}
@media (prefers-reduced-motion: reduce) {
    .sidebar {
        transition: none;
    }
}
@media (min-width: 992px) {
    .sidebar {
        width: 240px;
        position: relative;
        top: auto;
        bottom: auto;
        left: auto;
    }
}
.sidebar-fixed {
    background-color: #fff;
}
@media (min-width: 992px) {
    .sidebar-fixed {
        background-color: transparent;
        border-right: 1px solid rgba(72, 94, 144, 0.16);
        width: 240px;
        position: fixed;
        top: 60px;
        bottom: 0;
        z-index: 900;
        left: 0;
    }
}
.sidebar-fixed > .ps__rail-y {
    background-color: rgba(72, 94, 144, 0.16);
    width: 2px;
    display: none;
}
@media (min-width: 992px) {
    .sidebar-fixed > .ps__rail-y {
        display: block;
    }
}
.sidebar-fixed > .ps__rail-y > .ps__thumb-y {
    background-color: #c0ccda;
    width: 2px;
    left: 0;
}
@media (min-width: 992px) {
    .sidebar-fixed > .ps__rail-y > .ps__thumb-y {
        background-color: var(--primary);
    }
}
.sidebar-header {
    height: 55px;
    padding: 0 20px 0 25px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(72, 94, 144, 0.16);
}
@media (min-width: 992px) {
    .sidebar-header {
        display: none;
    }
}
.sidebar-header a {
    color: var(--secondary);
}
.sidebar-header a:focus,
.sidebar-header a:hover {
    color: #1c273c;
}
.sidebar-header a svg {
    width: 20px;
    height: 20px;
    stroke-width: 2.5px;
}
.sidebar-header a:first-child {
    margin-right: 17px;
}
.sidebar-header a:last-child {
    margin-left: auto;
}
.sidebar-header h5 {
    font-size: 14px;
    font-weight: 700;
    color: #1c273c;
    text-transform: uppercase;
    margin-top: 3px;
    margin-bottom: 0;
}
.sidebar-body {
    padding: 25px;
}
.sidebar-nav {
    list-style: none;
    padding: 0;
    margin: 0;
}
.sidebar-nav .nav-label {
    font-size: 10px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, inter ui, Roboto, sans-serif;
    color: #8392a5;
    text-transform: uppercase;
    letter-spacing: 0.7px;
}
.sidebar-nav .nav-item + .nav-item {
    margin-top: 9px;
}
.sidebar-nav .nav-item.show .nav {
    display: block;
}
.sidebar-nav .nav-link {
    padding: 0;
    color: #001737;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
}
@media (min-width: 992px) {
    .sidebar-nav .nav-link {
        font-size: 13px;
    }
}
.sidebar-nav .nav-link svg {
    margin-right: 15px;
    width: 18px;
    height: 18px;
    stroke-width: 2.2px;
    color: #525f70;
    fill: rgba(27, 46, 75, 0.06);
}
.sidebar-nav .nav-link.active {
    position: relative;
    color: var(--primary);
}
.sidebar-nav .nav-link.active::before {
    content: "";
    position: absolute;
    top: calc(50% - 1px);
    left: -25px;
    width: 10px;
    height: 2px;
    background-color: var(--primary);
}
@media (min-width: 1200px) {
    .sidebar-nav .nav-link.active::before {
        left: -30px;
        width: 13px;
    }
}
.sidebar-nav .nav-link.active svg {
    color: var(--primary);
    fill: rgba(1, 104, 250, 0.2);
}
.sidebar-nav .nav-link.with-sub::after {
    content: "";
    width: 6px;
    height: 6px;
    border-right: 1.5px solid #8392a5;
    border-bottom: 1.5px solid #8392a5;
    display: inline-block;
    transform: rotate(45deg);
    margin-top: -3px;
    margin-left: auto;
    align-self: center;
}
.sidebar-nav .nav-link.with-sub:focus::after,
.sidebar-nav .nav-link.with-sub:hover::after {
    border-color: #67788e;
}
.sidebar-nav .nav {
    display: none;
    border-left: 1px solid rgba(229, 233, 242, 0.7);
    margin-left: 8px;
    margin-top: 10px;
    padding-left: 25px;
    padding-bottom: 3px;
}
.sidebar-nav .nav a {
    display: block;
    font-size: 14px;
    color: #1b2e4b;
}
@media (min-width: 992px) {
    .sidebar-nav .nav a {
        font-size: 13px;
    }
}
.sidebar-nav .nav a:hover {
    color: var(--primary);
}
.sidebar-nav .nav a.active {
    position: relative;
    color: var(--primary);
    font-weight: 500;
}
.sidebar-nav .nav a.active::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: -26px;
    border-left: 1px solid var(--primary);
}
.sidebar-nav .nav a + a {
    margin-top: 5px;
}
.nav-aside {
    flex-direction: column;
    line-height: 1.6;
    font-size: 13px;
    white-space: nowrap;
    margin-bottom: 25px;
}
.nav-aside .nav-label {
    font-size: 10px;
    font-weight: 700;
    font-family: -apple-system, BlinkMacSystemFont, inter ui, Roboto, sans-serif;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #001737;
    margin-bottom: 5px;
    display: block;
    transition: all 0.25s;
}
@media (prefers-reduced-motion: reduce) {
    .nav-aside .nav-label {
        transition: none;
    }
}
.nav-aside .nav-item {
    position: relative;
}
.nav-aside .nav-item.active .nav-link {
    opacity: 1;
    color: var(--primary);
    font-weight: 500;
}
.nav-aside .nav-item.active .nav-link::before {
    opacity: 1;
    visibility: visible;
    border-left-color: var(--primary);
}
.nav-aside .nav-item.active svg {
    color: var(--primary);
    fill: rgba(1, 104, 250, 0.2);
}
.nav-aside .nav-item + .nav-item {
    margin-top: 1px;
}
.nav-aside .nav-item ul {
    margin: 0;
    padding-left: 34px;
    list-style: none;
    opacity: 0;
    visibility: hidden;
    height: 0;
}
.nav-aside .nav-item ul a {
    display: block;
    color: rgba(27, 46, 75, 0.75);
    padding: 2px 0;
}
.nav-aside .nav-item ul a:focus,
.nav-aside .nav-item ul a:hover {
    color: #1b2e4b;
}
.nav-aside .nav-item ul .active a {
    color: var(--primary);
}
.nav-aside .with-sub .nav-link::after {
    content: "\f3d1";
    font-family: ionicons;
    font-size: 12px;
    display: inline-block;
    position: relative;
    margin-left: auto;
    opacity: 0.5;
}
.nav-aside .with-sub.show .nav-link {
    font-weight: 500;
}
.nav-aside .with-sub.show .nav-link::before {
    opacity: 1;
    visibility: visible;
}
.nav-aside .with-sub.show .nav-link::after {
    transform: rotate(90deg);
}
.nav-aside .with-sub.show ul {
    opacity: 1;
    visibility: visible;
    padding-bottom: 10px;
    height: auto;
}
.nav-aside .with-sub.show:not(.active) .nav-link {
    color: #1b2e4b;
}
.nav-aside .with-sub.show:not(.active) svg {
    color: #1b2e4b;
}
.nav-aside .nav-link {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0;
    height: 30px;
    color: rgba(27, 46, 75, 0.9);
    transition: all 0.25s;
}
@media (prefers-reduced-motion: reduce) {
    .nav-aside .nav-link {
        transition: none;
    }
}
.nav-aside .nav-link::before {
    content: "";
    position: absolute;
    top: 2px;
    left: -25px;
    bottom: 2px;
    right: -25px;
    border-left: 3px solid #8392a5;
    opacity: 0;
    visibility: hidden;
}
.nav-aside .nav-link:focus,
.nav-aside .nav-link:hover {
    color: #1b2e4b;
}
.nav-aside svg {
    width: 18px;
    height: 18px;
    stroke-width: 2.3px;
    margin-right: 15px;
    color: rgba(27, 46, 75, 0.65);
    fill: rgba(27, 46, 75, 0.06);
}
.aside-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(28, 39, 60, 0.7);
    opacity: 0;
    z-index: 900;
    visibility: hidden;
}
@media (min-width: 992px) {
    .aside-backdrop {
        display: none;
    }
}
.content {
    padding: 20px 13px;
}
@media (min-width: 992px) {
    .content {
        padding: 25px;
    }
}
@media (min-width: 1200px) {
    .content {
        padding: 30px;
    }
}
.content-header {
    background-color: #fff;
    height: 55px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(72, 94, 144, 0.16);
    padding-left: 65px;
}
@media (min-width: 992px) {
    .content-header {
        height: 60px;
        padding: 0 25px;
    }
}
@media (max-width: 575px) {
    .content-header .nav {
        flex-wrap: nowrap;
    }
}
.content-header .nav-link {
    padding: 0;
    color: rgba(27, 46, 75, 0.75);
}
.content-header .nav-link:focus,
.content-header .nav-link:hover {
    color: #1b2e4b;
}
.content-header .nav-link + .nav-link {
    margin-left: 7px;
}
@media (min-width: 576px) {
    .content-header .nav-link + .nav-link {
        margin-left: 10px;
    }
}
.content-header svg {
    width: 18px;
    height: 18px;
}
@media (min-width: 992px) {
    .content-header svg {
        width: 20px;
        height: 20px;
    }
}
.content-header svg.feather-search {
    stroke-width: 2.8px;
}
.content-search {
    width: 250px;
    display: flex;
    align-items: center;
}
.content-search svg {
    stroke-width: 2.5px;
    margin-right: 10px;
    color: #8392a5;
}
.content-search .form-control {
    padding: 0;
    border-width: 0;
    background-color: transparent;
}
.content-search .form-control:focus {
    box-shadow: none;
    color: #1b2e4b;
}
.content-body {
    position: relative;
    padding: 15px;
    height: calc(100% - 54px);
    overflow-y: auto;
}
@media (min-width: 576px) {
    .content-body {
        padding: 20px;
    }
}
@media (min-width: 992px) {
    .content-body {
        padding: 25px;
        height: calc(100% - 60px);
    }
}
.content-body .container {
    margin-bottom: 25px;
}
@media (max-width: 1199.98px) {
    .content-body .container {
        max-width: none;
    }
}
.content-footer {
    font-size: 13px;
    margin-top: 20px;
    padding: 12px 10px !important;
    border-top: var(--border-light);
    color: var(--secondary);
    background: var(--navbar-background);
    box-shadow: var(--box-shadow);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    bottom: 0;
    width: 100%;
}
@media (min-width: 576px) {
    .content-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
@media (min-width: 992px) {
    .content-footer {
        padding-top: 25px;
    }
}
@media (min-width: 1200px) {
    .content-footer {
        padding-top: 30px;
    }
}
.content-footer .nav-link {
    padding: 0;
    color: #1b2e4b;
    margin: 0 8px !important;
}
.content-footer a:focus,
.content-footer a:hover {
    color: var(--primary);
}
.content-footer a + .nav-link {
    margin-left: 25px;
}
.content-footer > div:last-child {
    margin-top: 15px;
}
@media (min-width: 576px) {
    .content-footer > div:last-child {
        margin-top: 0;
    }
}
.footer {
    bottom: 0;
    font-size: 10px;
    font-family: -apple-system, BlinkMacSystemFont, inter ui, Roboto, sans-serif;
    letter-spacing: 0.3px;
    padding: 15px 25px;
    background-color: #fafbfc;
    color: #637388;
    border-top: 1px solid rgba(72, 94, 144, 0.16);
    text-transform: uppercase;
    display: none;
}
@media (min-width: 992px) {
    .footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
.footer a {
    padding: 0;
    color: #1b2e4b;
}
.footer a:focus,
.footer a:hover {
    color: var(--primary);
}
.footer a + .nav-link {
    margin-left: 25px;
}
.footer > div:last-child {
    margin-top: 15px;
}
@media (min-width: 576px) {
    .footer > div:last-child {
        margin-top: 0;
    }
}
.pq-roboto {
    font-family: roboto, sans-serif;
}
.pq-settings {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1200;
    transition: all 0.2s;
}
@media (prefers-reduced-motion: reduce) {
    .pq-settings {
        transition: none;
    }
}
.pq-settings.show {
    right: 250px;
}
.pq-settings.show .pq-settings-link,
.pq-settings.show .pq-settings-link:focus,
.pq-settings.show .pq-settings-link:hover {
    background-color: #fff;
    border-color: #c0ccda;
    color: #1c273c;
    box-shadow: none;
}
.pq-settings-link {
    position: absolute;
    top: 98px;
    left: -44px;
    width: 45px;
    height: 45px;
    background-color: #fff;
    color: var(--secondary);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c0ccda;
    border-right-width: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    transition: all 0.2s ease-in-out;
    z-index: 1;
}
@media (prefers-reduced-motion: reduce) {
    .pq-settings-link {
        transition: none;
    }
}
.pq-settings-link:focus,
.pq-settings-link:hover {
    color: #1c273c;
    border-color: #8392a5;
    box-shadow: 0 0 10px 2px rgba(28, 39, 60, 0.1);
}
.pq-settings-link svg {
    width: 20px;
    height: 20px;
}
.pq-settings-body {
    position: absolute;
    top: 0;
    left: 0;
    width: 250px;
    height: 100%;
    background-color: #fff;
    border-left: 1px solid #c0ccda;
    box-shadow: 0 0 15px rgba(28, 39, 60, 0.1);
    padding: 0 20px;
    overflow-y: auto;
}
.pq-settings-body label {
    display: block;
    line-height: 1;
}
.pq-mode,
.pq-skin {
    height: 20px;
    border: 1px solid rgba(72, 94, 144, 0.16);
    display: flex;
    margin-bottom: 5px;
    padding: 2px;
}
.pq-mode:focus,
.pq-mode:hover,
.pq-skin:focus,
.pq-skin:hover {
    border-color: #8392a5;
    box-shadow: 0 0 0 1px #8392a5;
}
.pq-mode.active,
.pq-skin.active {
    border-color: var(--primary);
    box-shadow: 0 0 0 1px var(--primary);
    position: relative;
}
.pq-mode.active::before,
.pq-skin.active::before {
    content: "\f3ff";
    font-family: ionicons;
    font-size: 26px;
    position: absolute;
    top: 50%;
    left: 50%;
    line-height: 0;
    margin-left: -5px;
    margin-top: -1px;
    color: var(--primary);
}
.pq-mode.active ~ span,
.pq-skin.active ~ span {
    color: var(--primary);
}
.pq-mode.disabled,
.pq-skin.disabled {
    cursor: default;
    border-color: rgba(72, 94, 144, 0.16);
    box-shadow: none;
    opacity: 0.75;
}
.pq-mode > span,
.pq-skin > span {
    display: block;
    flex: 1;
}
.pq-skin-deepblue span:first-child {
    background-color: #4994fe;
}
.pq-skin-deepblue span:last-child {
    background-color: #0536c5;
}
.pq-skin-charcoal span:first-child {
    background-color: #e5e9f2;
}
.pq-skin-charcoal span:last-child {
    background-color: #2d353e;
}
.pq-skin-gradient1 span:first-child {
    background-color: #5b47fb;
}
.pq-skin-gradient1 span:last-child {
    background-color: var(--primary);
}
.pq-skin-name {
    font-size: 9px;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, inter ui, Roboto, sans-serif;
    text-transform: uppercase;
    display: block;
    text-align: center;
    color: #8392a5;
}
.bg-brand-01 {
    background-color: var(--primary);
}
.bg-brand-02 {
    background-color: #042893;
}
.bg-brand-03 {
    background-color: #1ce1ac;
}
.bg-ui-01 {
    background-color: #f5f6fa;
}
.bg-ui-02 {
    background-color: #e5e9f2;
}
.bg-ui-03 {
    background-color: #c0ccda;
}
.bg-ui-04 {
    background-color: #8392a5;
}
.bg-gray-50 {
    background-color: #fafbfc;
}
.bg-gray-100 {
    background-color: #f4f5f8;
}
.bg-gray-200 {
    background-color: #e3e7ed;
}
.bg-gray-300 {
    background-color: #cdd4e0;
}
.bg-gray-400 {
    background-color: #b4bdce;
}
.bg-gray-500 {
    background-color: #97a3b9;
}
.bg-gray-600 {
    background-color: var(--secondary);
}
.bg-gray-700 {
    background-color: #596882;
}
.bg-gray-800 {
    background-color: #3b4863;
}
.bg-gray-900 {
    background-color: #1c273c;
}
.bg-white-1 {
    background-color: rgba(255, 255, 255, 0.1);
}
.bg-white-2 {
    background-color: rgba(255, 255, 255, 0.2);
}
.bg-white-3 {
    background-color: rgba(255, 255, 255, 0.3);
}
.bg-white-4 {
    background-color: rgba(255, 255, 255, 0.4);
}
.bg-white-5 {
    background-color: rgba(255, 255, 255, 0.5);
}
.bg-white-6 {
    background-color: rgba(255, 255, 255, 0.6);
}
.bg-white-7 {
    background-color: rgba(255, 255, 255, 0.7);
}
.bg-white-8 {
    background-color: rgba(255, 255, 255, 0.8);
}
.bg-white-9 {
    background-color: rgba(255, 255, 255, 0.9);
}
.bg-black-1 {
    background-color: rgba(0, 0, 0, 0.1);
}
.bg-black-2 {
    background-color: rgba(0, 0, 0, 0.2);
}
.bg-black-3 {
    background-color: rgba(0, 0, 0, 0.3);
}
.bg-black-4 {
    background-color: rgba(0, 0, 0, 0.4);
}
.bg-black-5 {
    background-color: rgba(0, 0, 0, 0.5);
}
.bg-black-6 {
    background-color: rgba(0, 0, 0, 0.6);
}
.bg-black-7 {
    background-color: rgba(0, 0, 0, 0.7);
}
.bg-black-8 {
    background-color: rgba(0, 0, 0, 0.8);
}
.bg-black-9 {
    background-color: rgba(0, 0, 0, 0.9);
}
.bg-gray-1 {
    background-color: rgba(28, 39, 60, 0.1);
}
.bg-gray-2 {
    background-color: rgba(28, 39, 60, 0.2);
}
.bg-gray-3 {
    background-color: rgba(28, 39, 60, 0.3);
}
.bg-gray-4 {
    background-color: rgba(28, 39, 60, 0.4);
}
.bg-gray-5 {
    background-color: rgba(28, 39, 60, 0.5);
}
.bg-gray-6 {
    background-color: rgba(28, 39, 60, 0.6);
}
.bg-gray-7 {
    background-color: rgba(28, 39, 60, 0.7);
}
.bg-gray-8 {
    background-color: rgba(28, 39, 60, 0.8);
}
.bg-gray-9 {
    background-color: rgba(28, 39, 60, 0.9);
}
.bg-primary-light {
    background-color: var(--primary-light);
}
.bg-success-light {
    background-color: rgba(16, 183, 89, 0.18);
}
.bg-warning-light {
    background-color: rgba(253, 126, 20, 0.18);
}
.bg-pink-light {
    background-color: rgba(241, 0, 117, 0.18);
}
.bg-indigo-light {
    background-color: rgba(91, 71, 251, 0.18);
}
.bg-indigo {
    background-color: #5b47fb;
}
.bg-purple {
    background-color: #6f42c1;
}
.bg-pink {
    background-color: #f10075;
}
.bg-orange {
    background-color: #fd7e14;
}
.bg-green {
    background-color: var(--green);
}
.bg-yellow {
    background-color: var(--yellow);
}
.bg-teal {
    background-color: #0cc;
}
.bg-lightblue {
    background-color: #a5d7fd;
}
.bg-transparent {
    background-color: transparent;
}
.bg-facebook {
    background-color: #4064ac;
}
.bg-twitter {
    background-color: #00a7e6;
}
.btn.bg-facebook:focus,
.btn.bg-facebook:hover,
.sp-container button.bg-facebook:focus,
.sp-container button.bg-facebook:hover,
.ui-datepicker-buttonpane button.bg-facebook:focus,
.ui-datepicker-buttonpane button.bg-facebook:hover,
a.bg-facebook:focus,
a.bg-facebook:hover,
button.bg-facebook:focus,
button.bg-facebook:hover {
    background-color: #395999;
}
.btn.bg-twitter:focus,
.btn.bg-twitter:hover,
.sp-container button.bg-twitter:focus,
.sp-container button.bg-twitter:hover,
.ui-datepicker-buttonpane button.bg-twitter:focus,
.ui-datepicker-buttonpane button.bg-twitter:hover,
a.bg-twitter:focus,
a.bg-twitter:hover,
button.bg-twitter:focus,
button.bg-twitter:hover {
    background-color: #0094cd;
}
.content-auth {
    min-height: calc(100vh - 106px);
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.content-auth > .container {
    padding: 0;
}
@media (max-width: 1139px) {
    .content-auth > .container {
        max-width: none;
        padding: 0 20px;
    }
}
.content-auth > .container:first-child {
    flex: 1;
}
.content-auth-alt {
    min-height: calc(100vh - 106px);
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.content-auth-alt .container {
    padding: 0;
}
@media (max-width: 1139px) {
    .content-auth-alt .container {
        max-width: none;
    }
}
.sign-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 340px;
}
@media (min-width: 1200px) {
    .sign-wrapper {
        width: 340px;
    }
}
.sign-wrapper .form-group label {
    display: block;
    margin-bottom: 5px;
}
header .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary);
    font-weight: 700;
    font-size: 16px;
}
.content.content-fixed {
    padding-top: 70px;
    min-height: calc(100vh - 100px);
}
#notifications-holder .dropdown-item:last-child {
    margin-bottom: 5px;
}
.copy,
.cursor-pointer {
    cursor: pointer;
}
.toast {
    position: fixed;
    top: 1.8rem;
    right: 2rem;
    z-index: 9999;
    max-width: 300px;
    font-weight: 500;
}
@media (max-width: 500px) {
    .toast {
        top: 0.5rem;
        right: 0.5rem;
    }
}
.animated {
    -webkit-animation-duration: 1.5s;
    -webkit-animation-fill-mode: both;
    animation-duration: 1.5s;
    animation-fill-mode: both;
}
@media (prefers-reduced-motion) {
    .animated {
        -webkit-animation: unset !important;
        -webkit-transition: none !important;
        animation: unset !important;
        transition: none !important;
    }
}
.animated.delay {
    -webkit-animation-delay: attr(data-delay);
    animation-delay: attr(data-delay);
}
.bounceIn {
    -webkit-animation-duration: 0.75s;
    -webkit-animation-name: bounceIn;
    animation-duration: 0.75s;
    animation-name: bounceIn;
}
@-webkit-keyframes bounceIn {
    0%,
    20%,
    40%,
    60%,
    80%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }
    20% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1);
    }
    40% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9);
        transform: scale3d(0.9, 0.9, 0.9);
    }
    60% {
        -webkit-transform: scale3d(1.03, 1.03, 1.03);
        opacity: 1;
        transform: scale3d(1.03, 1.03, 1.03);
    }
    80% {
        -webkit-transform: scale3d(0.97, 0.97, 0.97);
        transform: scale3d(0.97, 0.97, 0.97);
    }
    to {
        -webkit-transform: scaleX(1);
        opacity: 1;
        transform: scaleX(1);
    }
}
@keyframes bounceIn {
    0%,
    20%,
    40%,
    60%,
    80%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }
    20% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1);
    }
    40% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9);
        transform: scale3d(0.9, 0.9, 0.9);
    }
    60% {
        -webkit-transform: scale3d(1.03, 1.03, 1.03);
        opacity: 1;
        transform: scale3d(1.03, 1.03, 1.03);
    }
    80% {
        -webkit-transform: scale3d(0.97, 0.97, 0.97);
        transform: scale3d(0.97, 0.97, 0.97);
    }
    to {
        -webkit-transform: scaleX(1);
        opacity: 1;
        transform: scaleX(1);
    }
}
@-webkit-keyframes bounceOut {
    20% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9);
        transform: scale3d(0.9, 0.9, 0.9);
    }
    50%,
    55% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        opacity: 1;
        transform: scale3d(1.1, 1.1, 1.1);
    }
    to {
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }
}
@keyframes bounceOut {
    20% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9);
        transform: scale3d(0.9, 0.9, 0.9);
    }
    50%,
    55% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        opacity: 1;
        transform: scale3d(1.1, 1.1, 1.1);
    }
    to {
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }
}
.bounceOut {
    -webkit-animation-duration: 0.75s;
    -webkit-animation-name: bounceOut;
    animation-duration: 0.75s;
    animation-name: bounceOut;
}
.blankPage .content .container {
    display: flex;
    align-items: center;
    justify-content: center;
}
.custom-checkbox .custom-control-label::after,
.custom-checkbox .custom-control-label::before {
    top: 50%;
}
.auth .invalid-feedback {
    display: block;
}
.currencies-holder {
    position: relative;
    top: -7px;
    left: 0;
    width: 189px !important;
    padding: 3px 10px !important;
    transition: 1s easin;
    max-height: 115px;
    overflow-y: auto;
}
.currencies-holder:after,
.currencies-holder:before {
    content: none !important;
}
.currencies-holder a:hover {
    background: #e5e9f2 !important;
}
.currencies-holder a {
    margin: 7px 0 !important;
    color: #141c2b !important;
    padding: 6px 15px !important;
    border-radius: 0.25rem;
    transition: all 0.2s ease-in-out;
    font-weight: 600;
    background: #f4f5f8;
    border: 1px solid #edf0f7;
}
.text-12 {
    font-size: 12px !important;
}
.text-13 {
    font-size: 13px !important;
}
.text-14 {
    font-size: 14px !important;
}
.text-15 {
    font-size: 15px !important;
}
.text-16 {
    font-size: 16px !important;
}
.text-17 {
    font-size: 17px !important;
}
.text-18 {
    font-size: 18px !important;
}
.text-20 {
    font-size: 20px !important;
}
.text-22 {
    font-size: 22px !important;
}
.text-24 {
    font-size: 24px !important;
}
.text-26 {
    font-size: 26px !important;
}
.text-28 {
    font-size: 28px !important;
}
.box-shadow {
    box-shadow: var(--box-shadow);
}
.authPage .content-fixed .container {
    display: flex;
    justify-content: center;
}
.content-fixed .container {
    padding: 0;
}
.pq-card {
    background: var(--card-background);
    box-shadow: var(--box-shadow);
}
.btn svg {
    height: 16px;
    width: 16px;
}
.loader {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background: #aaaaaa75;
    z-index: 9999;
    display: none;
}
.loader.show {
    display: flex !important;
}
svg.top {
    vertical-align: top !important;
}
b {
    font-weight: 500;
}
.ajaxElement,
.ajaxModal {
    cursor: pointer;
}
.select-category span span {
    height: 29px !important;
    background: #f4f7fc;
    box-shadow: var(--box-shadow);
    border-color: #ddd !important;
    border-radius: 5px !important;
}
input[type="checkbox"] {
    width: 15px;
    height: 15px;
}
.select2-container--open .select2-selection__rendered {
    border-radius: 4px 4px 0 0 !important;
}
::-webkit-scrollbar {
    height: 2px;
    width: 5px;
    border-radius: 6px;
}
::-webkit-scrollbar-thumb {
    background: var(--secondary);
    border-radius: 10px;
}
::-webkit-scrollbar {
    border-radius: 10px;
    width: 5px;
}
ul::-webkit-scrollbar {
    border-radius: 10px;
    width: 5px;
}
.select2-dropdown ul::-webkit-scrollbar {
    width: 3px;
}
.table .collapse-button {
    position: absolute;
    right: 2rem;
    top: 2rem;
    color: #fff;
}
#fetchServices table thead tr th {
    background: 0 0 !important;
}
.select-category .selection *,
.select-category .selection span span {
    height: 26px !important;
    line-height: 24px !important;
    margin-top: -2px;
}
#fetchServices table thead tr th:first-child {
    min-width: 50px;
    text-align: center;
}
#fetchServices td input.form-control {
    margin: 0 5px;
    height: 30px;
}
#fetchServices .category-holder {
    display: flex;
    align-items: center;
    padding-left: 2rem;
    flex-wrap: wrap;
}
#fetchServices .category-holder .select-category {
    margin-left: 1rem;
    height: 22px !important;
}
@media (max-width: 500px) {
    #fetchServices .category-holder .select-category {
        margin: 8px 0 0 !important;
        width: 100%;
    }
    #fetchServices .category-holder {
        justify-content: left;
        padding-left: 0;
    }
    #fetchServices thead .chevron {
        left: 20px;
        top: 28px !important;
        position: relative;
    }
    #fetchServices .service-holder .s {
        width: 100% !important;
        margin: auto 0 !important;
    }
    #fetchServices td:first-child {
        padding-right: 0;
    }
}
@media (min-width: 501px) {
    .w-sm-50 {
        width: 50% !important;
    }
    .w-sm-auto {
        width: auto !important;
    }
    .w-sm-100 {
        width: 50% !important;
    }
    .w-sm-25 {
        width: 25% !important;
    }
}
#fetchServices .service-holder {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
#fetchServices .price-holder {
    display: flex;
    margin: 6px -5px 0;
}
#fetchServices td {
    vertical-align: middle;
}
.chevron::before {
    border-style: solid;
    border-width: 0.1em 0.1em 0 0;
    content: "";
    display: inline-block;
    height: 0.45em;
    left: 5px;
    position: relative;
    vertical-align: top;
    width: 0.45em;
    top: 0.45em;
    transform: rotate(-45deg);
    transition: all 0.3s ease;
}
.chevron.collapsed:before {
    transform: rotate(135deg);
    top: 5px;
}
#fetchServices thead .chevron {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    cursor: pointer;
    transform: scale(1.5);
    color: var(--primary);
    width: 20px;
}
#fetchServices .service-holder .s {
    width: 60%;
}
.service-holder:nth-child(2) div:before {
    content: "seller price";
    position: relative;
    color: #000;
}
.w-fit {
    width: fit-content;
}
.handle {
    cursor: move;
}
tr th {
    border: none !important;
}
.shadow-base {
    box-shadow: var(--box-shadow);
}
.services td:first-child {
    display: flex;
    justify-content: center;
    text-align: center;
    padding-bottom: 1px;
    flex-direction: column;
}
.services tr td:first-child span:first-child {
    margin-bottom: -5px;
}
.services td {
    vertical-align: middle;
}
.ui-sortable-helper {
    display: inline-table !important;
    background: #ffffffee;
}
.services tbody tr:not(:last-child) {
    border: 1px solid rgba(72, 94, 144, 0.16);
    border-left: 0;
}
.services .table td {
    border: none;
}
.services .table td.d-grid {
    padding: 0 0.5rem 4px;
}
.btn-pm,
.btn-pm:hover {
    background: var(--primary);
    color: #fff !important;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    font-size: 13px;
}
.select2 {
    width: 100% !important;
    display: block !important;
}
.select2 span span {
    height: 38px !important;
}
.modal .select2-selection__arrow {
    height: 38px !important;
}
.select2 .select2-selection__rendered {
    line-height: 33px !important;
}
input.select2-search__field {
    border-radius: 0.25rem !important;
    border-color: #d9d9d9 !important;
}
.editor {
    min-height: 150px;
}
.emoji-picker-handle {
    position: absolute;
    top: -0.5px;
    right: 0;
    text-decoration: none;
    cursor: pointer;
    background: var(--primary);
    padding: 7.5px 6px;
    border-radius: 0 5px 5px 0;
}
.emoji-picker-area {
    position: absolute;
    right: 0;
    outline: 0;
    top: 35px;
    z-index: 999;
    width: 235px;
    padding: 7px;
    margin-top: 5px;
    overflow: hidden auto;
    background: #fff;
    height: 200px;
    border-radius: 6px;
    box-shadow: 0 0 8px 4px #d6d6d65c;
    border: 1px solid #ededed;
}
.emoji-picker-area ul {
    padding: 0;
    margin: 0;
}
.emoji-picker-area ul a {
    margin: 5px;
    font-size: 24px;
    text-decoration: none;
}
.editor ~ .emoji-picker-handle {
    top: 11px;
    right: 10px;
}
.card::-webkit-scrollbar-thumb {
    background: 0 0;
}
input[emoji-picker="true"] {
    padding: 0 38px 0 5px;
    margin: 0;
}
.services .chevron {
    position: absolute;
    top: 13.5px;
    right: 15px;
    cursor: pointer;
    transform: scale(1.5);
    color: var(--primary);
    width: 20px;
}
thead tr:first-child > th {
    background-color: var(--primary-light);
}
table .sort-category {
    text-align: center;
    cursor: pointer;
    width: 5%;
}
table .sort-category svg {
    stroke: var(--primary);
}
.services tbody tr:last-child td {
    padding-bottom: 5px;
}
.dropdown-menu.categories {
    max-width: 333px;
    max-height: 500px;
    overflow: auto;
    border: 1px solid #e9e9e9;
}
.dropdown-menu.categories .dropdown-item {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
button .focus {
    box-shadow: 0 0 0 0.2rem rgba(39, 127, 251, 0.5);
}
#tables {
    display: grid;
    grid-row-gap: 15px;
}
.hide {
    display: none;
}
th:not(.hide).head.hide {
    display: table-row;
}
.services tbody tr:last-child {
    border-top: 1px solid rgba(72, 94, 144, 0.16);
}
.category-holder a.btn {
    background-color: var(--primary);
    color: #fff;
    padding: 0 6px;
    border-radius: 0.25rem;
    margin-left: 10px;
    line-height: 18px;
}
.scrolltop {
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    z-index: 999;
    background: var(--primary-light);
    padding: 8px;
    border-radius: 100%;
    display: none;
    cursor: pointer;
}
.scrolltop svg {
    stroke: var(--primary);
    width: 26px;
    height: 26px;
}
.inner-navbar {
    background: var(--navbar-background);
    border-radius: 4px;
    box-shadow: var(--box-shadow);
    /* margin-top: 1.5rem; */
    width: -webkit-fill-available !important;
    display: flex;
    margin-left: -5px;
    margin-right: -5px;
    width: 100%;
}
tbody > tr > td span {
    font-size: 13px;
    margin: 2px 0 -1px;
}
.services tbody tr.inactive {
    background-color: var(--secondary-light);
}
table.inactive {
    background: var(--secondary-light);
}
table.inactive thead tr:first-child > th {
    background-color: var(--secondary-light);
}
.ajaxDelete .feather-trash-2 {
    height: 20px;
    width: 20px;
    stroke-width: 2px;
}
.btn-group-xs > * {
    padding: 2px 10px 4px !important;
}
.btn-group-xs > :not(:last-child) {
    margin-right: 2px;
}
.btn-group-xs svg {
    stroke-width: 2.2px;
    width: 16px;
    height: 15px;
}
.pagination a.page-link {
    border: 0;
    box-shadow: var(--box-shadow);
}
.input-group-prepend.inside .input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.input-group-prepend.inside .form-control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
}
.loader.search.show {
    display: flex !important;
    position: absolute;
    justify-content: center;
    background: #ffffff65;
}
.loader.search {
    display: none !important;
}
tbody tr td:first-child {
    text-align: center;
}
.btn.btn-pm {
    padding: 2.5px 8px;
}
.badge-md {
    padding: 5px;
    font-size: 11px;
}
#verifyModal img {
    width: 100%;
    border: 2px solid var(--secondary-light);
    border-radius: 4px;
    margin: 8px 0 -2px;
}
#desc {
    min-height: 60px;
    height: 100%;
    width: 100%;
    white-space: pre-wrap;
}
small.invalid-feedback {
    margin: 0 0 -5px;
}
.users .table td {
    padding: 5px 10px;
}
table thead [data-sort] .feather {
    height: 16px;
    width: 16px;
}
.sorting th [data-sort],
th[data-sort] {
    cursor: pointer;
}
.btn-status {
    padding: 5px 6px;
    border-radius: 4px;
    color: #fff !important;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 13px !important;
}
.order-action {
    padding: 4px 8px;
    font-size: 13px;
    border-radius: 4px;
    color: #fff !important;
    font-weight: 500;
    text-transform: uppercase;
}
.fund-logs tbody tr td:first-child,
.transactions tbody tr td:first-child {
    text-align: left;
}
.no-results {
    display: grid;
    justify-content: center;
    text-align: center;
    margin-top: 20%;
}
.no-results svg {
    height: 100px;
    width: 100px;
    color: var(--secondary-light);
    margin: 0 auto;
    stroke-width: 1.2px;
}
.no-results p {
    font-size: 24px;
    color: #c2c2c2;
}
a.bell.active:after {
    background: var(--primary);
    height: 8px;
    width: 8px;
    position: absolute;
    border-radius: 100%;
    margin-left: -10px;
    box-shadow: 0 0 0 2.5px #fff;
    content: "";
}
.support .chat-holder {
    background: var(--body-background);
    height: 50vh;
    overflow-y: auto;
    border-radius: 4px;
    margin: 10px 0;
    border: 1px solid #c0ccda;
}
.support .editor {
    min-height: 100px;
}
.support .no-results {
    margin-top: 15vh;
}
.message.owner {
    float: right;
}
.message.sender {
    float: left;
}
.chat-holder .message {
    width: 55%;
    margin: 8px;
    border-radius: 4px;
}
.message.owner .body {
    background: var(--primary);
    color: #fff;
}
.message.sender .body {
    background: #fff;
    color: #333;
}
.message .foot {
    justify-content: flex-start;
    display: flex;
    padding: 2px;
}
.message.sender .foot {
    flex-direction: row-reverse;
}
.message .body {
    padding: 10px;
    border-radius: 4px;
    box-shadow: var(--box-shadow);
}
button.ql-image:after {
    content: "Upload image";
    position: absolute;
    margin-left: 10px;
    font-size: 12px;
    font-weight: 500;
    font-family: inherit;
}
.message .body img {
    width: 100%;
    margin: 10px 0;
    border-radius: 4px;
}
.modal .chat-holder {
    height: auto !important;
}
.modal .chat-holder .message {
    width: 75%;
}
.modal .messages {
    background: var(--body-background);
}
.modal .chat-holder {
    background: 0 0;
    border: 0;
    min-height: 100px;
}
.message .retract {
    position: relative;
    top: -46px;
    left: -25px;
    color: var(--red);
    cursor: pointer;
}
#gatewayForm .logo {
    max-height: 45px !important;
}
#gatewayForm img {
    height: auto;
    max-width: 100%;
}
.social-login:empty,
[data-sitekey]:empty {
    display: none !important;
}
.social-login {
    display: flex;
    justify-content: center;
}
.social-login a {
    font-size: 16px;
    font-weight: 500;
    justify-content: center;
    align-items: center;
    display: flex;
    border: 2px solid #c0ccda59;
    border-radius: 4px;
    padding: 8px 0;
    margin: 0 5px 15px;
    height: 45px;
    width: 100%;
}
.social-login .google img {
    max-height: 45px !important;
    margin-right: 12px;
}
.social-login .facebook img {
    max-width: 45px;
}
.error-message {
    color: var(--red);
    font-size: 12px;
}
body div.error-message:empty,
div:empty {
    display: none !important;
}
div.content img {
    border-radius: 4px;
}
table .cell {
    display: table-cell;
}
.action-buttons .btn {
    background: var(--secondary);
    color: #fff;
    padding: 1px 6px !important;
    margin: 4px 2px;
}
.action-buttons .btn svg {
    height: 13px;
    stroke-width: 2.5px;
}
.message .edit-message {
    position: relative;
    top: -45px;
    left: -30px;
    color: var(--primary);
    cursor: pointer;
}
.message.sender .edit-message {
    left: 100%;
    margin-left: 8px;
}
.admin.support .message {
    margin-bottom: -10px !important;
}
.modal .ql-editor {
    max-height: 500px;
    width: 100% !important;
}
.modal .ql-container img {
    max-width: 300px !important;
    display: block;
}
.message p:last-child {
    margin: 0;
}
td p {
    margin: 0;
}
.orders table input[type="checkbox"] ~ span {
    vertical-align: middle;
    display: inline-block;
    margin: -8px 0 0 8px;
}
.admin.orders .order-action {
    text-transform: capitalize;
    padding: 5px 8px;
}
.admin.orders .select2-dropdown.select2-dropdown--below {
    top: 4px;
}
tbody tr.checked {
    background: var(--primary-light);
}
table.checkbox {
    cursor: cell;
}
.orders .order-alert .feather {
    fill: var(--red);
    stroke: #fff;
    margin: -2px 2px 2px 0;
    stroke-width: 2.5px;
}
table.language tr td:first-child {
    text-align: left;
    padding-left: 15px;
}
table.language {
    background: #fff;
    border-radius: 5px;
    box-shadow: var(--box-shadow);
}
.input-group .error-message {
    display: none;
}
input.small {
    height: 32px;
    padding: 0 10px;
    font-size: 13.5px;
}
.settings form label {
    font-size: 13.5px;
    font-weight: 400;
}
.upcoming-block::before {
    background: var(--secondary);
    opacity: 0.11;
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    height: 100%;
    width: 100%;
    content: "";
    z-index: 99;
    border-radius: 4px;
    cursor: not-allowed;
}
.upcoming-block {
    display: block;
    position: relative;
}
.settings .action-checks label {
    margin: 2px 15px;
    text-transform: capitalize;
    font-size: 14px;
}
.action-checks .custom-control-label::after,
.action-checks .custom-control-label::before {
    left: -20px;
}
.code-editor {
    color: #abb2bf;
    border-radius: 6px;
    box-shadow: 0 2px 2px 0 #00000024, 0 1px 5px 0 #0000001f,
        0 3px 1px -2px #00000033;
    font-size: 15px;
    font-family: source sans pro, monospace;
    font-weight: 400;
    letter-spacing: normal;
    overflow-y: auto;
    height: 300px;
    line-height: 20px;
    padding: 10px;
    tab-size: 4;
    background: #282c34;
    display: block !important;
    width: 100%;
}
span.remove-logo {
    position: absolute;
    right: 75px;
    z-index: 2;
    top: 5px;
    color: var(--red);
    cursor: pointer;
}
.settings .code-editor {
    height: 150px;
}
.cursor-disabled {
    cursor: not-allowed !important;
}
.font-select span {
    border: 1px solid #c0ccda;
    padding: 7px 10px;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    display: block;
}
.fs-drop {
    display: block;
    position: absolute;
    background: #fff;
    border: 1px solid #c0ccda;
    border-radius: 4px;
    margin-top: 2px;
    width: calc(100% - 10px);
    z-index: 999;
}
.fs-results {
    max-height: 200px;
    list-style: none;
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 5px 0;
}
.fs-results li {
    margin: 2px 5px;
    padding: 4px 10px;
    border-radius: 4px;
    cursor: pointer;
}
.fs-results li:hover {
    background: #e5e9f2;
}
.fs-results li.active {
    background: var(--primary);
    color: #fff;
}
.font-select span:after {
    border-color: #888 transparent transparent transparent;
    margin-top: 10px;
    right: 16px;
    position: absolute;
}
.settings div:empty {
    display: inline-block !important;
}
.patterns div:empty {
    width: 85px;
    height: 85px;
    display: block !important;
    margin: 5px;
    box-shadow: var(--box-shadow);
    border: var(--border-light);
    border-color: #c0ccda;
    border-radius: 4px;
    cursor: pointer;
    display: block !important;
    float: left;
}
.patterns .active {
    border: 2.5px solid var(--primary);
}
.settings button.ql-image:after {
    content: "";
}
.morris-hover {
    position: absolute;
    z-index: 1000;
}
.morris-hover.morris-default-style {
    border-radius: 6px;
    padding: 6px;
    color: #666;
    background: rgba(255, 255, 255, 0.8);
    border: solid 1.5px var(--secondary-light);
    font-family: sans-serif;
    font-size: 12px;
    text-align: center;
}
.morris-hover.morris-default-style .morris-hover-row-label {
    font-weight: 700;
    margin: 0.25em 0;
}
.morris-hover.morris-default-style .morris-hover-point {
    white-space: nowrap;
    margin: 0.1em 0;
}
.spinner-grow:empty {
    display: block !important;
}
.popover hr {
    margin: 6px 0 5px;
}
.donut svg text:not(:last-child) {
    font-size: 32px !important;
    transform: none !important;
    margin-bottom: 10px;
}
.donut svg,
.morris svg,
.morris-hover div {
    width: 100% !important;
    font-weight: 500;
    font-family: var(--font-family) !important;
    text-transform: capitalize;
}
.donut svg path {
    cursor: pointer;
}
.badge-log {
    border-radius: 4px;
    padding: 3px 8px;
    text-transform: capitalize;
    font-weight: 500;
    display: inline-block;
}
.table-bordered.dark td,
.table-bordered.dark th {
    border-color: #b6b6b638;
}
.dashboard .badge-log {
    padding: 2px 8px 4px;
    display: inline;
}
.feather-file.small {
    height: 45px;
    stroke-width: 1.5px;
}
.last-message {
    max-height: 80px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    line-height: 22px;
    text-overflow: ellipsis;
    padding: 8px 15px;
    margin: 0 1rem 1rem;
    background: var(--primary-light);
    border-radius: 4px;
}
.settings .btn .reset {
    height: 16px;
    width: 16px;
}
.api code {
    font-size: 14px;
}
.api .response code {
    color: #f4f7fc;
}
.pq-logo img {
    max-height: 45px;
    height: 100%;
    margin-top: 2px;
}
.pagination .page-item {
    display: contents;
}
.filters .select2 .select2-selection__rendered,
.filters .select2 span span {
    line-height: 30px !important;
    height: 32px !important;
}
.filters .select2.select2-container {
    height: 32px !important;
    margin-top: 3px;
}
table {
    font-size: 0.875rem;
}
.support a.badge {
    color: #fff;
    background: var(--primary);
    font-size: 11px;
    padding: 4px 6px 3px;
    margin-right: 3px;
}
#orderForm #details .badge-info {
    margin-top: -10px !important;
    display: block;
    width: fit-content;
    font-size: 12px;
    letter-spacing: 0.4px;
    font-weight: 500;
}
.navbar-menu .nav-item.with-sub .nav-link:hover::after,
.navbar-menu .nav-item.with-sub.show .nav-link::after {
    transform: rotate(224deg);
    margin-top: 5px;
}
.orderPage .select2-results__options {
    max-height: 400px !important;
}
